<div [class.isMobile]="isMobile">
  <!-- <button mat-button type="button" class="btn btn-block" (click)="onBack($event)">
    < Voltar</button> -->

  <div class="top-bar" [class.isMobile]="isMobile">

    <button mat-icon-button class="btn btn-back" (click)="onBack($event)">
      <mat-icon class="icn-back">arrow_back_ios</mat-icon>VOLTAR
    </button>

  </div>

</div>

<div class="wrapper wrapper-content animated fadeInRight overflow" [class.isMobile]="isMobile">

  <!-- <lib-atividade-cadastro-view [atividadeNo]="atividadeNo" [processoNo]="processoNo" [enTheme]="enTheme"
    [isReadOnly]="false" [ocorrenciaNo]="ocorrenciaNo" [saveInList]="true" [isMobile]="isMobile"
    [defaultFormControls]="defaultFormControls" (afterCompleted)="afterCompleted($event)"
    (eventBubble)="eventBubble($event)" (afterSaved)="afterSaved($event)">
  </lib-atividade-cadastro-view> -->

  <lib-cmp-atividade id="evolution" [usuarioNo]="usuarioNo$ | async" [saveInList]="true" [ocorrenciaNo]="ocorrenciaNo"
    [atividadeNo]="atividadeNo$ | async" [processoNo]="processoNo" [defaultFormControls]="defaultFormControls$ | async"
    [isReadOnly]="isReadOnly" [isMobile]="isMobile" [enTheme]="enTheme" (eventBubble)="eventBubble($event)"
    (onErpRefresh)="onRefresh($event)" (onBack)="onGeBack($event)" (eventAfterCompleted)="afterCompleted($event)"
    (afterSaved)="afterSaved($event)"></lib-cmp-atividade>

</div>
