<div class="container">

  <h3>{{ title }}</h3>

  <div class="message" *ngIf="message" [innerHTML]="message"></div>

  <mat-toolbar>

    <button mat-raised-button (click)="onNoClick()">
      <mat-icon>{{ icon }}</mat-icon>{{ btnOK }}
    </button>

  </mat-toolbar>

</div>