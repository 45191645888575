// WEBAPI CONTROLLER
export const routeGetFormByName = 'Form/GetFormByName';
export const routeGetFormByNameWithCache = 'Form/GetFormByNameWitchCache';
export const routeGetLabelValueComboBoxByForm = 'Model/GetLabelValueComboBoxByForm';
export const routeGetSubProcessByUser = 'Process/GetSubProcessByUser';
export const routeGetBySchool = 'Person/GetBySchool';
export const routeGetAllAuthorized = 'Person/GetAllAuthorized';
export const routeGetForm = 'Form/GetForm';
export const routeGetDataRegister = 'Form/GetDataRegister';
// MEDLOGIC
export const routeGetPatientsWithMedications = 'Form/GetPatientsWithMedications';
export const routeGetAuthorizedPatients = 'Form/GetAuthorizedPatients';
export const routeGetMedLogic = "api/MedLogic";
export const routeGetStyle = "Style/GetStyle";
export const routeGetWSServer = "api/WSServer";
export const routeGetIvcf = "Ivcf";
export const routeGetRawMedication = "Medication/Raw";
export const routeGetHistoryMedication = "Medication/History";
export const routeGetTherapeuticByNames = "Medication/TherapeuticByNames";
export const routeGetAuthorizedInterventions = "Intervention/Authorized";
export const routeGetMedicationCountForPatients = "Medication/GetCountForPatients";
export const routePostCheckMedicationStock = 'Prescription/PostCheckMedicationStock';
export const routeGetWebsocketRoomMessage = 'Prescription/GetWebsocketRoomMessage';
export const routePostManyCheckins = 'Medication/PostManyCheckins';


// FILEUPLOAD CONTROLLER
export const routePostAttachment = 'File/PostAttachment';
export const routeGetAttachmentHeaders = 'File/GetAttachmentHeaders';
export const routeDeleteAttachment = 'File/DeleteAttachment';

// NetcoreApiController
export const routeAuthWebApiBase = 'ApplicationUser';
export const routeUserExist = `${routeAuthWebApiBase}/UserExist`;
export const routeRegister = `${routeAuthWebApiBase}/Register`;
export const routeLogin = `${routeAuthWebApiBase}/Login`;
export const routeUserRoles = `${routeAuthWebApiBase}/UserRoles`;
export const routeEnableUser = `${routeAuthWebApiBase}/EnableUser`;

// PythonAPIController
export const routeSendEmail = 'Email/Send';
export const routeGenerateToken = 'Token/Generate';
export const routeValidateToken = 'Token/Validate';
export const routeFileUpload = 'File/HttpFileUpload';

// Webservice
export const routePostWebService = 'PostWebService';

// Domain Controllers
export const routeGetPostsWithRelationships = 'Post/GetPostsWithRelationships';

// Redis
export const routeRedisGetKeys = 'Redis/GetKeys';
export const routeRedisGetHashes = 'Redis/GetHashes';
export const routeRedisDeleteKeys = 'Redis/DeleteKeys';
export const routeRedisDeleteTenantKeys = 'Redis/DeleteTenantKeys';
export const routeDeleteAllCaches = 'Redis/DeleteAllCaches';
export const routeDeleteRedisInvalidateCache = 'Redis/DeleteInvalidateCache';
export const routeDeleteRedisAllByTenantId = 'Redis/DeleteRedisAllByTenantId';
export const routeDeleteRedisAllByCustomerId = 'Redis/DeleteRedisAllByCustomerId';
export const routeDeleteRedisAllByUserId = 'Redis/DeleteRedisAllByUserId';
export const routeRedisPostLog = 'Redis/PostLog';
export const routeRedisPostLogPrescription = 'Redis/PostLogPrescription';

// internal routes
export const routeInternalMedlogicevolutionGetHasOccurrenceFromCache = 'medlogicevolution/getHasOccurrenceFromCache$';
export const routeInternalIntervencoesMedicamentosServiceHorarioGetAllFromCache = 'intervencoesmedicamentosservicehorario/getAllFromCache$';
export const routeInternalInterventionMedicationGetAllFromCache = 'interventionmedication/getAllFromCache$';
export const routeInternalMedicationCheckinGetAllFromCache = 'medicationcheckin/getAllFromCache';
export const routeInternalPatientGetAllFromCache = 'patient/getAllFromCache$';
export const routeInternalVitalSignGetHasOccurrenceFromCache = 'vitalsign/getHasOccurrenceFromCache$';
export const routeInternalIvcfGetAllFromCache = 'ivcf/getAllFromCache$';

// Assets
export const assetRawMedication = 'assets/data/raw-medication.json';
export const assetTherapeuticSafe = 'assets/data/terapeutica-segura.json';
