<lib-ge-blueprint [isLoading]="(isLoading | async)" [isMobile]="isMobile" [enTheme]="enTheme" module="ATIVIDADE"></lib-ge-blueprint>

<!-- A Ocorrencia Existe ou é uma Nova -->
<div *ngIf="newOrExists()">

  <form #form [formGroup]="formGroup" *ngIf="actived" (ngSubmit)="onSubmit()">
  
    <lib-tab #tabComponent [attr.data-ono]="ocorrenciaNo" [activityType]="activityType" [saveInList]="saveInList" [addToHistory]="true"
      [componentes]="componentes" [atividade]="atividade" [tabs]="tabs" [isMobile]="isMobile"
      [tabActivedId]="tabActivedId" [enTheme]="enTheme" [saved]="saved" [formGroup]="formGroup"
      [formErrors]="formErrors" [isLoading]="isLoading | async" [atividadeNo]="atividadeNo" [atividadeNome]="atividadeNome"
      [ocorrenciaNo]="ocorrenciaNo" [canShowSavedMessages]="canShowSavedMessages" [backAtividadeNo]="backAtividadeNo"
      [backOcorrenciaNo]="backOcorrenciaNo" [isEditMode]="isEditMode" (eventBubble)="onEventBubble($event)"></lib-tab>

  </form>

</div>

<!--A Ocorrencia que se tenta editar não existe-->
<div *ngIf="!newOrExists()">
  <!--Antes de exibir essa mensagem é necessário criar uma maneira de realmente saber se a ocorrencia não existe-->
  <!--<h1>Desculpe, mas você tentou editar a Ocorrência {{ ocorrenciaNo }}, mas ela não existe!</h1>-->
</div>

<p-toast position="bottom-center"></p-toast>
<!--Mensagem de confirmação de exclusão -->

<p-confirmDialog header="Confirmação" icon="fa fa-question-circle" [rejectIcon]="message.icon"
  [rejectLabel]="message.firstButtonLabel" acceptLabel="Sim" [style]="{ 'width': '425px'}">
</p-confirmDialog>

<lib-debug *ngIf="isDebug" [msg]="debugMsg"></lib-debug>
