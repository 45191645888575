import { IIvcf } from '@medlogic/shared/shared-interfaces';
import { LogService, EnUserRole, GlobalService, IListItem } from '@medlogic/shared/shared-interfaces';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { ItemSearchPipe } from '@medlogic/shared/utils';

export const selectIvcf = (state: IAppMedlogicState) => state?.ivcf?.ivcf;
export const selectAllIvcfs = (state: IAppMedlogicState) => state?.ivcf?.ivcfs;
export const selectIvcfError = (state: IAppMedlogicState) => state?.ivcf?.error;

const glb = new GlobalService();
const log = new LogService();


/* Operador ivcfalizado que converte os ivcfos em módulos. */
const toIListItem = ((ivcf: IIvcf) => {
  return ({
    id: ivcf?.codigo,
    identification1: ivcf?.codigo,
    identification2: ivcf?.titulo,
    topLeft: ivcf?.codigo,
    bottomLeft: ivcf?.codigo,
    topRight: ivcf?.codigo,
    bottomRight: '',
    color: 'green',
    imgUrl: '',
    obj: ivcf
  } as IListItem<IIvcf>);
})

function uniqueDates(itens: any[]) {
  const datas: Date[] = [];
  if (itens.length > 1) {
    itens.forEach(item => {
      if (!datas.find(f => glb.isEqualIgnoreTime(f, glb.YYYYMMddThhmmssToDate(item.obj.data)))) {
        datas.push(glb.YYYYMMddThhmmssToDate(item.obj.data));
      }
    });
    return datas;
  }
}

export const selectIvcfsAsIListItems = (state: any) => {
  return state?.ivcf?.ivcf?.map(toIListItem)[0];
};

const filterBySearch = (items: any[], keyword: string) => { // ISmartIvcf<IModule>
  return new ItemSearchPipe(log, glb).transform(items, keyword);
}

export const selectIvcfDefaultFormControls = (state: IAppMedlogicState): any => {
  const isEducator = [EnUserRole.customer, EnUserRole.educator, EnUserRole.professional].includes(state?.tenant?.userRole);
  const isSpecialist = [EnUserRole.professional].includes(state?.tenant?.userRole);
  const isParent = [EnUserRole.responsible].includes(state?.tenant?.userRole);
  const userName = state?.login?.userName;
  const patient = state?.person?.person;
  return {
    V_104052: userName,
    V_102283: state?.person?.person?.codigo,
    V_104049: glb.dateToYYYYMMddThhmmss(new Date()),
    // V_102725: isEducator ? userName : patient?.nomeEducadorProfissionalResponsavel,
    // V_102727: isParent ? userName : patient?.nomeresponsavelLegal,
    // V_102728: patient?.grauparentesco,
    // V_102303: state?.school?.selectedSchool?.codigo || state?.login?.selectedLogin?.customerId,
    V_103860: isEducator ? state?.login?.selectedLogin?.usuarioLogadoNome : '',
    V_103865: isSpecialist ? state?.login?.selectedLogin?.usuarioLogadoNome : '',
  };


}
