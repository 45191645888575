<lib-ge-blueprint [isLoading]="isLoading | async" [isMobile]="isMobile" [enTheme]="enTheme" module="ATIVIDADE">
</lib-ge-blueprint>

<ui-loading [isLoading]="isLoadingAfterComplete"> </ui-loading>

<form #form [formGroup]="formGroup" *ngIf="actived && !(isLoading | async)" (ngSubmit)="onSubmit()">

  <lib-tab [attr.data-ono]="ocorrenciaNo" [isActionBarVisible]="false" [activityType]="activityType" #tabComponent
    [saveInList]="saveInList" [addToHistory]="true" [componentes]="componentes" [atividade]="atividade"
    [ocorrenciaNo]="ocorrenciaNo" [atividadeNome]="atividadeNome" [atividadeNo]="atividadeNo" [tabs]="tabs"
    [isMobile]="isMobile" [tabActivedId]="tabActivedId" [saved]="saved" [formGroup]="formGroup"
    [isLoading]="isLoadingAfterComplete" [isEditMode]="isEditMode" [formErrors]="formErrors"
    (eventBubble)="onEventBubble($event)"></lib-tab>

</form>

<!--Mensagem de confirmação de exclusão -->
<p-confirmDialog header="Confirmação" icon="fa fa-question-circle" [rejectIcon]="message.icon"
  [rejectLabel]="message.firstButtonLabel" acceptLabel="Sim" [style]="{ 'width':'425px' }">
</p-confirmDialog>

<lib-debug *ngIf="isDebug" [msg]="debugMsg"></lib-debug>