<div [class.isMobile]="isMobile">

  <!-- <button mat-button type="button" class="btn btn-block" (click)="onBack($event)">
    < Voltar</button> -->

  <div class="top-bar" [class.isMobile]="isMobile">
    <button mat-icon-button class="btn btn-back" (click)="onBack($event)">
      <mat-icon class="icn-back">arrow_back_ios</mat-icon>VOLTAR
    </button>
  </div>

</div>

<div class="wrapper wrapper-content animated fadeInRight" [class.isMobile]="isMobile">

  <lib-atividade-cadastro-view [atividadeNo]="atividadeNo" [processoNo]="processoNo" [enTheme]="enTheme"
    [isReadOnly]="false" [ocorrenciaNo]="ocorrenciaNo" [saveInList]="true" [isMobile]="isMobile"
    [defaultFormControls]="defaultFormControls" (afterCompleted)="afterCompleted($event)"
    (eventBubble)="eventBubble($event)" (afterSaved)="afterSaved($event)">
  </lib-atividade-cadastro-view>

  <!-- NOVO <lib-cmp-atividade [usuarioNo]="usuarioNo$ | async" [saveInList]="saveInList"
  [ocorrenciaNo]="ocorrenciaNo$ | async" [atividadeNo]="atividadeNo$ | async" [processoNo]="processoNo$ | async"
  [defaultFormControls]="defaultFormControls$ | async" [isReadOnly]="isReadOnly" [saveInList]="saveInList"
  [isMobile]="isMobile" [enTheme]="enTheme$ | async" (onErpRefresh)="onRefresh($event)" (onBack)="onGeBack($event)"
  (eventAfterCompleted)="afterCompleted($event)" (afterSaved)="afterSaved($event)"></lib-cmp-atividade> -->

</div>