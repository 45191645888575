import { MaterialModule } from '@medlogic/shared/material';
import { UiDialogAlertComponent } from './ui-dialog-alert/ui-dialog-alert.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const COMPONENTS = [
  UiDialogAlertComponent
];

const MODULES = [
  CommonModule,
  MaterialModule
]

@NgModule({
  declarations: [COMPONENTS],
  imports: [MODULES],
  exports: [COMPONENTS],
  entryComponents: [UiDialogAlertComponent]
})
export class UiDialogAlertModule { }
