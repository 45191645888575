import { MedicationCheckinCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMedicationCheckin } from '@medlogic/shared/shared-interfaces';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { IMedication } from '@medlogic/shared/shared-interfaces';
import { catchError } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IForm } from '@medlogic/shared/gecore';

@Injectable()
export class MedicationCheckinPwaCustomService extends MedicationCheckinCustomService {

    /* Insere ou atualiza o item.
     * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
     * Se houver uma instrução de executeNow irá checar a conexão com a Internet.
     * Caso haja, irá tentar salvar. Se não conseguir, irá enfileirar a chamada.
     */
    save(
        ano: number,
        medicationCheckin: IMedicationCheckin,
        patient: IPatient,
        medication: IMedication,
        uno: number,
        id?: number,
        executeNow: boolean = true
    ): Observable<any> {
        try {
            this.cadastroNo = ano;
            const forms: IForm[] = this.mapToForm(medicationCheckin).filter(
                (f) => !this.glb.IsNullOrEmpty(f.ValorDado)
            );
            const key = id || this.generateKey(patient.codigo, medication, medicationCheckin.dataCheckin);
            const saveToQueue$ = this.cadastroSrv.saveToQueue(forms, uno, this.cadastroNo, key);
            const save$ = this.cadastroSrv.save(forms, uno, this.cadastroNo, key);
            if (!executeNow) {
                return saveToQueue$;
            } else if (navigator.onLine) {
                return save$.pipe(
                    mergeMap(wasSaved => {
                        if (!wasSaved) {
                            // Como não conseguiu salvar
                            return saveToQueue$;
                        }
                        return of(wasSaved);
                    }),
                    catchError((err, obs) => {
                        // Como deu erro, tenta salvar na fila.
                        return saveToQueue$;
                    })
                );
            } else {
                return saveToQueue$;
            }
        } catch (error) {
            this.log.Registrar(this.constructor.name, 'save', error.message);
        }
    }


}
