import { MedlogicTenantService } from '@medlogic/medlogic/medlogic-data-access';
import { metaReducers } from './+state/medlogic-storage.metareducer';
import { SharedUtilsModule } from '@medlogic/shared/utils';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TenantEffects } from './+state/medlogic-tenant.effects';

import * as fromTenant from './+state/medlogic-tenant.reducer';

const SERVICES = [
  MedlogicTenantService
];

const MODULES = [
  CommonModule,
  SharedUtilsModule,
  StoreModule.forFeature(fromTenant.tenantFeatureKey, fromTenant.reducer, { metaReducers }),
  EffectsModule.forFeature([TenantEffects]),
];

@NgModule({
  imports: [MODULES],
  providers: [SERVICES]
})
export class StateMedlogicTenantModule { }
