<!-- <p-messages [value]="msgs" [style]="{'background-color': 'white', 'color': 'black'}"></p-messages> -->
<div *ngIf="formErrors && cnfJson.showFormErrors" class="alert alert-danger">
  {{ formErrors | json }}
</div>

<div *ngIf="atividade" class="ibox-content" [class.is-mobile]="isMobile" [class.black]="enTheme === 'black'">
  
  <!-- Save ou Confirm -->
  <div *ngIf="hasConfirm" class="box-a">
    <button mat-raised-button type="button" [class.btn-mobile]="isMobile" (click)="onConfirm($event, saveInList)" color="accent"
      [disabled]="isLoading">
      <mat-icon>check_circle</mat-icon> {{ textConfirm }}
    </button>

    <!-- <button class="btn btn-primary" type="button" id="confirm" (click)="onConfirm($event, saveInList)"><i class="fa fa-check"></i>&nbsp;{{
      textConfirm }}</button> -->
  </div>

  <div *ngIf="hasSave" class="box-b">
    <!-- <button class="btn btn-primary" type="submit" id="save"><i class="fa fa-check"></i>&nbsp;{{ textSave }}</button> -->
    <button mat-raised-button type="button" [class.btn-mobile]="isMobile" type="submit" color="primary" [disabled]="isLoading">
      <mat-icon>done</mat-icon> {{ textSave }}
    </button>
  </div>

  <!-- fim Save ou Confirm -->
  <div *ngIf="hasSaveAndNew" class="box-c text-left">
    <!-- <button class="btn btn-info" type="button" id="saveAndNew" (click)="onSaveAndNew($event)"><i class="fa fa-check-square-o"></i>&nbsp;{{
      textSaveAndNew }}</button> -->
    <button mat-raised-button type="button" [class.btn-mobile]="isMobile" (click)="onSaveAndNew($event)" color="primary"
      [disabled]="isLoading">
      <mat-icon>done_outline</mat-icon> {{ textSaveAndNew }}
    </button>
  </div>

  <div *ngIf="hasDuplicate" class="box-d text-left">
    <!-- <button class="btn btn-success" type="button" id="duplicate" (click)="onDuplicate($event)"><i class="fa fa-copy"></i>&nbsp;{{
      textDuplicate }}</button> -->
    <button mat-raised-button type="button" [class.btn-mobile]="isMobile" (click)="onDuplicate($event)" color="primary"
      [disabled]="isLoading">
      <mat-icon>file_copy</mat-icon> {{ textDuplicate }}
    </button>
  </div>
  
  <!-- <div *ngIf="hasDelete" class="box-e">
    <button mat-icon-button [class.btn-mobile]="isMobile" color="warn" (click)="onDelete($event)">
      <mat-icon>clear</mat-icon>{{textDelete }}
    </button>
  </div>
  <div *ngIf="hasPrint" class="box-f">
    <button mat-flat-button [class.btn-mobile]="isMobile" (click)="onPrint($event)" matBadgePosition="before"
      matBadgeColor="accent">
      <mat-icon>print</mat-icon> {{ textPrint }}
    </button>
  </div>
  <div *ngIf="hasAttachment" class="box-g">
    <button mat-flat-button [class.btn-mobile]="isMobile" (click)="onAttachment($event)" matBadge="attachmentCount"
      matBadgePosition="before" matBadgeColor="accent">
      <mat-icon>attach_file</mat-icon> {{ textAttachment }}
    </button>
  </div> -->
</div>
