<div class="grid" *ngLet="numOfMedications$ | async as numOfMedications">

  <button type="button" class="btn btn-white btn-block" (click)="timelineClick(patient)">
    <mat-icon class="warning" *ngIf="patient?.calcHasEvolutionOccurrence">announcement</mat-icon>
    <i class="fa fa-list mr"> </i>
    <span class="bold">Linha do Tempo </span>
  </button>

  <ng-container *ngLet="(hasMedication$ | async) as hasMedication">

    <button type="button" class="btn btn-white btn-block"
      (click)="medications(patient, numOfMedications, hasMedication)">
      <i class="fa fa-medkit mr"> </i>
      <span class="bold">Registrar Medicação <span *ngIf="numOfMedications > 0">({{ numOfMedications }})</span></span>
    </button>

  </ng-container>

  <button type="button" class="btn btn-white btn-block" (click)="vitalSigns(patient)">
    <mat-icon class="alert" *ngIf="patient?.calcHasVitalSignOccurrence">report_problem</mat-icon>
    <i class="fa fa-heart-o mr"> </i>
    <span class="bold">Registrar Sinais Vitais </span>
  </button>

  <button type="button" class="btn btn-white btn-block" (click)="evolution(patient)">
    <mat-icon class="alert" *ngIf="patient?.calcHasEvolutionOccurrence">report_problem</mat-icon>
    <i class="fa fa-check mr"> </i>
    <span class="bold">Registrar Evolução </span>
  </button>

  <button type="button" class="btn btn-white btn-block" (click)="emergencyData(patient)">
    <mat-icon class="warning" *ngIf="patient?.calcHasOccurrence">announcement</mat-icon>
    <i class="fa fa-plus-square mr"> </i>
    <span class="bold">Resumo do Paciente </span>
  </button>

  <!-- Menus personalizados -->

  <ng-container *ngIf="usrProcess$ | async as usrProcess; else carregamento">

    <ng-container *ngIf="usrProcess?.length > 0">

      <button *ngFor="let process of usrProcess" type="button" class="btn btn-white btn-block"
        (click)="onClickProcess(process, patient)">
        <img *ngIf="showImg(process)" [src]="process?.UrlIcon" class="icn" (error)="swapImage($event.target)" />
        <span class="bold">{{ process?.Label }}</span>
      </button>

    </ng-container>

  </ng-container>

  <ng-template #carregamento>
    <br />
    <span>Processos do Usuário em Carregamento...</span>
  </ng-template>

</div>