import { IAppMedlogicState } from './../../../../../medlogic-shared-interfaces/src/lib/interface/iapp-medlogic-state';
import { EnStockChangeMode, EnTheme } from '@medlogic/shared/shared-interfaces';
import { EnUserRole, IModule, LogService } from '@medlogic/shared/shared-interfaces';
import { ITenantState } from '@medlogic/shared/shared-interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EnCustomerAction } from './medlogic-state-tenant.enums';

import * as fromTenant from './medlogic-tenant.reducer';

const log = new LogService();

export const selectTenant = (state: ITenantState) => state;
export const selectUserRole = (state: ITenantState) => state?.userRole;
export const selectTenantOno = (state: ITenantState) => state?.ono || -1;
export const selectTenantTheme = (state: IAppMedlogicState) => state?.tenant?.enTheme || EnTheme.default;

export const selectDefaultFormControlFromTenant = (
    state: ITenantState,
    vnoId: string,
    vnoIdentification1: string,
    vnoIdentification2: string
) => {
    try {
        const obj = {};
        if (state?.customerId) {
            obj[`${vnoId}`] = state?.customerId;
        }
        if (state?.identification1) {
            obj[`${vnoIdentification1}`] = state?.identification1;
        }
        if (state?.identification2) {
            obj[`${vnoIdentification2}`] = state?.identification2;
        }
        return obj;
    } catch (error) {
        log.Registrar('tenant.selectors', 'selectDefaultFormControlFromTenant', error.message);
    }
    return null;
};

export const selectEnCustomerAction = (state: ITenantState, selectedProcess: IModule) => {
    try {
        const tenant = selectTenant(state);
        const isCustomerFirstAccess = (tenant?.userRole === EnUserRole.customer) && !tenant?.customerId;

        const isCustomerRegistration = tenant?.userRole === EnUserRole.customer &&
            tenant?.selectedTenant?.customerAno === selectedProcess?.ObjInfo?.AtividadeNo;

        const enCustomerAction = isCustomerFirstAccess ?
            EnCustomerAction.FirstAccess :
            (isCustomerRegistration ?
                EnCustomerAction.CustomerRegistration :
                EnCustomerAction.CustomerAction
            );
        return { tenant, enCustomerAction };
    } catch (error) {
        log.Registrar('tenant.selectors', 'selectEnCustomerAction', error.message);
    }
    return null;
};

export const selectPrivacyDefaultFormControls = (state: any) => { // IAppMedlogicState
    return {
        V_103635: state?.tenant?.loggedUserName || '',
        V_391: new Date()
    }
}
export const selectTenantState = createFeatureSelector<ITenantState>(fromTenant?.tenantFeatureKey);
export const selectTenantIsUpdateStockOnApp = createSelector(
    selectTenantState,
    (state) => state?.selectedTenant?.enStockChangeMode === EnStockChangeMode.checkin
);

// export const selectEnCustomerAction = createSelector(
//     selectTenant,
//     selectProcess,
//     (tenant: ITenantState, process: IModule) => {
//         const isCustomerFirstAccess = (tenant.userRole === EnUserRole.customer) && !tenant.customerId;
//         const isCustomerRegistration = tenant.userRole === EnUserRole.customer &&
//             tenant.selectedTenant.customerAno === process.ObjInfo.AtividadeNo;
//         if (isCustomerFirstAccess) {
//             return EnCustomerAction.FirstAccess;
//         } else if (isCustomerRegistration) {
//             return EnCustomerAction.CustomerRegistration;
//         }
//         return EnCustomerAction.CustomerAction;
//     }
// );
