
import { IMedlogicEvolution } from '@medlogic/shared/shared-interfaces';
import { HttpClient } from '@angular/common/http';
import { UnsubscribeOnDestroyAdapter, GlobalService, LogService, IForm, EnTypedValue } from '@medlogic/shared/shared-interfaces';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { Observable, of } from 'rxjs';
import { map, publishReplay, refCount, toArray } from 'rxjs/operators';

export abstract class EvolutionService extends UnsubscribeOnDestroyAdapter {

  // tslint:disable-next-line: max-line-length
  protected lstVariaveis = 'V_353,V_387,V_391,V_1608,V_2230,V_3328,V_3329,V_28051,V_28091,V_28092,V_28097,V_28595,V_28611,V_28626,V_28660,V_28759,V_28887,V_28888,V_28889,V_28927,V_28929,V_28930,V_28931,V_28932,V_28933,V_28971,V_29069,V_29070,V_29071,V_29072,V_29073,V_29977,V_31918,V_31922,V_33925,V_34202,V_34205,V_34315,V_34316,V_34317,V_34318,V_34319,V_34320,V_34322,V_34325,V_101593,V_34340,V_28610';
  private variavelGrid = '';
  private lstVariaveisGrid = '';

  recurrences: IMedlogicEvolution[] = new Array<IMedlogicEvolution>();

  cadastroNo = 11266;
  currentDtInicial: Date = new Date();
  currentDtFinal: Date = new Date();
  cadastrosCache: Observable<any>;

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    super();
  }

  /* Retorna somente as variáveis desejadas.
   * lstVariables do tipo: "V_3332,V_32223"
   */
  getSome(ano: number, lstVariables: string, startDate?: Date, endDate?: Date): Observable<IMedlogicEvolution> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate, lstVariables);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSome', error.message);
    }
    return of(null);
  }

  getAll(ano: number, startDate?: Date, endDate?: Date): Observable<IMedlogicEvolution> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      return this.getWithCache(this.cadastroNo, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAll', error.message);
    }
    return of(null);
  }

  /* Método utilizado para popular uma lista com os itens ativos. */
  loadArray(ano: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const propLabel = 'titulo'; const propValue = 'codigo'; const propEnabled = 'habilitado';
      return this.cadastroSrv.loadArray(this.getAll(ano), propLabel, propValue, propEnabled);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'loadArray', error.message);
    }
    return of(null);
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.cadastrosCache = null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  protected getWithCache(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<IMedlogicEvolution> {
    if (
      (startDate.getTime() !== this.currentDtInicial.getTime())
      || (endDate.getTime() !== this.currentDtFinal.getTime())
      || (!this.cadastrosCache)
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  protected getFromCadastro(cadastroNo: number, startDate: Date, endDate: Date, lstVariables: string = null): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      lstVariables = lstVariables || this.lstVariaveis;
      console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv
        .getCadastro(cadastroNo, lstVariables, startDate, endDate).pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }

  /* Mapeia para o objeto principal. */
  protected toAttribute(c: any): IMedlogicEvolution {
    try {
      return {
        desidratacao: this.glb.getBoolean(c.V_353),
        residente: c.V_387,
        dtNascimento: c.V_391,
        nomemae: c.V_1608,
        codigo: c.V_28610,
        codigoPaciente: c.V_2230,
        identificacao1: c.V_3328,
        identificacao2: c.V_3329,
        prontuario: c.V_28051,
        intensidade: c.V_28091,
        senteDor: this.glb.getBoolean(c.V_28092),
        intensidadeDorEvaURL: c.V_28097,
        // dataAvaliacao: this.getDataAvaliacao(c),
        dataAvaliacao: this.glb.getTypedValue(c.V_28595).value,
        titulo: c.V_28611,
        oCORRENCIA: c.V_28626,
        obito: this.glb.getBoolean(c.V_28660),
        ulceraDecubito: this.glb.getBoolean(c.V_28759),
        queixaPaciente: c.V_28887,
        realizouConduta: this.glb.getBoolean(c.V_28888),
        houveIntercorrencias: this.glb.getBoolean(c.V_28889),
        oPacienteEvacuaRegularmente: this.glb.getBoolean(c.V_28927),
        ultimaEvacuacaoOcorreurmais2Dias: this.glb.getBoolean(c.V_28929),
        oPacienteEstaIngerindoUmaQuantidadeAdequadafibras: this.glb.getBoolean(c.V_28930),
        oPacienteEstahidratandoAdequadamente: this.glb.getBoolean(c.V_28931),
        oPacienteUtilizaLaxante: this.glb.getBoolean(c.V_28932),
        qual: c.V_28933,
        condutaEvolucao: c.V_28971,
        diarreia: this.glb.getBoolean(c.V_29069),
        tomouBanho: this.glb.getBoolean(c.V_29070),
        horario: c.V_29071,
        diurese: this.glb.getBoolean(c.V_29072),
        higieneBucal: this.glb.getBoolean(c.V_29073),
        executorAvaliacao: c.V_34340,
        glicose: c.V_31918,
        temperaturaTax: c.V_31922,
        evolucaoDescritiva: c.V_33925,
        hospede: c.V_34202,
        habilitado: this.glb.getBoolean(c.V_34205),
        ingestao: this.glb.getBoolean(c.V_34315),
        pASistolica: c.V_34316,
        pADiastolica: c.V_34317,
        quedaComLesao: this.glb.getBoolean(c.V_34318),
        quedaSemLesao: this.glb.getBoolean(c.V_34319),
        tentativaSuicidio: this.glb.getBoolean(c.V_34320),
        escabiose: this.glb.getBoolean(c.V_34322),
        desnutricao: this.glb.getBoolean(c.V_34325),
        dataHoraEvolucao: this.glb.ddMMYYYYThhmmssToDate(c.V_101593)
      } as IMedlogicEvolution;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
    }
    return null;
  }

  /* Retorna a data da avaliação. Tentará extrair do título, uma vez que o app não está gravando o horário adequadamente.
    *  TODO: o campo dataavaliacao deveria ser utilizado para esse fim, no entanto, a hora está vindo sempre 00:00.
    * Se a data for inválida, retornará dataavaliacao.
   */
  protected getDataAvaliacao(item: any): Date {
    try {
      // const time = item.V_29071;
      // const typed = this.glb.getTypedValue(time);
      // if (typed.type === EnTypedValue.Date) {
      //   return this.glb.ddMMYYYYThhmmssToDate(time);
      // } else if (this.glb.IsHourHHHMM(time)) {
      //   const dt = this.glb.ddMMYYYYThhmmssToDate(item.V_28595);
      //   return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), this.glb.getHora(time), this.glb.getMinuto(time));
      // } else {
      if (!item || (!item.V_101593 && !item.V_28611 && !item.V_28595)) {
        return null;
      }
      const typed1 = this.glb.getTypedValue(item.V_101593);
      if (typed1.type === EnTypedValue.Date) {
        return typed1.value;
      } else {
        const split = item.V_28611.split('_');
        if (split && split.length > 1) {
          const typed = this.glb.getTypedValue(split[1]);
          if (typed.type === EnTypedValue.Date) {
            return typed.value;
          }
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDataAvaliacao', error.message);
    }
    return this.glb.ddMMYYYYThhmmssToDate(item.V_28595);
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}` */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IMedlogicEvolution> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true, startDate, endDate)
        .pipe(
          map(c => this.toAttribute(c)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Insere ou atualiza o item.
    * Se for atualização, especificar o id. Caso contrário, não fornecê-lo.
    */
  save(ano: number, evolution: IMedlogicEvolution, uno: number, id?: number): Observable<any> {
    try {
      this.cadastroNo = ano;
      const forms: IForm[] = this.mapToForm(evolution).filter(f => f.ValorDado);
      return this.cadastroSrv.save(forms, uno, this.cadastroNo, id);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'save', error.message);
    }
    return of(null);
  }

  protected mapToForm(evolution: IMedlogicEvolution): IForm[] {
    try {
      return [
        { VariavelNo: 353, ValorDado: evolution.desidratacao ? 'SIM' : 'NÃO' },
        { VariavelNo: 387, ValorDado: evolution.residente || '' },
        { VariavelNo: 391, ValorDado: evolution.dtNascimento || '' },
        { VariavelNo: 1608, ValorDado: evolution.nomemae || '' },
        { VariavelNo: 2230, ValorDado: evolution.codigoPaciente || '' },
        { VariavelNo: 3328, ValorDado: evolution.identificacao1 || '' },
        { VariavelNo: 3329, ValorDado: evolution.identificacao2 || '' },
        { VariavelNo: 28051, ValorDado: evolution.prontuario || '' },
        { VariavelNo: 28091, ValorDado: evolution.intensidade || '' },
        { VariavelNo: 28092, ValorDado: evolution.senteDor ? 'SIM' : 'NÃO' },
        { VariavelNo: 28097, ValorDado: evolution.intensidadeDorEvaURL || '' },
        { VariavelNo: 28595, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolution.dataAvaliacao) },
        { VariavelNo: 28611, ValorDado: evolution.titulo || '' },
        { VariavelNo: 28626, ValorDado: evolution.oCORRENCIA || '' },
        { VariavelNo: 28660, ValorDado: evolution.obito ? 'SIM' : 'NÃO' },
        { VariavelNo: 28759, ValorDado: evolution.ulceraDecubito ? 'SIM' : 'NÃO' },
        { VariavelNo: 28887, ValorDado: evolution.queixaPaciente || '' },
        { VariavelNo: 28888, ValorDado: evolution.realizouConduta ? 'SIM' : 'NÃO' },
        { VariavelNo: 28889, ValorDado: evolution.houveIntercorrencias ? 'SIM' : 'NÃO' },
        { VariavelNo: 28927, ValorDado: evolution.oPacienteEvacuaRegularmente ? 'SIM' : 'NÃO' },
        { VariavelNo: 28929, ValorDado: evolution.ultimaEvacuacaoOcorreurmais2Dias ? 'SIM' : 'NÃO' },
        { VariavelNo: 28930, ValorDado: evolution.oPacienteEstaIngerindoUmaQuantidadeAdequadafibras ? 'SIM' : 'NÃO' },
        { VariavelNo: 28931, ValorDado: evolution.oPacienteEstahidratandoAdequadamente ? 'SIM' : 'NÃO' },
        { VariavelNo: 28932, ValorDado: evolution.oPacienteUtilizaLaxante ? 'SIM' : 'NÃO' },
        { VariavelNo: 28933, ValorDado: evolution.qual || '' },
        { VariavelNo: 28971, ValorDado: evolution.condutaEvolucao || '' },
        { VariavelNo: 29069, ValorDado: evolution.diarreia ? 'SIM' : 'NÃO' },
        { VariavelNo: 29070, ValorDado: evolution.tomouBanho ? 'SIM' : 'NÃO' },
        { VariavelNo: 29071, ValorDado: evolution.horario || '' },
        { VariavelNo: 29072, ValorDado: evolution.diurese ? 'SIM' : 'NÃO' },
        { VariavelNo: 29073, ValorDado: evolution.higieneBucal ? 'SIM' : 'NÃO' },
        { VariavelNo: 29977, ValorDado: evolution.executorAvaliacao || '' },
        { VariavelNo: 31918, ValorDado: evolution.glicose || '' },
        { VariavelNo: 31922, ValorDado: evolution.temperaturaTax || '' },
        { VariavelNo: 33925, ValorDado: evolution.evolucaoDescritiva || '' },
        { VariavelNo: 34202, ValorDado: evolution.hospede || '' },
        { VariavelNo: 34205, ValorDado: evolution.habilitado ? 'SIM' : 'NÃO' },
        { VariavelNo: 34315, ValorDado: evolution.ingestao ? 'SIM' : 'NÃO' },
        { VariavelNo: 34316, ValorDado: evolution.pASistolica || '' },
        { VariavelNo: 34317, ValorDado: evolution.pADiastolica || '' },
        { VariavelNo: 34318, ValorDado: evolution.quedaComLesao ? 'SIM' : 'NÃO' },
        { VariavelNo: 34319, ValorDado: evolution.quedaSemLesao ? 'SIM' : 'NÃO' },
        { VariavelNo: 34320, ValorDado: evolution.tentativaSuicidio ? 'SIM' : 'NÃO' },
        { VariavelNo: 34322, ValorDado: evolution.escabiose ? 'SIM' : 'NÃO' },
        { VariavelNo: 34325, ValorDado: evolution.desnutricao ? 'SIM' : 'NÃO' },
        { VariavelNo: 101593, ValorDado: this.glb.ddMMYYYYThhmmssToDate(evolution.dataHoraEvolucao) },
        { VariavelNo: 28610, ValorDado: evolution.codigo || '' },
      ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToForm', error.message);
    }
    return null;
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  insertIfNotExist(ano: number, evolution: IMedlogicEvolution, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return new Observable(observer => {
        this.subs.sink = this.getFromCadastro(ano, null, null).pipe(
          toArray())
          .subscribe(items => {
            const founded = items.findIndex(f => this.glb.isEqual(f[compareFieldName], evolution[compareFieldName])) >= 0;
            observer.next(founded);
            if (!founded) {
              this.subs.sink = this.save(ano, evolution, uno)
                .subscribe(s => observer.complete());
            } else {
              observer.complete();
            }
          });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
    return of(null);
  }


}
