import { FamilyCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { EmailService } from '@medlogic/shared/shared-data-access';
import { IConfigModule, IEmailMessage } from '@medlogic/shared/shared-interfaces';
import { IMedlogicEvolution } from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from './../../service/config-pwa-medlogic.custom.service';
import { ConfigJsonService, IAtividadeComponenteDAL } from '@medlogic/shared/shared-interfaces';
import { IFlashObj } from '../../../assist/interfaces/iflash-obj';
import { Component, OnInit } from '@angular/core';
import { UnsubscribeOnDestroyAdapter, LogService, GlobalService, EnTheme } from '@medlogic/shared/shared-interfaces';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { addEvolution, selectEvolutionsDefaultControls, selectEvolutionSelectedId, sendEvolutionEmail } from '@medlogic/medlogic/medlogic-state';
import { EvolutionCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { FormGroup } from '@angular/forms';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-evolution',
  templateUrl: './evolution.component.html',
  styleUrls: ['./evolution.component.css']
})
export class EvolutionComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  
  // Armazena os valores para preenchimento de um lançamento baseado em recorrência
  // defaultFormControls: Observable<any> = this.store.pipe(select(selectEvolutionsDefaultControls));
  atividadeNo: number;
  processoNo: number;
  enTheme: EnTheme.default;
  isRecurrent = false;
  flashObjs: IFlashObj[];
  description = 'Evolução';
  isEdit = true;
  isReadOnly = false;
  evolutionNo: number;
  ocorrenciaNo: number
  ocorrenciaNo$: Observable<number> = this.store.pipe(select(selectEvolutionSelectedId));
  usuarioNo$: Observable<number> = this.store.pipe(select(state => state?.loginPWA?.selectedLogin?.usuarioLogadoNo));
  atividadeNo$: Observable<number> = this.store.pipe(select(state => state?.tenant?.selectedTenant?.cadEvolutionNo));

  defaultFormControls$: Observable<{ [key: string]: any }> = this.store.pipe(select(selectEvolutionsDefaultControls));

  public get isMobile(): boolean {
    return this.global.isMobile();
  }

  constructor(
    private log: LogService,
    private store: Store<IAppMedlogicState>,
    private cnf: ConfigPwaMedLogicService,
    private route: ActivatedRoute,
    private global: GlobalService,
    private nav: NavigationPwaService,
    private evolutionSrv: EvolutionCustomService) {
    super();
  }

  ngOnInit() {
    try {
      this.nav.addToHistory(this.route.snapshot.url.map(m => m.path), '[evolution] Evolution');
      this.getUrlParams(this.route);
      this.ocorrenciaNo$.subscribe(s => this.ocorrenciaNo = s);
      if (this.ocorrenciaNo && this.ocorrenciaNo > 0) {
        this.isReadOnly = true;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Carrega os dados. */
  // refresh(evolutionNo: number): void {
  //   try {
  //     // Carregamento da Atividade
  //     this.ocorrenciaNo = -1; // Nova ocorrência
  //     this.atividadeNo = evolutionNo;
  //     this.defaultFormControls = this.getDefaultFormControls(
  //       this.cnf.OcorrenciaNo.value,
  //       this.cnf.selectedPatient,
  //       this.cnf.usuarioLogadoNo,
  //       this.cnf.usuarioLogadoNome
  //     );
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'refresh', error.message);
  //   }
  // }

  /* Injeta os valores de variáveis, auto preenchendo o formulário. */
  // getDefaultFormControls(ono: number, patient: IPatient, professionalNo: number, professionalName: string): any {
  //   try {
  //     const dfc = {
  //       V_28626: ono,
  //       V_29977: professionalName,
  //       V_28841: professionalNo,
  //       V_2230: patient.codigo,
  //       V_28611: `${patient.codigo}_${this.global.dateToYYYYMMddThhmmss(new Date())}`, // Título
  //       V_28051: patient.codigo,
  //       V_34202: patient.nomeHospede
  //     };
  //     return dfc;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getDefaultFormControls', error.message);
  //   }
  // }

  /* Extrai os parâmetros passados na url/rota */
  protected getUrlParams(route: ActivatedRoute): void {
    try { // Os parametros estão sendo passados diretamente aos componentes
      // Para aguardar o carregamento do config
      // this.subs.sink = route.params
      //   .subscribe((params: Params) => {
      //     if (params.key) {
      //       this.evolutionNo = +params.key;
      //     } else {
      //       if (this.cnf.evolutionCadastroNo) {
      //         this.evolutionNo = this.cnf.evolutionCadastroNo;
      //       }
      //     }
      //     this.refresh(this.evolutionNo);
      //   });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }


  /* Permitirá que os controles internos chamem uma rotina de recarregamento e limpeza do cache.
   * É útil, por exemplo, ao cadastrar um grid de rateio, forçar o recarregamento.
   * mas essa função não faz retornar para o Grid, apenas prepara para quando o usuário retornar esteja correto.
   */
  onRefresh(args): void {
    try {
      this.cacheCleanAndRefresh();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onRefresh', error.message);
    }
  }

  /* Limpa o cache de dados e recarrega. */
  protected cacheCleanAndRefresh(): void {
    try {
      // this.refresh(this.cnf.entryCadastroNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cacheClean', error.message);
    }
  }

  onBack(args: any): void {
    try {
      this.nav.goBack();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  onGeBack(args: any): void {
    try {
      this.nav.goBack();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }

  eventBubble($event: any): void {
    try {

    } catch (error) {
      this.log.Registrar(this.constructor.name, 'eventBubble', error.message);
    }
  }

  afterCompleted($event: any): void {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterCompleted', error.message);
    }
  }

  afterSaved(obj: {
    AtividadeNo: number;
    OcorrenciaNo: number;
    ProcessoNo: number;
    TarefaNo: number;
    Componentes: IAtividadeComponenteDAL[];
    fg: FormGroup;
  }): void {
    try {
      // Não é possível retornar automaticamente, se houver
      // operações de grid, edição ==de combo, imprimir, anexos, chamam o salvar.
      const objEvolution = obj.Componentes
        .reduce((a, b) => {
          a[`V_${b.VariavelNo}`] = b.Valor;
          return a;
        }, {});

      // pipe principal
      this.subs.sink = this.evolutionSrv.mapObjToEvolution(objEvolution)
        .pipe(
          tap(evolution => {
            this.store.dispatch(addEvolution({ evolution }));
            this.store.dispatch(sendEvolutionEmail({ evolution }));
            this.nav.gotoEvolutionList();
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSaved', error.message);
    }
  }


}
