import { IDialogData, IPatient } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

import { CustomCookieService } from '@medlogic/shared/utils';


import * as moment_ from 'moment';
const moment = moment_; // Necessário dado a problema de compilação

/**** Conceitualmente, deveria ser utilizado para armazenar o estado de classes que não utilizem ngrx. */
@Injectable({
  providedIn: 'root'
})
export class ConfigStateService {

  version = '1.4.5';
  WEBUSER_NAME = 'addhere.webuser';
  WEBUSER_PASSWORD = '123456';

  // tslint:disable-next-line: variable-name
  private _baseUsuarioToken: string; // Pegar o token do dia, após logar
  public get baseUsuarioToken(): string {
    const storage = localStorage.getItem('baseUsuarioToken') &&
      localStorage.getItem('baseUsuarioToken') !== 'undefined' ?
      localStorage.getItem('baseUsuarioToken') : null;
    return this._baseUsuarioToken || storage;
  }
  public set baseUsuarioToken(v: string) {
    if (v === null) {
      localStorage.removeItem('baseUsuarioToken');
    } else {
      localStorage.setItem('baseUsuarioToken', v);
    }
    this._baseUsuarioToken = v;
  }

  // tslint:disable-next-line: variable-name
  private _usuarioLogadoNome = '';
  public get usuarioLogadoNome(): string {
    const storage = localStorage.getItem('usuarioLogadoNome') &&
      localStorage.getItem('usuarioLogadoNome') !== 'undefined' ?
      localStorage.getItem('usuarioLogadoNome') : null;
    return this._usuarioLogadoNome || storage;
  }
  public set usuarioLogadoNome(v: string) {
    if (v === null) {
      localStorage.removeItem('usuarioLogadoNome');
    } else {
      localStorage.setItem('usuarioLogadoNome', v);
    }
    this._usuarioLogadoNome = v;
  }

  // tslint:disable-next-line: variable-name
  private _tenantId: number;
  public get tenantId(): number {
    const tk = parseInt(this.cookieSrv.get('tenantId'), 10);
    return this._tenantId || tk;
  }
  public set tenantId(v: number) {
    if (!v) {
      this.cookieSrv.delete('tenantId');
    } else {
      this.cookieSrv.putObject('tenantId', v);
    }
    this._tenantId = v;
  }

  private _customerId: number;
  public get customerId(): number {
    const tk = parseInt(this.cookieSrv.get('customerId'), 10);
    return this._customerId || tk;
  }
  public set customerId(v: number) {
    if (!v) {
      this.cookieSrv.delete('customerId');
    } else {
      this.cookieSrv.putObject('customerId', v);
    }
    this._customerId = v;
  }

  // tslint:disable-next-line: variable-name
  private _usuarioLogadoNo: number;
  public get usuarioLogadoNo(): number {
    try {
      if (this._usuarioLogadoNo) {
        return this._usuarioLogadoNo;
      }
      const uno = localStorage.getItem('usuarioLogadoNo') &&
        localStorage.getItem('usuarioLogadoNo') !== 'undefined' ?
        localStorage.getItem('usuarioLogadoNo') : null;
      return uno ? parseInt(uno, 10) : null;
    } catch { }
    return null;
  }

  public set usuarioLogadoNo(v: number) {
    if (v === null) {
      localStorage.removeItem('usuarioLogadoNo');
    } else if (!isNaN(v)) {
      localStorage.setItem('usuarioLogadoNo', v.toString());
      this._usuarioLogadoNo = v;
    }
  }


  /********* GE ****************/
  /*****************************/
  alturaPadrao = 26; // 60;
  /*VARIÁVEIS PARA PASSAGEM DE PARÂMETROS ENTRE PÁGoINAS
  * O formato delas é o mesmo do formGroup {'V_0001': value, 'V_0002': value  }
  */

  /* Parâmetros para manutenção do estado da tela de BuscaOcorrencia. */
  searchParams: any;

  /*CONCEITO: Os valores são transportados da Atividade pai para a filha
  por meio de defaultFormControl e retornam da filha para a pai por setGridItem.
   * Propriedade utilizada para passagem de valores default de uma atividade para outra. */
  protected defaultFormControls: { [ano: string]: any } = {};
  /* Se for um item de grid, os valores serão modificados para que reflitam na atividade que chamou a edição. */
  // tslint:disable-next-line:max-line-length
  // private gridItems: { [ano: string]: any } = {}; // Essa propriedade deverá ser atualizada, para que, ao retornar para a tela do Grid que chamou a edição, os respectivos valores estejam atualizados.

  /* Equivalente ao grid item, mas para selecionar um novo item acrescentado a uma combobox. */
  // listItem: { [key: string]: any } = {};
  /* FIM VARIAVEIS PASSAGEM */

  // Propriedades que identificam os campos fixos de um cadastro
  listIdVariableNo: number;
  listTitleVariableNo: number;
  listEnabledVariableNo: number;

  rptSeparationLabelData: IDialogData;


  countChanges = 0;
  processoNo = -1;
  OcorrenciaNo = new BehaviorSubject<any>(-1);
  TarefaNo = -1;
  observableNewOcorrenciaNo: Observable<number>; // Permite subscrição de notificação para criação de ocorrencia.
  ModeloAtividadeNo: number;
  listVno: number;
  MultiAtividadeNo: number;
  isReadOnly = false;
  saveInList = false;
  // tslint:disable-next-line: max-line-length
  prevState = new Array<{ ano: number, ono: number, listVno: number, pno: number, tno: number, isReadOnly: boolean, saveInList: boolean }>();
  intervalMin = 60;
  startTime = 6;
  endTime = 23;
  // ATENÇÃO: O GE2018 Não está lendo o arquivo de config. Portanto, foram fixados esses parametros como paliativo.
  pending = 0; // Número de registros pendentes de sincronismo
  saved = 0; // Número de itens que estavam pendentes e foram sincronizados
  isOnline = true;
  isForceMobile = true; // Para forçar o comportamento de dispositivo móvel.
  // O comportamento padrão será de disposivo móvel, mas o usuário poderá modificar no menu.
  showMenu = false;    /********* GE ****************/

  selectedPatient: IPatient;

  /********* ERP ****************/
  /*****************************/
  timelineConfig = 1631; // Número do CadastroNo da timeline a ser exibida
  cryptKey = '';
  // tslint:disable-next-line:max-line-length
  loginUnifiedNo: number;
  usuarioNome: string;
  usuarioSenha: string;
  usuarioEmail: string;
  ofxUrl = '';

  modeloConfigNo: number;
  stockCadastroNo: number;
  entryCadastroNo: number;
  workScheduleCadastroNo: number;
  recurrentStartDateVariavelNo = 27909;
  recurrentDueDateVariavelNo = 4645;
  recurrentNumOfInstallmentVariavelNo = 4663;
  vitalSignCadastroNo: number;

  listFormulaRendering = Array<string>();
  evolutionCadastroNo: number;
  /* Controle utilizado para registrar os valores do item do GE modificados,
  // para, por exemplo, atualizar o grid na volta ao invés de limpar o cache. */
  private changedFormControls: { [atividadeNo: number]: any[] } = {};


  /* Checa se o usuário está logado, baseado na existência do token. */
  isLogged(): boolean {
    try {
      return this.hasValue(this.baseUsuarioToken, this.usuarioLogadoNome);
    } catch (error) {
      console.log(this.constructor.name, 'isLogged', error.message);
    }
  }

  /* Verifica se cada um dos parâmetros (values) é diferente de vazio, nulo, ou undefined.
   * Pode receber um conjunto de valores.
   * Replica o que existe na global, pois, config não pode depender de GlobalService.
   */
  protected hasValue(...values: any[]): boolean {
    try {
      return values.reduce((a, b) => a && (b !== undefined) && (b !== null) && (b !== ''), true);
    } catch (error) {
      console.log(this.constructor.name, 'hasValue', error.message);
    }
    return false;
  }

  /* Há parâmetros essenciais para uma atividade que são preenchidos aqui no config. */
  fillActivityParams(
    ano: number = null,
    ono: number = null,
    listVno: number = null,
    pno: number = null,
    tno: number = null,
    isReadOnly: boolean = null,
    saveInList: boolean = null,
    cleanPrevState: boolean = false // Toda vez que uma atividade de início for criada, o estado deveria ser limpo.
  ): void {
    try {
      if (ano) {
        this.ModeloAtividadeNo = +ano;
      }
      if (listVno) {
        this.listVno = +listVno;
      }
      if (isReadOnly !== null && isReadOnly !== undefined) {
        this.isReadOnly = isReadOnly;
      }
      if (saveInList !== null && saveInList !== undefined) {
        this.saveInList = saveInList;
      }
      if (ono) {
        this.OcorrenciaNo.next(+ono);
      }
      if (tno) {
        this.TarefaNo = +tno;
      }
      if (pno) {
        this.processoNo = +pno;
      }
      if (cleanPrevState) {
        this.cleanPrevState();
      }
      this.prevState.push({
        ano,
        ono,
        listVno,
        pno,
        tno,
        isReadOnly,
        saveInList
      });
    } catch (error) {
      console.log(this.constructor.name, 'fillActivityParams', error.message);
    }
  }

  cleanPrevState(): void {
    try {
      this.prevState = [];
    } catch (error) {
      console.log(this.constructor.name, 'cleanPrevState', error.message);
    }
  }

  returnToPreviousState(): void {
    try {
      if (this.prevState && this.prevState.length > 0) {
        this.prevState.pop();
        const prevState = this.prevState[this.prevState.length - 1];
        this.fillActivityParams(
          prevState.ano,
          prevState.ono,
          prevState.listVno,
          prevState.pno,
          prevState.tno,
          prevState.isReadOnly,
          prevState.saveInList
        );
      }
    } catch (error) {
      console.log(this.constructor.name, 'returnToPreviousState', error.message);
    }
  }

  /*Adiciona um item no array de itens modificados.
   * Se for encontrado um item com a mesma ocorrenciaNo, apenas atualizará os valores.
  */
  setChangedFormControl(atividadeNo: number, ono: number, item: any): void {
    try {
      const items = this.changedFormControls[atividadeNo];
      if (items && items.length > 0) {
        const finded = items.find((f) => f.OcorrenciaNo === ono);
        if (finded) {
          Object.assign(finded, item);
        }
      } else {
        if (!this.changedFormControls[atividadeNo]) {
          this.changedFormControls[atividadeNo] = [];
        }
        this.changedFormControls[atividadeNo].push(item);
      }
    } catch (error) {
      console.log(this.constructor.name, 'setChangedFormControl', error.message);
    }
  }

  /*Adiciona um item no array de itens modificados.
   * TODO: Deveria receber pno, uma vez que atividadeNo pode se repetir em
   * processo diferente? Também incluir o usuário para evitar vazamento de
   * cache se há logins de clientes diferentes no mesmo cpu. E a ocorrência?
   */
  getChangedFormControl(atividadeNo: number): any[] {
    try {
      const obj = this.changedFormControls[atividadeNo];
      if (obj) {
        return obj;
      }
    } catch (error) {
      console.log(this.constructor.name, 'getChangedFormControl', error.message);
    }
    return [];
  }

  /* Remove o item da lista de controles. */
  removeFromChangedFormControl(atividadeNo: number, item: any): void {
    try {
      const obj = this.changedFormControls[atividadeNo];
      if (obj) {
        const findIndex = obj.findIndex(
          (f) => item.OcorrenciaNo && f.OcorrenciaNo && item.OcorrenciaNo === f.OcorrenciaNo
        );
        if (findIndex >= 0) {
          obj.splice(findIndex, 1);
        }
      }
    } catch (error) {
      console.log(this.constructor.name, 'removeFromChangedFormControl', error.message);
    }
  }

  /* Limpa o array de itens modificados. */
  clearChangedFormControl(atividadeNo: number): void {
    try {
      this.changedFormControls[atividadeNo] = [];
    } catch (error) {
      console.log(this.constructor.name, 'clearChangedFormControl', error.message);
    }
  }

  /* Para evitar repetições. */
  setListFormulaRendering(item: string): void {
    try {
      if (this.listFormulaRendering.indexOf(item) < 0) {
        this.listFormulaRendering.push(item);
      }
    } catch (error) {
      console.log(this.constructor.name, 'setListFormulaRendering', error.message);
    }
  }

  /*CONCEITO: Os valores são transportados da Atividade pai para a filha por
  * meio de defaultFormControl e retornam da filha para a pai por setGridItem.
   * Método utilizado para passar os valores da edição de um grid (atividade filha) para a atividade que contém o grid (atividade pai).
   * Os itens são adicionados em ctr-grid e os valores da atividade editada são atualizados em atividade-view subscribeToChangesIfIsGrid.
  * A atulização dos itens editados/adicionados no grid são acrescentados em ctr-grid transformValorTextoInLstCadastroAdicional.
  * Em action-bar também há a adição dos items em onSaveAndNew e onDuplicate
  */
  // setGridItem(ano: number, ono: number, gridVariavelNo: number, item: any): void {
  // 	try {
  // 		if (!ano || ano <= 0) {
  // 			throw new Error('Atenção: AtividadeNo inválida!');
  // 		}
  // 		if (item) {
  // 			if (!this.gridItems[ano]) {
  // 				this.gridItems[ano] = new Array<any>();
  // 			}
  // 			item.gridVariavelNo = gridVariavelNo;
  // 			const finded = this.gridItems[ano].find((f) => f.index === item.index);
  // 			for (const clm in item) {
  // 				if (item.hasOwnProperty(clm) && clm !== 'index') {
  // 					if (finded) {
  // 						finded[clm] = this.getDateOrString(item[clm]);
  // 					} else {
  // 						item[clm] = this.getDateOrString(item[clm]);
  // 					}
  // 				}
  // 			}
  // 			if (!finded) {
  // 				this.gridItems[ano].push(item);
  // 			}
  // 			const key = this.generateKey(ano, ono, gridVariavelNo);
  // 			this.sp.activityStore.next({ key: key, value: item });
  // 		}
  // 	} catch (error) {
  // 		console.log(this.constructor.name, 'addGridItem', error.message);
  // 	}
  // }

  /* Gera a chave para localização correta do item armazenado na store para passagem de parâmetros. */
  generateKey(ano: number, ono: number, gridVariavelNo: number): string {
    try {
      return `${ano}__${ono}__${gridVariavelNo}`;
    } catch (error) {
      console.log(this.constructor.name, 'generateKey', error.message);
    }
    return null;
  }

  /*Retorna o objeto indicado para o transporte de atividade de edição de um item de grid (filha),
   * para a atividade pai, que contém o grid.
   * Como padrão, limpará a propriedade logo após o retorno.
   */
  // FIXME: Deveria receber listvno como parâmetro e apenas excluir o
  // correspondente ao grid para permitir mais de um grid na mesma atividade.
  // getGridItems(ano: number, cleanAfterRead: boolean = true): any {
  // 	try {
  // 		if (!this.gridItems[ano] || !this.gridItems[ano]) {
  // 			return null;
  // 		}
  // 		const obj = this.gridItems[ano];
  // 		if (cleanAfterRead) {
  // 			this.gridItems[ano] = null;
  // 		}
  // 		return obj;
  // 	} catch (error) {
  // 		console.log(this.constructor.name, 'getGridItem', error.message);
  // 	}
  // 	return null;
  // }

  /* Limpa a propriedade para uma atividade específica. */
  // cleanGridItems(ano: number, ono: number): void {
  // 	try {
  // 		const key = this.getKey(ano, ono);
  // 		if (this.gridItems[key]) {
  // 			this.gridItems[key] = null;
  // 		}
  // 	} catch (error) {
  // 		console.log(this.constructor.name, 'cleanGridItems', error.message);
  // 	}
  // }

  /* Gera uma chave para o armazenamento do item. */
  protected getKey(ano: number, ono: number): string {
    try {
      return `${ano}__${ono}`;
    } catch (error) {
      console.log(this.constructor.name, 'getKey', error.message);
    }
    return null;
  }

  /* Verifica se o valor é uma data, se for retorna Date senão string. */
  protected getDateOrString(value: string): Date | string {
    try {
      let res;
      const date = moment(value, 'DD/MM/YYYY', true);
      if (date.isValid()) {
        res = date.toDate();
      } else {
        res = value;
      }
      return res;
    } catch (error) {
      console.log(this.constructor.name, 'getDateOrString', error.message);
    }
    return null;
  }

  /********* FIM ERP ****************/

  // constructor(injector: Injector) {
  constructor(private cookieSrv: CustomCookieService) {
    try {
      // this.cookieSrv = injector.get(CustomCookieService);
    } catch (error) {
      console.log(this.constructor.name, 'constructor', error.message);
    }
  }

  /* Implementação teste de gravação de valores default  */
  protected writeCookie(): void {
    try {
      this.cookieSrv.putObject('defaultFormControls', {
        V_4651: -0,
        V_4647: '',
        V_4649: '',
        V_27908: 'SIM'
      });
    } catch (error) {
      console.log(this.constructor.name, 'writeCookie', error.message);
    }
  }

  /* Leitura dos parâmetros armazenados no cookie do usuário, após o login  */
  protected readCookies(): void {
    try {
      // ******* GE *********
      //   let token = this.cookieSrv.get("UsuarioToken");
      //   if (token) { this.baseUsuarioToken = token; }
      const usuarioNo = this.cookieSrv.get('UsuarioNo');
      if (usuarioNo) {
        this.usuarioLogadoNo = +usuarioNo;
      }
      const loginUnifiedNo = this.cookieSrv.get('LoginUnifiedNo');
      if (loginUnifiedNo) {
        this.usuarioLogadoNo = +loginUnifiedNo;
      }
      const userName = this.cookieSrv.get('UsuarioNomeCookie');
      if (userName) {
        this.usuarioLogadoNome = userName;
      }
      const defaultFormControls = this.cookieSrv.getObject('defaultFormControls');
      if (defaultFormControls) {
        this.defaultFormControls = defaultFormControls;
      }
      // if (token == undefined) {
      //     this.router.navigate([this.loginUrl]);
      // }
      // console.log("cookie", token, usuarioNo, loginUnifiedNo);
      // ********** FIM GE ************

      // ********** ERP ***************
      const token = this.cookieSrv.get('UsuarioToken');
      if (token) {
        this.baseUsuarioToken = token;
      }
      if (loginUnifiedNo) {
        this.loginUnifiedNo = +loginUnifiedNo;
      }
      // ********** FIM ERP ***************
    } catch (error) {
      console.log(this.constructor.name, 'readCookies', error.message);
    }
  }

  /*Essa propriedade precisa ser resgatada por um método, pois dará
     * a opção de limpar ou não o cookie. Atenção: "limpará" (na verdade um update)
     * o cookie apenas para o ano.
    */
  getDefaultFormControls(ano: number, cleanCookie: boolean = false): any {
    try {
      const objDefaultFormCtrl = this.defaultFormControls[ano] || {};
      if (cleanCookie) {
        // Zera a propriedade, para que só volte a ser utilizada caso seja redefinida novamente.
        this.defaultFormControls[ano] = {};
        this.cookieSrv.putObject('defaultFormControls', this.defaultFormControls);
      }
      return objDefaultFormCtrl;
    } catch (error) {
      console.log(this.constructor.name, 'getDefaultFormControls', error.message);
    }
  }

  /* É importante que a definição do controle se dê através desse método, para assegurar a criação da AtividadeNo (ano) */
  setDefaultFormControls(ano: number, newValue: any): void {
    try {
      if (!this.defaultFormControls) {
        this.defaultFormControls = {};
      }
      this.defaultFormControls[ano] = newValue;
    } catch (error) {
      console.log(this.constructor.name, 'setDefaultFormControls', error.message);
    }
  }

  /* Limpa o valor default. Será chamado externamento após o valor ter sido lido.
   * TODO: Atenção: esse formato, sem considerar a AtividadeNo pode trazer problemas para atividades dentro de atividades.
  */
  cleanDefault(ano: number): void {
    try {
      this.defaultFormControls[ano] = null;
      this.cookieSrv.putObject('defaultFormControls', this.defaultFormControls);
    } catch (error) {
      console.log(this.constructor.name, 'cleanDefault', error.message);
    }
  }

  /* Limpa todos os valores padrão, seja  defaultFormControls, gridItem ou listItem. */
  cleanAllDefault(): void {
    try {
      this.defaultFormControls = {};
      this.cookieSrv.delete('defaultFormControls');
      // this.gridItems = {};
      // this.listItem = {};
    } catch (error) {
      console.log(this.constructor.name, 'cleanAllDefault', error.message);
    }
  }

  /* Limpa cookies e sessions relacionados a cadastros e login.  */
  reset(): void {
    try {
      this.baseUsuarioToken = null;
      this.tenantId = null;
      this.modeloConfigNo = null;
      this.usuarioLogadoNo = null;
      this.usuarioLogadoNome = null;
      sessionStorage.clear();
      this.cookieSrv.deleteAll();
      this.cleanAllDefault();
      this.cleanPrevState();
    } catch (error) {
      console.log(this.constructor.name, 'reset', error.message);
    }
  }


}
