<div class="text-center header">

  <br />
  <img alt="image" class="img-circle" [class.miniImg]="showMiniImg" width="80" src="{{ patient.foto }}"
    (error)="swapImage($event.target, patient)">

  <h2 class="m-b-xs" [class.miniBox]="showMiniImg">
    <strong [class.miniFont]="showMiniImg">{{ patient.nomeHospede }}</strong>
  </h2>

  <div class="font-bold" *ngIf="!showMiniImg">{{ getAge(patient.nascimento) }} anos</div>

  <button mat-raised-button *ngIf="showChangePatient" type="button" (click)="selectPatient()">Selecionar...</button>

</div>