import { EnStockStatus } from '../../enum/medlogic/en-stock-status.enum';
import { IHistoricoCompraEstoque } from './ihistorico-compra-estoque';

export interface IEstoqueMateriais {

  // ocorrenciaNo: number;
  // tempoUso: string;
  // dosagem: any;
  // orientacoes: string;
  // posologia: string;
  // codigo: any;
  // titulo: any;
  // habilitado: boolean;
  // centroCusto: string;
  // estoque: any;
  // estoqueMinimo: any;
  // unidademedida: string;
  // dataUltimaCompra: any;
  // tipoMaterial: string;
  // consumoDiario: any;
  // dataProximaCompra: any;
  // hoje: Date;
  // duracaoTotal: any;
  // unidadeNegocio: string;
  // codigoPaciente: any;
  // codHospede: any;
  // medicamento: any;
  // medicamentoControlado: any;
  // medicamento2: any;
  // codigoPacienteNomePaciente: any;
  // codPacienteNomedoPacienteCodMedicamento: any;
  // quantUtilizada: any;
  // tipoMedicamentosCodPaciente: any;
  // itens: string;
  // material: any;
  // lsthistoricoCompraEstoque: any;
  // idPaciente: any;
  // uniNegocio: any;
  // cascataTipoMAterial: any;
  // quantidadePorEmbalagem: number;
  // idMedicamento: number;
  // // Adicionado para tela de pedido de compra
  // purchaseQuantity: number;
  // // Campos calculados:
  // status: EnStockStatus;
  // isSelected: boolean;
  // isHistoryVisible: boolean;
  // totalPrice: number;
  // lastPrice: number;
  // isMinimum: boolean;
  // lastPurchaseId: number;
  // ultLote: string;
  // ultDtValidade: Date;

  // Campos gerados de maneira diferente em 25 out 2019
  ocorrenciaNo: number;
  dosagem: any;
  codigo: any;
  titulo: any;
  habilitado: boolean;
  centroCusto: string;
  estoque: any;
  estoqueMinimo: any;
  unidademedida: string;
  dataUltimaCompra: any;
  tipoMaterial: string;
  consumoDiario: any;
  dataProximaCompra: any;
  hoje: Date;
  duracaoTotal: any;
  unidadeNegocio: string;
  idPaciente: any;
  medicamentoControlado: any;

  medicamento: any;
  medicamento2: any;
  codigoPacienteNomePaciente: any;
  cascataTipoMAterialCodHosp: any;
  materialID: any;

  uniNegocio: any;
  tipoMedicamentosCodPaciente: any;
  codPacienteNomedoPacienteCodMedicamento: any;
  itens: string;
  concatUniNegocioSIM: any;
  concatUniNegocioNAO: any;
  quantidadeEmbalagem: any;
  idMedicamento: any;
  codCenCusto: any;
  ultimoLote: any;
  dataUltimaValidade: any;
  dose: any;
  unidadeDose: string;
  consumoDiario2: any;
  gOTASPMl: any;
  dataAtual: Date;

  lsthistoricoCompraEstoque: IHistoricoCompraEstoque[];
  // ##
  // cascataTipoMAterialCodHosp: any;
  // concatUniNegocioSIM: any;
  // concatUniNegocioNAO: any;
  // quantidadeEmbalagem: any;
  // : any;
  // codCenCusto: any;
  // ultimoLote: any;
  // dataUltimaValidade: any;
  // dose: any;
  // unidadeDose: string;
  // consumoDiario: any;
  // consumoDiario2: any;
  // gOTASPMl: any;
  // dataAtual: Date;

  // Campos Calculados
  status?: EnStockStatus;
  isSelected?: boolean;
  isHistoryVisible?: boolean;
  totalPrice?: number;
  lastPurchaseId?: number;
  lastPrice?: number;
  isMinimum?: boolean;
  purchaseQuantity?: number;
  daysToExpire?: number;
}

export abstract class IEstoqueMateriaisClass {
  /* Retorna o código de forma padronizada. */
  static getTitulo(medicationName: string, dose: string, costCenter: string): string {
    try {
      return `${medicationName} - ${dose}_${costCenter}`;
    } catch (error) {
      console.log(this.constructor.name, 'getTitulo', error.message);
    }
    return '';
  }
}
