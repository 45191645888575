import { IFrailty } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { LogService, EnUserRole, GlobalService, IListItem, EnColor } from '@medlogic/shared/shared-interfaces';
import { IFrailtyState, IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { ItemSearchPipe } from '@medlogic/shared/utils';

export const selectFrailty = (state: IFrailtyState) => state?.frailty;
export const selectAllFrailtys = (state: IFrailtyState) => state?.frailtys;
export const selectFrailtyError = (state: IFrailtyState) => state?.error;
export const selectAudios = (state: IFrailtyState) => state?.audios;
export const selectNumOfAudios = (state: IFrailtyState) => state?.audios?.length || 0;
export const selectNumOfAudiosBlue = (state: IFrailtyState) => state?.audios?.filter(f => f.enColor === EnColor.blue)?.length || 0;
export const selectNumOfAudiosRed = (state: IFrailtyState) => state?.audios?.filter(f => f.enColor === EnColor.red)?.length || 0;
export const selectFirstAudio = (state: IFrailtyState, enColor: EnColor) => {
  const audioFromColor = state?.audios?.filter(f => f.enColor === enColor);
  return audioFromColor?.length > 0 ? audioFromColor[0] : null;
};


export const selectIsPlaying = (state: IFrailtyState) => state?.isPlaying || false;

const glb = new GlobalService();
const log = new LogService();


/* Operador frailtyalizado que converte os frailtyos em módulos. */
const toIListItem = ((frailty: IFrailty) => {
  return ({
    id: frailty?.codigo,
    identification1: frailty?.codigo,
    identification2: frailty?.titulo,
    topLeft: frailty?.codigo,
    bottomLeft: frailty?.codigo,
    topRight: frailty?.codigo,
    bottomRight: '',
    color: 'green',
    imgUrl: '',
    obj: frailty
  } as IListItem<IFrailty>);
})

function uniqueDates(itens: any[]) {
  const datas: Date[] = [];
  if (itens.length > 1) {
    itens.forEach(item => {
      if (!datas.find(f => glb.isEqualIgnoreTime(f, glb.YYYYMMddThhmmssToDate(item.obj.data)))) {
        datas.push(glb.YYYYMMddThhmmssToDate(item.obj.data));
      }
    });
    return datas;
  }
}

export const selectFrailtysAsIListItems = (state: any) => {
  return state?.frailty?.frailty?.map(toIListItem)[0];
};

const filterBySearch = (items: any[], keyword: string) => { // ISmartFrailty<IModule>
  return new ItemSearchPipe(log, glb).transform(items, keyword);
}

export const selectFrailtyDefaultFormControls = (state: IAppMedlogicState): any => {
  const isEducator = [EnUserRole.customer, EnUserRole.educator, EnUserRole.professional].includes(state?.tenant?.userRole);
  const isSpecialist = [EnUserRole.professional].includes(state?.tenant?.userRole);
  const isParent = [EnUserRole.responsible].includes(state?.tenant?.userRole);
  const userName = state?.login?.userName;
  const patient = state?.person?.person;
  return {
    V_104052: userName,
    V_102283: state?.person?.person?.codigo,
    V_104049: glb.dateToYYYYMMddThhmmss(new Date()),
    // V_102725: isEducator ? userName : patient?.nomeEducadorProfissionalResponsavel,
    // V_102727: isParent ? userName : patient?.nomeresponsavelLegal,
    // V_102728: patient?.grauparentesco,
    // V_102303: state?.school?.selectedSchool?.codigo || state?.login?.selectedLogin?.customerId,
    V_103860: isEducator ? state?.login?.selectedLogin?.usuarioLogadoNome : '',
    V_103865: isSpecialist ? state?.login?.selectedLogin?.usuarioLogadoNome : '',
  };


}
