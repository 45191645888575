import { queue } from '@medlogic/shared/state-queue';
import { LocalLibService, routeGetMedLogic, IQueue } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  mergeMap, toArray, catchError,
  filter, map, publishReplay, refCount,
} from 'rxjs/operators';
import {
  EnStockStatus, IEstoqueMateriais, IEstoqueMateriaisClass,
  IHistoricoCompraEstoque, IHistoricoCompraEstoqueClass,
  IInterventionMedication, IStockChange
} from '@medlogic/shared/shared-interfaces';
import { EstoqueMateriaisService } from './estoque-materiais.services';
import { forkJoin, Observable, of } from 'rxjs';
import { EMPTY } from 'rxjs';
import { IMedication } from '@medlogic/shared/shared-interfaces';
import { HistoricoCompraEstoqueCustomService } from './historico-compra-estoque.custom.service';
import {
  GlobalService, LogService, IServiceProvider,
  ConfigJsonService
} from '@medlogic/shared/shared-interfaces';
import { CadastroService, BasePageService } from '@medlogic/shared/shared-data-access';
import { IAppMedlogicState } from '../../../medlogic-shared-interfaces/src';
import { Store } from '@ngrx/store';

import * as moment from 'moment';

const error = () => catchError((err, obs) => {
  console.log(err);
  return of(err);
});

/* Esse é o design pattern recomendado para que seja preservada a classe original da exata forma que foi gerado,
* a fim de que seja possível regerar novamente sem afetar partes customizadas do código.
*/
@Injectable({
  providedIn: 'root'
})
export class EstoqueMateriaisCustomServices extends EstoqueMateriaisService implements IServiceProvider {
  SAFE_MARGIN_DAYS = 10; // Número de dias a descontar como margem de segurança para cálculo da data da próxima compra
  // tslint:disable-next-line: max-line-length
  urlUpdateStockQuantity = `${routeGetMedLogic}?stockItemId={0}&quantity={1}&lstStockVariables={2}&lstPurchaseHistoryVariables={3}&batch={4}&dueDate=&description={5}`;
  // Necessário acrescentar variáveis que são pré-requisitos para a baixa no estoque.
  protected lstVariaveisUpdateStock = `V_34687,V_28008,${this.lstVariaveis}`;

  constructor(
    protected http: HttpClient,
    cadastroSrv: CadastroService,
    private basepage: BasePageService,
    glb: GlobalService,
    private cnfJson: ConfigJsonService,
    log: LogService,
    protected lib: LocalLibService,
    protected historicoCompraSrv: HistoricoCompraEstoqueCustomService,
    private store: Store<IAppMedlogicState>
  ) {
    super(cadastroSrv, glb, lib, log);
  }

  /* Retorna todos os itens do estoque de materiais através do código do Paciente ou do Centro de Custo.
   * Ou seja, trará os estoques de ambos
   * V_29828 é o código do paciente.
   * V_27993 é o centro de custo.
   */
  getByCodigoPacienteOrCentroCusto(
    ano: number,
    codigoPaciente: string,
    costCenter: string,
    dtStart: Date = null,
    dtEnd: Date = null): Observable<IEstoqueMateriais> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const strFilter = `V_29828:${codigoPaciente},V_27993:${costCenter}`;
      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate, false);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCodigoPacienteOrCentroCusto', error.message);
    }
    return of(null);
  }

  getByCentroCusto(
    ano: number,
    costCenter: string,
    dtStart: Date = null,
    dtEnd: Date = null): Observable<IEstoqueMateriais> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const strFilter = `V_27993:${costCenter}`;
      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCentroCusto', error.message);
    }
    return of(null);
  }

  getByCentroCustoAndItem(
    ano: number,
    costCenter: string,
    medicationName: string,
    dtStart: Date = null,
    dtEnd: Date = null): Observable<IEstoqueMateriais> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const strFilter = `V_27993:${costCenter},V_30350:${medicationName}`;
      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate, true);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCentroCusto', error.message);
    }
    return of(null);
  }

  /* Tentará retornar um item de estoque através da comparação do medicationId. */
  getByMedicationId(ano: number, medicationId: number): Observable<IEstoqueMateriais> {
    try {
      this.cadastroNo = ano;
      const startDate = new Date(1900, 0, 1);
      const endDate = new Date(2500, 0, 1);
      const strFilter = `V_100306:${medicationId}`; // V_34809 // 30268
      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByMedicationId', error.message);
    }
    return of(null);
  }

  /* Atualiza um item específico do cache. */
  updateCache(estoqueMaterial: IEstoqueMateriais, action: string): void {
    try {
      if (action === 'DELETE') {
        this.cadastrosCache = this.cadastrosCache.pipe(
          filter((item) => item.OcorrenciaNo !== estoqueMaterial.ocorrenciaNo)
        );
      } else {
        this.cadastrosCache = this.cadastrosCache.pipe(
          map((item) => {
            if (item.OcorrenciaNo === estoqueMaterial.ocorrenciaNo) {
              Object.assign(item, estoqueMaterial);
            }
            return item;
          })
        );
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  /* Checa se a descrição existe, pelo nome apenas, e se não existir, cria. Senão, faz nada. */
  // insertIfNotExist(
  //   ano: number,
  //   evolution: IEstoqueMateriais,
  //   compareFieldName: string = 'titulo'
  // ): Observable<boolean> {
  insertIfNotExist<T>(ano: number, item: T, uno: number, compareFieldName: string = 'titulo'): Observable<boolean> {
    try {
      return new Observable((observer) => {
        this.subs.sink = this.getFromCadastro(ano, null, null).pipe(toArray()).subscribe((items) => {
          const founded =
            items.findIndex((f) => this.glb.isEqual(f[compareFieldName], item[compareFieldName])) >= 0;
          observer.next(founded);
          if (!founded) {
            this.subs.sink = this.save<IEstoqueMateriais>(ano, item as unknown as IEstoqueMateriais, uno).subscribe((s) => observer.complete());
          } else {
            observer.complete();
          }
        });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insertIfNotExist', error.message);
    }
  }

  /* Além de salvar o item, salvará também no cadastro relacionado ao grid, neste caso, o lsthistoricoCompraEstoque.
  * Acrescentará um novo item no cadastro de relacionamento para cada item do lsthistoricoCompraEstoque, que não tiver um id definido
  * (representa os novos itens).
  * mainId é o id do Cadastro principal.
  * relationShipId é o id do Cadastro de relacionamento, neste caso, "Histórico Compra Estoque".
  * Essa rotina sempre acrescentará um novo item no cadastro de relacionamento. Não está preparada para editar um item já existente.
  * No entanto, está preparada para editar o ESTOQUE DE MATERIAIS, bem como o xml de Histórico de Compra.
  * Apenas não editará o cadastro de Histórico de Compra, que é separado do XML.
  */
  saveWithRelationship(
    mainId: number,
    relationShipId: number,
    estoqueMateriais: IEstoqueMateriais,
    uno: number,
    id?: number
  ): Observable<any> {
    try {
      const newItens = estoqueMateriais.lsthistoricoCompraEstoque
        ? estoqueMateriais.lsthistoricoCompraEstoque.filter(
          (f) => !f || f.codigo === null || f.codigo === undefined
        )
        : null;
      // Observe que o save é chamado duas vezes: se for um novo item, na primeira id será null e na segunda, newEstoqueId
      const obsMainSave = this.save(mainId, estoqueMateriais, +id);
      if (newItens && newItens.length > 0) {
        // Existem novos itens no histórico de compras.
        return obsMainSave.pipe(
          mergeMap((newEstoqueId: any) => {
            try {
              if (newEstoqueId && newEstoqueId > 0) {
                const obsHistorico = newItens
                  .map((m) => {
                    // Gerará uma coleção de observables para disparar várias chamadas que irão
                    // salvar no cadastro de histórico, cada um dos novos itens de lsthistoricoCompraEstoque.
                    return this.historicoCompraSrv.save(relationShipId, m, uno)
                      .pipe(
                        mergeMap((newHistoricoId: any) => {
                          try {
                            // Para que a edição de um item do histórico funcione, após serem
                            // criados os ids, necessário atualizar o xml do item de estoque.
                            const itemDoHistoricoCompras =
                              estoqueMateriais.lsthistoricoCompraEstoque[
                              estoqueMateriais.lsthistoricoCompraEstoque.length - 1
                              ];
                            itemDoHistoricoCompras.codigo = newHistoricoId;
                            itemDoHistoricoCompras.index = newHistoricoId;
                            itemDoHistoricoCompras.codigoItemEstoque = newEstoqueId;
                            itemDoHistoricoCompras.data = new Date();
                            itemDoHistoricoCompras.dosagem = estoqueMateriais.dosagem;
                            estoqueMateriais.codigo = newEstoqueId;
                            return this.save(mainId, estoqueMateriais, +newEstoqueId);
                          } catch (error) {
                            this.log.Registrar(this.constructor.name, 'saveWIthRelationship', error.message);
                          }
                          return of(null);
                        })
                      );
                  });
                return forkJoin(
                  // necessário forkJoin pois obsHistórico retornará vários observables.
                  obsHistorico
                ).pipe(mergeMap(() => of(newEstoqueId))); // Para que o id de retorno seja do item de estoque e não do histórico
              } else {
                return EMPTY;
              }
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'saveWithRelationship.map', error.message);
            }
            return EMPTY;
          })
        );
      } else {
        // Não existem novos itens no histórico de compras, apenas salvará o cadastro principal.
        return obsMainSave;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'saveWithRelationship', error.message);
    }
    return EMPTY;
  }

  /* override
  * Atenção: Não carrega do grid, portanto, o histórico não será preenchido no carregamento.
  */
  protected getWithCache(
    cadastroNo: number,
    startDate: Date,
    endDate: Date,
    lstVariables: string = null,
    includeGridItems: boolean = true): Observable<IEstoqueMateriais> {
    if (
      startDate.getTime() !== this.currentDtInicial.getTime() ||
      endDate.getTime() !== this.currentDtFinal.getTime() ||
      !this.cadastrosCache
    ) {
      this.currentDtInicial = startDate;
      this.currentDtFinal = endDate;
      if (includeGridItems) {
        this.cadastrosCache = this.getFromCadastroGrid(cadastroNo, startDate, endDate, null);
      } else {
        this.cadastrosCache = this.getFromCadastro(cadastroNo, startDate, endDate, lstVariables);
      }
    } else {
      console.log('retorno do cache');
    }
    return this.cadastrosCache;
  }

  /* override
  * Insere campos calculados com regras de negócio específicas.
  * Foram desativados os cálculos com base no histórico, pois, o histórico será carregado sob demanda,
  * para otimizar desempenho de carregamento.
  */
  // toAtribute(c: any, lstHistoricoCompraEstoque: Array<IHistoricoCompraEstoque>): IEstoqueMateriais {
  //   try {
  //     const estoqueMateriais = super.toAtribute(c, lstHistoricoCompraEstoque);
  //     return this.defineCalculatedFields(estoqueMateriais);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'toAttribute', error.message);
  //   }
  //   return null;
  // }

  /* override para incluir os campos calculados. */
  protected mapTo = () => map((c: any) => {
    const estoque = super.toAttribute(c);
    return this.defineCalculatedFields(estoque);
  })

  /* Irá adicionar todos os campos calculados ao estoque. */
  private defineCalculatedFields(estoqueMateriais: IEstoqueMateriais): IEstoqueMateriais {
    try {
      let stockValue = 0;
      if (estoqueMateriais.lsthistoricoCompraEstoque && estoqueMateriais.lsthistoricoCompraEstoque.length > 0) {
        stockValue = this.calculateStockValue(
          estoqueMateriais.lsthistoricoCompraEstoque,
          estoqueMateriais.estoque
        );
      } else {
        stockValue = this.lib.convertNumber(estoqueMateriais.estoque);
      }
      const stockMin: number = this.lib.convertNumber(estoqueMateriais.estoqueMinimo);
      const isMinimum: boolean = this.calculateIsMinimum(stockValue, stockMin);
      // Calcular a partir do histórico diretamente é mais confiável do que resgatar da
      // variável, pois, essa é calculada na tela e há outras formas de entrada (como o invoice).
      const lastHistory = this.calculateLastHistoryItem(estoqueMateriais.lsthistoricoCompraEstoque);
      const lastPrice: number = this.calculateLastPrice(estoqueMateriais.lsthistoricoCompraEstoque);
      // let lastPrice: number = this.lib.convertNumber(c.V_27996);
      const lastPurchaseQuantity: number = this.calculateLastPurchaseQuantity(estoqueMateriais.lsthistoricoCompraEstoque);
      // let lastPrice: number = this.lib.convertNumber(c.V_27996);
      const dateLast: Date = this.calculateDateLast(estoqueMateriais.lsthistoricoCompraEstoque);
      // this.global.getTypedValue(c.V_27998).value
      const dailyDuration = +estoqueMateriais.consumoDiario > 0 ? +estoqueMateriais.consumoDiario : 1;
      const totalDuration = (+stockValue / +dailyDuration) - stockMin;
      const dateNextBuy = moment().add(totalDuration, 'days').toDate();
      estoqueMateriais.consumoDiario = +dailyDuration;
      estoqueMateriais.duracaoTotal = totalDuration;
      estoqueMateriais.estoqueMinimo = stockMin;
      estoqueMateriais.estoque = stockValue;
      estoqueMateriais.status = this.calculateStatus(isMinimum, dateNextBuy, lastHistory);
      estoqueMateriais.isSelected = false;
      estoqueMateriais.isHistoryVisible = false;
      estoqueMateriais.totalPrice = this.calculateTotalPrice(lastPrice, stockValue, estoqueMateriais.quantidadeEmbalagem);
      estoqueMateriais.lastPurchaseId = this.calculateLastPurchaseId(lastHistory);
      estoqueMateriais.lastPrice = lastPrice;
      estoqueMateriais.dataUltimaCompra = dateLast;
      estoqueMateriais.dataProximaCompra = dateNextBuy;
      estoqueMateriais.isMinimum = isMinimum;
      estoqueMateriais.purchaseQuantity = lastPurchaseQuantity;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'defineCalculatedFields', error.message);
    }
    return estoqueMateriais;
  }

  private calculateLastPurchaseId(lastHistory: IHistoricoCompraEstoque) {
    try {
      return this.glb.isNullOrEmpty(lastHistory) ? null : lastHistory.codigoPedido;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateLastPurchaseId', error.message);
    }
    return null;
  }

  /* Calcula o valor equivalente ao item em estoque baseado no último preço pago, na
  * quantidade de itens do pacote e na quantidade de unidades em estoque. */
  private calculateTotalPrice(lastPrice: number, stockValue: number, packageQuantity: number) {
    try {
      const typedPackage = this.glb.getTypedValue(packageQuantity).value;
      const typedLastPrice = this.glb.getTypedValue(lastPrice).value;
      const typedStockValue = this.glb.getTypedValue(stockValue).value;
      return typedLastPrice * (typedStockValue / typedPackage);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateTotalPrice', error.message);
    }
    return null;
  }

  private calculateIsMinimum(stockValue: number, stockMin: number): boolean {
    try {
      return stockValue <= stockMin;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateIsMinimum', error.message);
    }
    return null;
  }

  private calculateStatus(
    isMinimum: boolean,
    dateNextBuy: Date,
    lastHistory: IHistoricoCompraEstoque
  ): EnStockStatus {
    try {
      return EnStockStatus[this.getStockStatus(isMinimum, dateNextBuy, lastHistory)];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateStatus', error.message);
    }
    return null;
  }

  /*lastHistory deve ser o último item do histórico de compras do item.
  * Necessário, pois, se o último - ou seja, o mais recente - item tiver um número de pedido,
  * Significa que existe um pedido já realizado para o item.
  * // TODO: Será necessário atualizar esse item para verificar se já não foi dado entrada do pedido,
  * pois nesse caso o status deve considerar as condições originais.
  */
  getStockStatus(isMinimum: boolean, dtDue: Date, lastHistory: IHistoricoCompraEstoque): EnStockStatus {
    try {
      const dtToday: Date = new Date();
      const dtWarning: Date = !this.glb.isNullOrEmpty(dtDue) ? this.lib.addDays(dtDue, -7) : dtToday;
      if (!dtToday || !dtWarning) {
        return EnStockStatus.None;
      }

      if (!this.glb.isNullOrEmpty(lastHistory) && !lastHistory.pedidoConferido && lastHistory.codigoPedido) {
        // Significa que há um pedido de compra ainda não conferido
        return EnStockStatus.Pedido;
      } else if (!isMinimum) {
        // Significa que não há um pedido de compra, ou que há, mas já foi conferido
        if (dtToday.getTime() >= dtWarning.getTime()) {
          return EnStockStatus.Comprar;
        } else {
          return EnStockStatus.EmEstoque;
        }
      } else {
        return EnStockStatus.Critico;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getStockStatus', error.message);
    }
    return EnStockStatus.None;
  }

  /* DailyUseQuantity: quantidade de unidades utilizadas por dia. */
  private calculateDateNextBuyBasedOnFixedDays(
    currentStockValue: number,
    dailyUseQuantity: number,
    safeMargin: number,
    currentDateNext: any
  ): Date {
    try {
      if (currentStockValue && dailyUseQuantity && safeMargin) {
        const today = new Date();
        const daysToAdd = Math.round((currentStockValue - safeMargin) / dailyUseQuantity);
        return this.glb.DateAdd('d', daysToAdd, today);
      } else {
        return this.glb.getTypedValue(currentDateNext).value;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateDateNextBuy', error.message);
    }
    return null;
  }

  private calculateDateLast(lstHistoricoCompraEstoque: IHistoricoCompraEstoque[]): Date {
    try {
      const lastHistory = this.calculateLastHistoryItem(lstHistoricoCompraEstoque);
      return lastHistory ? lastHistory.data : null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateDateLast', error.message);
    }
    return null;
  }

  /* Retorna o número total de unidades compradas na compra mais recente. */
  private calculateLastPurchaseQuantity(lstHistoricoCompraEstoque: IHistoricoCompraEstoque[]): number {
    try {
      const lastHistory = this.calculateLastHistoryItem(lstHistoricoCompraEstoque);
      return lastHistory ? this.lib.convertNumber(lastHistory.quantidade) : 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateLastPurchaseQuantity', error.message);
    }
    return null;
  }

  private calculateLastPrice(lstHistoricoCompraEstoque: IHistoricoCompraEstoque[]): number {
    try {
      const lastHistory = this.calculateLastHistoryItem(lstHistoricoCompraEstoque);
      return lastHistory ? this.lib.convertNumber(lastHistory.preco) : 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateLastPrice', error.message);
    }
    return null;
  }

  private calculateLastHistoryItem(lstHistoricoCompraEstoque: IHistoricoCompraEstoque[]) {
    try {
      return lstHistoricoCompraEstoque && lstHistoricoCompraEstoque.length > 0
        ? lstHistoricoCompraEstoque[lstHistoricoCompraEstoque.length - 1]
        : null;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateLastHistoryItem', error.message);
    }
    return null;
  }

  /* Calcula o valor do estoque, baseado no histórico de compras.
   * Atenção: se o código do Pedido for preenchido, o valor será desconsiderado, pois, é um pedido e não uma entrada efetiva.
   */
  private calculateStockValue(lstHistoricoCompraEstoque: IHistoricoCompraEstoque[], currentStockValue: any): number {
    try {
      return !lstHistoricoCompraEstoque || lstHistoricoCompraEstoque.length <= 0
        ? this.lib.convertNumber(currentStockValue)
        : lstHistoricoCompraEstoque
          .filter((f) => f && this.glb.isNullOrEmpty(f.codigoPedido))
          .reduce((a, b) => a + this.lib.convertNumber(b.quantidade), 0);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateStockValue', error.message);
    }
    return null;
  }

  /* Para cada item, de cada centro de custo, dará entrada das quantidades no estoque.
  * Deverá, portanto, também atualizar o histórico de compras do cadastro de estoques,
  * bem como acrescentar os respectivos itens no Cadastro de Histórico de Compras.
  * A quantidade no estoque será calculada através do histórico de compras, após ter sido acrescentada nova linha no histórico.
  */
  insereItemEstoque(
    cadItemEstoqueNo: number,
    cadStockHistoryNo: number,
    dtInvoice: Date,
    fornecedor: string,
    quantidade: number,
    stockChange: IStockChange,
    minStockDefault: number = 1,
    stock: IEstoqueMateriais = null
  ): Observable<any> {
    try {
      stock = stock || this.mapToIEstoqueMateriais(dtInvoice, quantidade, minStockDefault, stockChange);
      return this.historicoCompraSrv.getById(cadStockHistoryNo, stock.codigo)
        .pipe(
          toArray(),
          mergeMap(lsthistoricoCompraEstoque => {
            stock.lsthistoricoCompraEstoque = lsthistoricoCompraEstoque || new Array<IHistoricoCompraEstoque>();
            stock.lsthistoricoCompraEstoque.push({
              // index: null,
              // codigo: null,
              codigoItemEstoque: stockChange.medicationId,
              dosagem: stockChange.dosage,
              material: stockChange.medicationName,
              centroCusto: stockChange.costCenter,
              unidademedida: stockChange.unity,
              quantidade,
              habilitado: true,
              data: dtInvoice,
              preco: stockChange.price || stock.lastPrice || 0,
              fornecedor,
              unidadeNegocio: stockChange.businessUnit,
              medicamento: stockChange.medicationName,
              idMedicamento: stockChange.medicationId,
              quantidadePorEmbalagem: stockChange.packageQuantity,
              quantidadeDeEmbalagens: Math.round(quantidade / stockChange.packageQuantity),
              codPacienteNomedoPacienteCodMedicamento: stockChange.codPacienteNomedoPacienteCodMedicamento,
              titulo: IHistoricoCompraEstoqueClass.getTitulo(stockChange.medicationId, stockChange.medicationName),
              lote: stock.ultimoLote,
              dtValidade: stock.dataUltimaValidade
            } as IHistoricoCompraEstoque);
            // Recalcula todos os itens com base no históricoCompras e demais regras de negócio.
            this.defineCalculatedFields(stock);
            return this.saveWithRelationship(cadItemEstoqueNo, cadStockHistoryNo, stock, stock.codigo);
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'insereItemEstoque', error.message);
    }
    return of(null);
  }

  /* Extrai o código do paciente. */
  getCodPaciente(codPacienteNomedoPacienteCodMedicamento: string): number {
    try {
      if (codPacienteNomedoPacienteCodMedicamento) {
        const index = codPacienteNomedoPacienteCodMedicamento.indexOf('_');
        return parseFloat(codPacienteNomedoPacienteCodMedicamento.substring(0, index));
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCodPaciente', error.message);
    }
    return null;
  }

  /* Extrai o código do medicamento. */
  getCodMedicamento(codPacienteNomedoPacienteCodMedicamento: string): number {
    try {
      if (codPacienteNomedoPacienteCodMedicamento) {
        const index = codPacienteNomedoPacienteCodMedicamento.indexOf('_');
        const index2 = codPacienteNomedoPacienteCodMedicamento.indexOf('_', index + 1);
        const size = codPacienteNomedoPacienteCodMedicamento.length;
        return parseFloat(codPacienteNomedoPacienteCodMedicamento.substring(index2 + 1, size + 1));
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getCodMedicamento', error.message);
    }
    return null;
  }

  /* Mapeia para um item de Estoque.
  */
  protected mapToIEstoqueMateriais(
    dtInvoice: Date,
    quantity: number,
    minStock: number,
    stockChange: IStockChange
  ): IEstoqueMateriais {
    try {
      return {
        ocorrenciaNo: null, // v
        dosagem: stockChange.dosage, // v V_832
        codigo: stockChange.medicationId, // v V_27987 // Fará com que um novo item seja criado
        habilitado: true, // v V_27989
        centroCusto: stockChange.costCenter, // v V_27993
        estoque: quantity, // v V_27994
        estoqueMinimo: +minStock, // v V_27995
        unidademedida: stockChange.unity, // v V_27997
        dataUltimaCompra: dtInvoice, // v V_27998
        tipoMaterial: stockChange.material, // v V_28018
        consumoDiario: null, // v V_101098
        dataProximaCompra: null, // v V_28020 // FIXME: Deveria ser calculada, conforme a prescrição
        hoje: new Date(), // v V_28021
        duracaoTotal: null, // v V_28022
        unidadeNegocio: stockChange.businessUnit, // v V_28023
        idPaciente: stockChange.codPacienteNomedoPacienteCodMedicamento, // v V_29828
        medicamento: stockChange.medicationName, // v V_30267
        medicamentoControlado: null, // v V_30268
        medicamento2: stockChange.medicationName, // v V_30269
        codigoPacienteNomePaciente: null, // v V_30296
        uniNegocio: stockChange.businessUnit, // v V_30313
        codPacienteNomedoPacienteCodMedicamento: null, // v V_30321
        tipoMedicamentosCodPaciente: null, // v V_30339
        itens: stockChange.medicationName, // v V_30350
        // cascataTipoMAterialCodHosp // V_32855
        // concatUniNegocioSIM // V_34382
        // concatUniNegocioNAO // V_34383
        quantidadeEmbalagem: stockChange.packageQuantity, // v V_34718
        // materialID V_100236
        idMedicamento: stockChange.medicationId, // v V_100306
        // codCenCusto V_100323
        ultimoLote: stockChange.sourceBatch, // v V_100851
        dataUltimaValidade: stockChange.sourceExpirationDate, // v V_100852
        // dose V_100853
        // unidadeDose V_100854
        // consumoDiario2 V_28019
        // gOTASPMl V_101099
        // dataAtual V_101213
        lsthistoricoCompraEstoque: null, // v V_28008
        titulo: IEstoqueMateriaisClass.getTitulo(
          stockChange.medicationName,
          stockChange.dosage,
          stockChange.costCenter
        ), // v2 V_27988
        // Calculados
        status: EnStockStatus.None, // v
        isSelected: false, // v
        isHistoryVisible: false, // v
        totalPrice: 0.0, // v
        lastPurchaseId: null, // v
        lastPrice: 0.0, // v
        isMinimum: false, // v
        purchaseQuantity: null, // v
        daysToExpire: this.calculateDaysToExpire(stockChange.sourceExpirationDate),
        // campos null forçam carregamento de valor default, quando configurado no Studio.
        // cascataTipoMAterial: null, // X // É composto por TipoMaterial+CodHospede
        // materialType: stockChange.material, // X
        // tempoUso: null, // X
        // orientacoes: null, // X
        // posologia: null, // X
        // codigoPaciente: null, // X
        // codHospede: null, // X
        // quantUtilizada: null, // X
        // material: stockChange.material, // X
      } as IEstoqueMateriais;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToIStock', error.message);
    }
  }

  /* Calcula o número de dias que faltam para expirar.
   * Se não houver data de vencimento, retorna null.
   */
  protected calculateDaysToExpire(dueDate: Date): number {
    try {
      if (!dueDate) {
        return null;
      }
      const td = new Date();
      return this.glb.dateDiffDays(td, dueDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'calculateDaysToExpire', error.message);
    }
    return null;
  }

  /* Mapeia uma intervenção para o objeto dessa classe. */
  mapLancamentoToI(
    intervencao: IInterventionMedication,
    codigo: string = null,
    habilitado: boolean = true
  ): IEstoqueMateriais {
    try {
      return {
        ocorrenciaNo: null, // v
        dosagem: intervencao.dosagem, // v V_832
        dosagemValor: intervencao.dosagemValor || '', // V106765
        dosagemUnidade: intervencao.dosagemUnidade || '', // V106766
        codigo, // v V_27987
        habilitado, // v V_27989
        centroCusto: intervencao.centrocusto, // v V_27993
        estoque: '', // v V_27994
        estoqueMinimo: '', // v V_27995
        unidademedida: intervencao.apresentacao, // v V_27997
        dataUltimaCompra: '', // v V_27998
        tipoMaterial: intervencao.tIPOMaterial, // v V_28018
        consumoDiario: '', // v  V_101098
        dataProximaCompra: '', // v V_28020
        hoje: new Date(), // v V_28021
        duracaoTotal: '', // v V_28022
        unidadeNegocio: intervencao.uNIDADEDENEGOCIO, // v V_28023
        idPaciente: intervencao.Id_Paciente, // v V_29828
        medicamento: intervencao.medicamento,  // v V_30267
        medicamentoControlado: intervencao.medicamentoControlado, // v V_30268
        medicamento2: '', // v V_30269
        codigoPacienteNomePaciente: intervencao.codigoPacienteNomePaciente, // v V_30296
        uniNegocio: intervencao.uNIDADEDENEGOCIO, // v V_30313
        codPacienteNomedoPacienteCodMedicamento: intervencao.codPacienteNomedoPacienteCodMedicamento, // V V_30321
        tipoMedicamentosCodPaciente: intervencao.tipoMedicamentosCodPaciente, // v V_30339
        itens: intervencao.medicamento, // v V_30350
        cascataTipoMAterialCodHosp: `${intervencao.tIPOMaterial}${intervencao.Id_Paciente}`,
        // V_32855  /=#28018##30027#/ 30027 é o mesmo que idPaciente?
        concatUniNegocioSIM: `${+intervencao.Id_Medicamento}__${null}`, // V_34382 //  /=#100306#__#27993#/ o que é 27993?
        concatUniNegocioNAO: `${null}_${null}`, // V_34383 // 	 /V=#30350#_#27993#/ o que é 30350?
        quantidadeEmbalagem: null,  // v V_34718
        materialID: null, // V_100236 o que é 100236?
        idMedicamento: parseInt(intervencao.Id_Medicamento, 10),  // v V_100306
        codCenCusto: null, // V_100323 o que é 100323?
        ultimoLote: '',  // v V_100851
        dataUltimaValidade: null,  // v V_100852
        dose: null, // V_100853 //TODO:
        unidadeDose: null, // V_100854 //TODO:
        consumoDiario2: null, // V_28019 //TODO:
        gOTASPMl: null, // V_101099 //TODO:
        dataAtual: null, // V_101213 //TODO:
        lsthistoricoCompraEstoque: [],  // v V_28008
        titulo: IEstoqueMateriaisClass.getTitulo(
          intervencao.medicamento,
          intervencao.dosagem,
          intervencao.centrocusto
        ), // X V_27988
        // Campos calculados:
        status,  // v
        isSelected: true,  // v
        isHistoryVisible: true,  // v
        totalPrice: null,  // v
        lastPurchaseId: null,  // v
        lastPrice: null,  // v
        isMinimum: true,  // v
        // Adicionado para tela de pedido de compra
        purchaseQuantity: null,  // v
        // // cascataTipoMAterial: '', // X
        // // //materialType // X
        // // tempoUso: intervencao.tempoUso, // X
        // // orientacoes: intervencao.orientacoes, // X
        // // posologia: intervencao.posologia, // X
        // // codigoPaciente: intervencao.Id_Paciente, // X
        // // codHospede: intervencao.Id_Paciente, // X
        // quantUtilizada: intervencao.quantUtilizadaD,
        // material: intervencao.medicamento,
      } as IEstoqueMateriais;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapLancamentoTo', error.message);
    }
    return null;
  }

  /* Faz o mapeamento para o IMedication que é o cadastro da prescrição médica do item.
  * Não é possível fazer um mapeamento perfeito, pois, horário, posologia, etc não são definidos no item de estoque,
  * mas apenas na prescrição.
  */
  mapToIMedication(stockSource: IEstoqueMateriais, stock: IEstoqueMateriais, quantity: number): IMedication {
    try {
      const medication = {
        medicationId: stockSource.idMedicamento || stockSource.ocorrenciaNo,
        codPacienteNomedoPacienteCodMedicamento: stockSource.codPacienteNomedoPacienteCodMedicamento,
        medicationName: stockSource.itens || stockSource.medicamento || stockSource.medicamento2,
        costCenter: stockSource.centroCusto,
        unity: stockSource.unidademedida,
        businessUnit: stockSource.unidadeNegocio,
        material: stockSource.materialID || stockSource.tipoMaterial, // TODO: material e materialID são a mesma coisa?
        dosage: stockSource.dosagem,
        // date: stock.cad
        // prescription: stockSource.orientacoes, // Campo de texto comentando a prescrição, por exemplo, "Em caso de dor", "Após almoço"
        // prescribedTime: stock.; // Hora prescrita para tomar
        // medicationDate?: Date; // Data que o medicamento foi ministrado
        // access: stock.
        // presentation: stock.apr
        // instruction: stockSource.orientacoes,
        stock,
        stockSourceQuantity: quantity,
        stockSourceLastBatch: stockSource.ultimoLote,
        stockSourceLastDueDate: stockSource.dataUltimaValidade,
        stockSource,
        dailyQuantity: stockSource.consumoDiario || 1
      } as IMedication;
      return medication;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToImedication', error.message);
    }
    return null;
  }

  /* Utiliza o método disponível na API para realizar a baixa no estoque. */
  updateStockQuantity(
    stockItemId: number,
    quantity: number,
    // cod_produto: number,
    batch: string, // número do lote
    //  dueDate: Date,
    description: string
  ): Observable<boolean> {
    try {
      // let stockItem: any;
      // await this.getNumberOcorrency(cod_produto).then(response => response.json()).then(json => stockItem = json);
      // const stockItemId = stockItem.map(m => m.OcorrenciaNo);
      const url = this.basepage.format(this.urlUpdateStockQuantity,
        stockItemId.toString(),
        quantity,
        this.lstVariaveisUpdateStock,
        this.historicoCompraSrv.lstVariaveis,
        batch,
        description
      );
      this.store.dispatch(queue({
        queue: {
          key: url,
          url,
          message: `${stockItemId} - ${description} (${quantity})`
        } as IQueue
      }));
      // return this.getWebApiService(url)
      //   .pipe(
      //     defaultIfEmpty(false),
      //     error()
      //   );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'updateStockQuantity', error.message);
    }
    return of(false);
  }

  protected getWebApiService(url: string): Observable<any> {
    try {
      url = `${this.cnfJson.baseUrlAPI}${url}`;
      return this.basepage
        .get(url)
        .pipe(
          error()
        );
      // const fetch = require('node-fetch');
      // const retorno = await fetch(
      //   url,
      //   { method: 'GET' }
      // ).catch(error => console.log(error));
      // return retorno;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getWebApiService', error.message);
    }
    return of(null);
  }

  getFromCadastroFiltro(
    cadastroNo: number,
    filtro: string,
    startDate: Date,
    endDate: Date
  ): Observable<any> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      // console.log('Recarregando dados...');
      // publishReplay é para permanecer o resultado em cache e refCount para que o cache não seja esvaziado enquando houver subscribers
      return this.cadastroSrv.getCadastroComFiltro(cadastroNo, this.lstVariaveis, filtro, true)
        .pipe(
          this.mapTo(),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFromCadatro', error.message);
    }
    return of(null);
  }


}
