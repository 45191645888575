import { VitalSignCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { IVitalSign } from '@medlogic/shared/shared-interfaces';
import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { filter } from 'rxjs/operators';
import {
  UnsubscribeOnDestroyAdapter, IColumn, GlobalService,
  LogService, ITarefaComponente
} from '@medlogic/shared/shared-interfaces';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';

@Component({
  selector: 'app-longitudinal-history',
  templateUrl: './longitudinal-history.component.html',
  styleUrls: ['./longitudinal-history.component.css']
})
export class LongitudinalHistoryComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  ano: number;
  date: Date;
  patientName: string;
  patientCod: number;
  isLoaded = false;
  public get strDate(): string {
    return this.glb.dateToddMMYYYY(this.date);
  }

  vitalSigns: IVitalSign[] = new Array<IVitalSign>();
  rows: any[] = new Array<any>();
  columns: IColumn[];
  periods: any[] = [
    {
      title: 'Manhã',
      startH: 0,
      startM: 0,
      endH: 12,
      endM: 0
    },
    {
      title: 'Tarde',
      startH: 12,
      startM: 1,
      endH: 18,
      endM: 0
    },
    {
      title: 'Noite',
      startH: 18,
      startM: 1,
      endH: 23,
      endM: 59
    },
  ];
  // tabs: Array<string> = new Array<string>();
  // includeNormal: boolean = false;
  // tooltipIncludeNormal: string = "Atenção: trata-se de um filtro das
  // palavras NÃO, AUSENTE e NORMAL. Pode haver resultados cujo NÃO indica alteração.";

  constructor(
    private log: LogService,
    // private longHisSrv: LongitudinalHistoryService,
    private vitalSignSrv: VitalSignCustomService,
    private cnf: ConfigPwaMedLogicService,
    private route: ActivatedRoute,
    private glb: GlobalService,
    private nav: NavigationPwaService
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.nav.addToHistory(this.route.snapshot.url, 'History');
      this.getUrlParams();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /* Recarrega os dados */
  protected refresh(ano: number, patientCod: number, date: Date): void {
    try {
      const dtStart: Date = date;
      const dtEnd: Date = dtStart;
      // Registros
      this.subs.sink = this
        .vitalSignSrv
        .getAll(ano, dtStart, dtEnd)
        .pipe(
          filter(f => this.glb.isEqual(f.codigoPaciente, patientCod))
        )
        .subscribe(s => {
          this.vitalSigns.push(s);
        },
          (e) => console.log(e),
          () => {
            try {
              for (const field in this.vitalSigns[0]) { // Para cada coluna, adiciona um índice
                if (field) {
                  const obj = {};
                  this.vitalSigns.forEach(e => {
                    obj[`Rotulo_${this.glb.dateTohhmmss(e.dataavaliacao, false, '')}`] = this.vitalSignSrv.getRotulo(field);
                    obj[`ValorTexto_${this.glb.dateTohhmmss(e.dataavaliacao, false, '')}`] = e[field];
                  });
                  if (Object.values(obj)[0] !== '#NAOEXIBIR#') { // Recurso utilizado para identificar quais campso não deve ser exibidos
                    this.rows.push(obj);
                  }
                }
              }
              this.columns = this.vitalSigns.map(m => {
                return {
                  field: this.glb.dateTohhmmss(m.dataavaliacao, false, ''),
                  header: this.glb.dateTohhmmss(m.dataavaliacao, false)
                } as IColumn;
              });
              this.isLoaded = true;
            } catch (error) {
              this.log.Registrar(this.constructor.name, 'refresh.vitalSignSrv', error.message);
            }
          });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  // Refresh ocorrencia - protótipo Cigga
  // obsComponentes
  //   .toArray().map(v => v.sort((a, b) => (b.TabOrder - a.TabOrder)))
  //   .subscribe(items => {
  //     this.componentes = this.componentes.concat(items[0])
  //       .filter((a) => {
  //         var key = a.Rotulo + '|' + a.ValorTexto;
  //         if (!this[key]) {
  //           this[key] = true;
  //           return true;
  //         }
  //       }, Object.create(null));
  //   },
  //   (er) => { },
  //   () => {
  //   });

  /* Cria uma chave para a coluna para relacionar não apenas o campo, mas a hora */
  getKey(clm: IColumn, key: string): string {
    try {
      return `${key}_${clm.field}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getKey', error.message);
    }
  }

  /* Cria um filtro para permitir apenas os itens que são únicos */
  private distinct(item: ITarefaComponente, index: number, self: ITarefaComponente[]): boolean {
    try {
      // return self.indexOf(value) === index;
      return self.findIndex(f => f.Rotulo === item.Rotulo) < 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'distinct', error.message);
    }
  }

  /* Carrega os parâmetros passados pela Url */
  protected getUrlParams(): void {
    try {
      // Os parametros estão sendo passados diretamente aos componentes
      this.subs.sink = this.route.params
        .subscribe((params: Params) => {
          if (params.key) {
            this.ano = params.key;
          }
          if (params.patientCod) {
            this.patientCod = params.patientCod;
          }
          if (params.patientName) {
            this.patientName = params.patientName;
          }
          if (params.date) {
            this.date = this.glb.YYYYMMddThhmmssToDate(params.date);
          }
          this.nav.addToHistory(this.nav.getRouteLongitudinalHistory(
            this.ano, this.patientCod, this.patientName, this.glb.DateToYYYYMMdd(this.date)), 'longitudinal-history');
          this.refresh(this.ano, this.patientCod, this.date);
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /* Clique no botão voltar */
  onBackClick(event): void {
    try {
      this.nav.goBack();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBackClick', error.message);
    }
  }

  /* Incluir as respostas negativas, que supostamente contém os resultados normais.
    * Atenção: trata-se de um filtro da palavra NÃO. Pode haver resultados cujo NÃO indica alteração.
    */
  // includeNormalClick(event): void {
  //   try {
  //     this.includeNormal = !this.includeNormal;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'includeNormalClick', error.message);
  //   }
  // }

}
