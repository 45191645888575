export interface IHistoricoCompraEstoque {
  index?: any;
  dosagem: any;
  material: string;
  centroCusto: string;
  unidademedida: string;
  codigo: any;
  quantidade: any;
  habilitado: boolean;
  data: Date;
  preco: any;
  fornecedor: string;
  unidadeNegocio: string;
  medicamento: any;
  codPacienteNomedoPacienteCodMedicamento: any;
  codigoPedido: any;
  pedidoConferido: boolean;
  codigoItemEstoque: any;
  titulo: string;
  idMedicamento: number;
  lote: string;
  dtValidade: Date;
  quantidadePorEmbalagem: number;
  quantidadeDeEmbalagens: number;

  // Campos gerados de maneira diferente em 25 out 2019
  dataCompra: Date;
  tipoMaterial: string;
  quantidadeEmbalagem: any;
  validoAte: Date;
  idCentroCusto: any;
  quantidadePacotes: any;
}

export abstract class IHistoricoCompraEstoqueClass {
  /*Retorna o título de maneira padronizada. */
  static getTitulo(stockCodigo: any, stockTitulo: string): string {
    try {
      return `${stockCodigo}_${stockTitulo}`;
    } catch (error) {
      console.log(this.constructor.name, 'getTitulo', error.message);
    }
    return '';
  }
}
