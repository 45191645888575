import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HomeView } from '@medlogic/shared/gecore';
import { CustomerConfigService } from '@medlogic/shared/gecore';
import { tap } from 'rxjs/operators';
import { LocalNavigationService } from '../../service/local-navigation.service';
import { AppProvider } from '../../service/app.provider';
import { LocalMsgPtBR } from '../../service/local-msg-ptBR.service';
import { GenericCustomService } from '../../service/generic.custom.service';
import { error } from '../../service/error';
import { GlobalService, LogService, EnTheme } from '@medlogic/shared/shared-interfaces';
import { ModelComponent } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../../../pwa/service/config-pwa-medlogic.custom.service';


/** This component is just a wrapper to the AtividadeCadastroViewComponenent.
 * But it is important because it can capture the events like as the complete,
 * and deal with it specifically.
 */
@Component({
  selector: 'app-local-atividade-cadastro-view',
  templateUrl: './local-atividade-cadastro-view.component.html',
  styleUrls: ['./local-atividade-cadastro-view.component.css']
})
export class LocalAtividadeCadastroViewComponent extends HomeView implements OnInit {

  atividadeNo: number;
  processoNo = -1;
  ocorrenciaNo: number;
  readOnly: boolean;
  saveInList: boolean; // Determina se deve salvar o resultado no cadastro
  defaultFormControls: any;
  isMobile: boolean;
  enTheme: EnTheme.default;
  canShowSavedMessages = true;

  constructor(
    log: LogService,
    glb: GlobalService,
    cnf: ConfigPwaMedLogicService,
    route: ActivatedRoute,
    protected nav: LocalNavigationService,
    modelComponent: ModelComponent,
    customerCnf: CustomerConfigService,
    matDialog: MatDialog,
    protected appPrv: AppProvider,
    protected msg: LocalMsgPtBR,
    protected genericSrv: GenericCustomService,
  ) {
    super(log, glb, cnf, route, nav, modelComponent, customerCnf, msg);
  }

  ngOnInit(): void {
    try {
      this.getUrlParams();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  protected setCadParams(): void {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCadParams', error.message);
    }
  }

  protected setCurrentRoute(title: string = null): void {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCurrentRoute', error.message);
    }
  }

  /* Método principal para carregamento. */
  protected refresh(): void {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  afterCompleted($event: any): void {
    try {
      console.log('complete');
      this.nav.gotoRoot();
      // this.store
      //   .select(state => {
      //     const process = state.process.selectedProcess.ObjInfo;
      //     const processId = process.ProcessoNo;
      //     const activityId = process.AtividadeNo;
      //     const theme = state.tenant.enTheme;
      //     this.nav2.gotoRouteContaHome(false);
      //   }).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterCompleted', error.message);
    }
  }

  afterSaved(ocorrencia: any): void {
    try {
      this.subs.sink = this.genericSrv
        .map(ocorrencia.Componentes)
        .subscribe((generic) => {
          // return this.store.dispatch(updateGeneric({ generic }));
        });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'afterSaved', error.message);
    }
  }

  eventBubble($event: any): void {
    try {
      // console.log('eventBubble');
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'eventBubble', error.message);
    }
  }

  /* Extrai os parâmetros passados na url/rota. */
  protected getUrlParams(): void {
    try {
      // FIXME: Does the parameter must be loaded from Store? But, potential impact in Grid and Combo item edition.
      this.subs.sink = this.route.params
        .pipe(
          tap((params: Params) => {
            this.atividadeNo = params.key ? +params.key : -1;
            this.readOnly = params.readonly === 'true';
            this.enTheme = params.theme ? params.theme : EnTheme.black;
            this.saveInList = params.saveinlist === 'true';
            this.ocorrenciaNo = params.ono ? +params.ono : -1;
            this.processoNo = params.pno ? +params.pno : -1;
          }),
          error()
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }


}
