import {
  clearEvolutions, clearMedications,
  clearPatients, clearVitalSigns, setIsLoading, StatesManagementService
} from '@medlogic/medlogic/medlogic-state';
import {
  PatientCustomService, EvolutionCustomService,
  EstoqueMateriaisCustomServices,
  FamilyCustomService
} from '@medlogic/medlogic/medlogic-data-access';
import { Injectable } from '@angular/core';
import {
  CadConfigService, CustomerConfigService
} from '@medlogic/shared/gecore';
import { LocalMsgPtBR } from './local-msg-ptBR.service';
import { IntervencoesMedicamentosHorariosService } from '@medlogic/medlogic/medlogic-data-access';
import { MedicationCheckinCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { InterventionMedicationCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { CardPatientProvider } from '../../../pwa/provider/card-patient.provider';
import { LocalStorageService } from './local-storage.service';
import { EnRequestType, LogService, UnsubscribeOnDestroyAdapter } from '@medlogic/shared/shared-interfaces';
import { BasePageService, EmailService } from '@medlogic/shared/shared-data-access';
import { ConfigPwaMedLogicService } from '../../../pwa/service/config-pwa-medlogic.custom.service';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError, tap } from 'rxjs/operators';
import { NavigationService } from '../../../pwa/service/navigation.service';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { Store } from '@ngrx/store';
import { clearLogin } from '../ngrx/actions/login.actions';
import { cleanEmails } from '@medlogic/shared/state-utils';

@Injectable({
  providedIn: 'root'
})
export class LocalLoginService extends UnsubscribeOnDestroyAdapter {

  urlPost = 'Account/Login';
  urlPostLoginWithUser = 'Account/LoginWithUser';

  constructor(
    protected basepageService: BasePageService,
    protected cnf: ConfigPwaMedLogicService,
    protected log: LogService,
    protected nav: NavigationService,
    protected cad: CadConfigService,
    protected msg: LocalMsgPtBR,
    protected horPersonSrv: IntervencoesMedicamentosHorariosService,
    protected checkMedSrv: MedicationCheckinCustomService,
    private patientSrv: PatientCustomService,
    private intervecoesMedicamentosSrv: InterventionMedicationCustomService,
    private stockSrv: EstoqueMateriaisCustomServices,
    private evolutionSrv: EvolutionCustomService,
    private custConfigSrv: CustomerConfigService,
    private pv: CardPatientProvider,
    private localStorageSrv: LocalStorageService,
    private store: Store<IAppMedlogicState>,
    private familySrv: FamilyCustomService,
    private emailSrv: EmailService,
    private stateManSrv: StatesManagementService
  ) {
    super();
  }

  /* override */
  logoff(isLoading: boolean = false): void {
    try {
      this.cnf.reset();
      this.localStorageSrv.clean();
      this.cleanAllCaches(isLoading);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'logoff', error.message);
    }
  }

  /* Limpará todos os caches. Deverá navegar para a página inicial novamente, para recarregar os contadores. */
  cleanAllCaches(isLoading: boolean = false): void {
    try {
      this.cleanStates(isLoading);
      try {
        this.intervecoesMedicamentosSrv.clearCache();
      } catch (error) { }
      try {
        this.patientSrv.clearCache();
      } catch (error) { }
      try {
        this.stockSrv.clearCache();
      } catch (error) { }
      try {
        this.horPersonSrv.clearCache();
      } catch (error) { }
      try {
        this.checkMedSrv.clearCache();
      } catch (error) { }
      try {
        this.evolutionSrv.clearCache();
      } catch (error) { }
      try {
        this.custConfigSrv.clearCache();
      } catch (error) { }
      try {
        this.familySrv.clearCache();
      } catch (error) { }
      try {
        this.pv.clearCache();
      } catch (error) { }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanAllCaches', error.message);
    }
  }

  cleanStates(isLoading: boolean = false): void {
    try {
      this.subs.sink = this.stateManSrv.cleanAllStatesAndCache$().subscribe(
        () => {
          this.store.dispatch(setIsLoading({ isLoading }));
          // Limpa apenas os states relacionados ao login/sessão, pois os demais states são excluídos em cleanAllStatesAndCache.
          this.store.dispatch(clearLogin());
          this.store.dispatch(clearPatients());
          // this.store.dispatch(clearMedications());
          // this.store.dispatch(clearEvolutions());
          // this.store.dispatch(clearVitalSigns());
          // this.store.dispatch(cleanEmails());
        }
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanStates', error.message);
    }
  }

  /*
  * Efetuar login. Retorna o token do usuário se bem sucedido.
  * Já gravará o token na propriedade de configurações.
  * Também gravará o id do idoso bem cuidado.
  */
  getLogin(userName: string, password: string): Observable<any> {
    try {
      const login = this.basepageService.baseDados(EnRequestType.Post, this.urlPost,
        {
          Password: password,
          Login: userName
        });
      return new Observable(observer => {
        this.subs.sink = login.subscribe(token => {
          try {
            if (token) {
              this.cnf.baseUsuarioToken = token;
              this.cnf.showMenu = true;
              this.subs.sink = this.cad.getCadIdosoBemCuidado()
                .subscribe(atividade => {
                  this.cnf.tenantId = atividade.AtividadeNo;
                  observer.next({ token, tenantId: atividade.AtividadeNo });
                  observer.complete();
                });
            } else {
              observer.next({ token: null, message: 'Unauthorized', msg: this.msg.LOGIN_FAIL });
              observer.complete();
            }
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'getLogin.subscribe', error.message);
          }
        });
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLogin', error.message);
    }
    return of(null);
  }

  /* Semelhante ao getLogin, no entanto também retorna o usuarioNo, além do token.
  * Atenção: os parâmetros token e usuarioLogadoNo já serão armazenados no config.service.
  */
  getLoginWithUser(userName: string, password: string): Observable<any> {
    try {
      const loginPost = this.basepageService.baseDados(EnRequestType.Post, this.urlPostLoginWithUser,
        {
          password, // Password para acesso direto ao webservice
          userName // Login
        });
      return loginPost
        .pipe(
          mergeMap(login => {
            if (login && !login.message) {
              this.cnf.baseUsuarioToken = login?.UsuarioToken;
              this.cnf.usuarioLogadoNo = login?.UsuarioNo;
              return of(
                {
                  token: login?.UsuarioToken,
                  usuarioLogadoNo: login?.UsuarioNo,
                  role: login?.roles,
                  imgUrl: login?.imgUrl,
                  customerId: login?.customerId
                }
              );
            } else {
              return of({ token: null, message: 'Unauthorized', msg: this.msg.LOGIN_FAIL });
            }
          }),
          catchError((error, obs) => {
            this.log.Registrar(this.constructor.name, 'getLoginWithUser', error.message);
            return of({ token: null, message: 'Unauthorized', msg: this.msg.LOGIN_FAIL });
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLoginWithUser', error.message);
    }
    return of(null);
  }


}
