import { getCodPaciente } from '@medlogic/medlogic/medlogic-state';
import { EnRequestType, error, extractCodPatient, routeGetMedicationCountForPatients } from '@medlogic/shared/shared-interfaces';
import { BasePageService } from '@medlogic/shared/shared-data-access';
import {
  mapMedicationToIntervention, mapInterventionToMedication,
  timesAccordingPosology, getItemHorario
} from '@medlogic/shared/shared-interfaces';
import { map, filter, toArray, mergeMap, reduce } from 'rxjs/operators';
import { IInterventionMedication, IMedicationCheckin } from '@medlogic/shared/shared-interfaces';
import { IIntervecoesMedicamentosHorarios } from '@medlogic/shared/shared-interfaces';
import { EnPosology } from '@medlogic/shared/shared-interfaces';
import { IMedication } from '@medlogic/shared/shared-interfaces';
import { IPatient } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { IntervencoesMedicamentosHorariosService } from './intervecoes-medicamentos.serviceHorario';
import { MedicationCheckinCustomService } from './medication-checkin.custom.service';
import { InterventionMedicationCustomService } from './intervention-medication.custom.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { GlobalService, LogService } from '@medlogic/shared/shared-interfaces';
import { PatientCustomService } from './patient.custom.service';
import { getMedicationStatus } from '@medlogic/shared/utils-exported';

@Injectable()
export class IntervencoesMedicamentosService {

  readonly urlGetAuthorizedPatients = `${routeGetMedicationCountForPatients}?cadInterventionNo={0}&patientIds={1}`;

  constructor(
    protected glb: GlobalService,
    protected log: LogService,
    protected patientSrv: PatientCustomService,
    protected intervMedSrv: InterventionMedicationCustomService,
    protected horPersonSrv: IntervencoesMedicamentosHorariosService,
    protected checkMedSrv: MedicationCheckinCustomService,
    protected basepage: BasePageService
  ) {
    try {
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'constructor', error.message);
    }
  }

  getMedicationCount(ano: number): Observable<{ [patientId: number]: number }> {
    try {
      // Id_Paciente: c.V_29828,
      // Id_Medicamento: c.V_30263,
      const interventions$ = this.intervMedSrv.getSome(ano, 'V_29828,V_30263');
      return interventions$
        .pipe(
          reduce((acc, cur) => {
            const obj = { ...acc };
            obj[cur.Id_Paciente] = (obj.hasOwnProperty(cur.Id_Paciente)) ? obj[cur.Id_Paciente] + 1 : 1;
            return obj;
          }, {}),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMedicationCount', error.message);
    }
    return of(null);
  }

  getMedicationCountForPatients(ano: number, patients: IPatient[]): Observable<{ [patientId: number]: number }> {
    try {
      // Id_Paciente: c.V_29828,
      // Id_Medicamento: c.V_30263,
      const urlGet = this.basepage.format(this.urlGetAuthorizedPatients,
        ano,
        patients?.map(m => +m.prontuario).join(',')
      );
      return this.basepage.baseDados(EnRequestType.Get, urlGet, {});
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMedicationCountForPatients', error.message);
    }
    return of(null);
  }

  /* Para cada um dos pacientes, insere a lista de intervenção medicamentos registrados, para o período escolhido.
  * Será populada a propriedade lstIntervecoesMedicamentos de cada um dos pacientes.
  * Além disso, e mais importante, populará a lista de medication: para cada intervMed gerará uma lista de medicamentos,
  * um para cada horário, conforme descrito na posologia.
  * patient.medications é a propriedade final que será usada para popular o TreeView do Care Map.
  * Essa lista também popula o grid que contém os checkins.
  * @referenceDate: é a data de referência para cálculo do status. O status que indica se
  * não tomou, ou se não está prescrito para o dia, por exemplo, depende dessa data.
  */
  getMedicationsForPatient(
    ano: number,
    cadMedCheckNo: number,
    cadHorarioNo: number,
    patient: IPatient,
    startDt: Date,
    endDt: Date,
    referenceDate: Date = null
  ): Observable<IMedication[]> {
    try {
      // Pipe
      return this.checkCall(cadMedCheckNo, patient, referenceDate)
        .pipe(
          mergeMap((checks) => {
            return this.intervMedSrv.getAll(ano) // As interveções são os medicamentos prescritos, não devem ter data.
              .pipe(
                filter((f) => this.glb.isEqual(getCodPaciente(f), this.patientCodigo(patient))),
                toArray(), // Necessário consolidar, pois, o resultado final deve ser
                this.medWithChecks(null, checks, referenceDate),
                // apenas de um array de medication e não um array para cada intervenção
              );
          }),
          error()
        ); // por causa do mergeMap só haverá continuidade quando todas as emissões forem concluídas
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMedicationsForPatient', error.message);
    }
    return of(null);
  }

  // private getPatientId(medication: IMedication | any): number {
  //   try {
  //     return medication?.patientId || medication?.Id_Paciente || medication?.codigoPaciente || medication?.codigoHospede || -1;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'getPatientId', error.message);
  //   }
  //   return -1;
  // }


  // FIXME: Tentativa de remover os horários do resgate dos dados. Método original mantido em getMedicationsByPrescriptionWithTimes
  getMedicationsByPrescription(
    cadPrescriptionNo: number,
    cadHorarioNo: number,
    prescriptionId: number,
    patient: IPatient,
    startDt: Date,
    endDt: Date,
  ): Observable<IMedication[]> {
    try {
      const mapToMedication$ = (horarios: IIntervecoesMedicamentosHorarios[]) => map((intervMed: IInterventionMedication) => this.mapIntervToMedication(intervMed, intervMed.horaprimeiraDose, null, horarios));
      // Pipe principal
      return this.intervMedSrv.getByPrescription(cadPrescriptionNo, prescriptionId) // As interveções são os medicamentos prescritos, não devem ter data.
        .pipe(
          mapToMedication$(null),
          toArray(),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMedicationsByPrescription', error.message);
    }
    return of(null);
  }

  getMedicationsByPrescriptionWithTimes(
    cadPrescriptionNo: number,
    cadHorarioNo: number,
    prescriptionId: number,
    patient: IPatient,
    startDt: Date,
    endDt: Date,
  ): Observable<IMedication[]> {
    try {
      const mapToMedication$ = (horarios: IIntervecoesMedicamentosHorarios[]) => map((intervMed: IInterventionMedication) => this.mapIntervToMedication(intervMed, intervMed.horaprimeiraDose, null, horarios));
      // Pipe principal
      return this.horarioCall(cadHorarioNo, patient, startDt, endDt)
        .pipe(
          mergeMap(horarios => {
            return this.intervMedSrv.getByPrescription(cadPrescriptionNo, prescriptionId) // As interveções são os medicamentos prescritos, não devem ter data.
              .pipe(
                mapToMedication$(horarios),
                toArray()
              );
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMedicationsByPrescriptionWithTimes', error.message);
    }
    return of(null);
  }

  // Para os checkins, é necessário trazer apenas os registros do próprio dia, para que sejam devidamente assinalados nas medicações.
  private checkCall = (cadMedCheckNo: number, patient: IPatient, referenceDate: Date) => {
    const refStart = referenceDate || new Date();
    const refEnd = this.glb.addDays(refStart, 2);
    return this.checkMedSrv.getAll(cadMedCheckNo, refStart, refEnd)
      .pipe( // Nesse caso, é mais eficiente trazer de todos e depois filtrar,
        // pois, haverá uma única chamada do serviço e depois retornará de cache.
        filter((f) => this.glb.isEqual(f?.Id_Paciente, patient?.codigo)),
        toArray(),
        error()
      );
  }

  private patientCodigo = (patient: IPatient) => this.patientSrv.calcPatientCodigo(patient);// patient.calcCodigo(patient);

  // Chamadas de dois métodos get, sendo que a segunda chamada aguardará a conclusão da primeira
  private horarioCall = (
    cadHorarioNo: number,
    patient: IPatient,
    startDt: Date,
    endDt: Date,
  ) => this.horPersonSrv.getAll(cadHorarioNo, startDt, endDt)
    .pipe(
      // Essa chamada está se repetindo diversas vezes, mas é viável, pois, os parâmetros são os mesmos e portanto retornará de cache
      filter((f) => {
        try {
          // const split = f.codigoMedicamentoPaciente.indexOf('__') >= 0 ?
          //   f.codigoMedicamentoPaciente.split('__') :
          //   f.codigoMedicamentoPaciente.split('_');
          // const codPatient = split && split.length === 2 ? split[1] : null;
          const codPatient = extractCodPatient(f);
          return this.glb.isEqual(codPatient, this.patientCodigo(patient));
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'getMedicationsForPatient.horarioCall.filter', error.message);
        }
        return false;
      }),
      toArray()
    ); // Fará com que a consulta inteira seja completada e consolidada num array, para então dar sequencia

  private medWithChecks = (horarios: IIntervecoesMedicamentosHorarios[], checks: IMedicationCheckin[], referenceDate: Date) =>
    map((intervMeds: IInterventionMedication[]) => {
      try {
        return intervMeds.reduce((meds, intervMed) => {
          // Será chamado n vezes, um para cada emissão
          intervMed.lstmedicationCheckin = checks;
          // Como resultado, para cada intervMed, gerará uma lista de medicamentos, um para cada horário.
          const medicationsWithTimes = this.getMedicationWithTimes(intervMed, horarios, referenceDate);
          return [...meds, ...medicationsWithTimes];
        }, new Array<IMedication>());
      } catch (error) {
        this.log.Registrar(this.constructor.name, 'insertInterventionMedicationForPatients.medWithChecks', error.message);
      }
    });

  /* Retorna S para tomou, N para Não tomou, - não está prescrito nesse dia. SH Tomou fora de horário. NJ Não tomou com justificativa.
  * patient não é usado, mas necessário para manter compatibilidade com status do vital sign.
  * Mudança de conceito: Ao invés de considerar marcado o checkin conforme data e hora, comparar o
  */
  getStatus(patient: IPatient, medication: IMedication, date: Date, check: IMedicationCheckin): string {
    try {
      return getMedicationStatus(patient, medication, date, check);

      // if (!date && !check) {
      //   return 'N';
      // } else if (
      //   !this.glb.isEqualIgnoreTime(date, medication.dtStart) &&
      //   (date < medication.dtStart || (medication.stopMedication && date > medication.dtEnd)) // (date > medication.dtEnd && medication.dtStart < medication.dtEnd))
      // ) {
      //   return '-';
      // } else if (check) {
      //   if (this.glb.isEqual(check.status, 'NJ')) { return 'NJ'; } // Significa que o check foi realizado, mas o paciente NÃO TOMOU.
      //   return this.isOnPrescribedInterval(check) ? 'S' : 'SH';
      // } else {
      //   if (date.getTime() >= new Date().getTime()) {
      //     if (
      //       this.checkIsDateOnMustToTake(
      //         date,
      //         medication?.dtStart,
      //         medication?.dtEnd,
      //         medication?.enPosology,
      //         medication?.intervaloDS,
      //         medication?.stopMedication
      //       )
      //     ) {
      //       return '-';
      //     }
      //     return 'P';
      //   }
      //   return medication.status === '-' ? '-' : 'N';
      // }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getStatus', error.message);
      return ' ';
    }
  }

  /* Determina se o check foi dentro da tolerância de horário. */
  // protected isOnPrescribedInterval(check: IMedicationCheckin, toleranceMin: number = 60): boolean {
  //   try {
  //     const checkTime = this.glb.getHora(check.horaCheckin); // , true);
  //     const prescribedTime = this.glb.getHora(check.horaPrescricao); // , true);
  //     return checkTime <= prescribedTime + (toleranceMin / 60) && checkTime >= prescribedTime - (toleranceMin / 60);
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'isOnPrescribedInterval', error.message);
  //   }
  //   return true;
  // }

  /* Definirá se a data fornecida coincide com uma data na qual a medicação deveria ser tomada.
  * Por exemplo: se a posologia for semanal e prescrita para iniciar em 01/12/2018 (sábado) e finalizar em 01/03/2019
  * então deverá checar se a data fornecida é superior a 01/12/2018 (que é também a data de início),
  * se é inferior a 01/03/2019, e se também coincide com uma das datas que têm intervalo de 7 em 7 dias.
  */
  // protected checkIsDateOnMustToTake(
  //   dtToCheck: Date,
  //   dtStart: Date,
  //   dtEnd: Date,
  //   enPosology: EnPosology,
  //   intervaloDS: any,
  //   stopMedication: boolean
  // ): boolean {
  //   try {
  //     // A checagem de dtStart < dtEnd é necessária, pois, caso a medicação seja
  //     // 'Permanente', a dtEnd ficará oculta e igual a data do dia de preenchimento.
  //     if (this.glb.isEqualIgnoreTime(dtToCheck, dtStart)) {
  //       return false;
  //       // } else if (dtToCheck < dtStart || (dtToCheck > dtEnd && dtStart < dtEnd)) {
  //     } else if (dtToCheck < dtStart || (stopMedication && dtToCheck > dtEnd)) {
  //       return true;
  //     }
  //     let dayInterval = 1;
  //     const posology = enPosology ? enPosology.toUpperCase() : '';
  //     switch (posology) {
  //       default:
  //       case EnPosology.p1perDay:
  //         dayInterval = 1;
  //         break;
  //       case EnPosology.perDay:
  //         dayInterval = intervaloDS;
  //         break;
  //       case EnPosology.perWeek:
  //         dayInterval = intervaloDS * 7;
  //     }
  //     const diff = this.glb.dateDiffDays(dtStart, dtToCheck);
  //     return diff % dayInterval !== 0;
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'checkIsDateOnMustToTake', error.message);
  //   }
  //   return false;
  // }

  /* Retorna uma lista de medicamentos, um medicamento para cada horário, conforme posologia.
  * referenceDate: é a data de referência para cálculo do status. O status que indica se
  * não tomou, ou se não está prescrito para o dia, por exemplo, depende dessa data.
  */
  protected getMedicationWithTimes(
    intervMed: IInterventionMedication,
    horarios: IIntervecoesMedicamentosHorarios[],
    referenceDate: Date = null
  ): IMedication[] {
    try {
      const lstHorarios = this.timesAccordingPosology(intervMed, horarios, +intervMed.Id_Medicamento);
      return lstHorarios.map((h) => {
        try {
          const checksOfMedication = intervMed?.lstmedicationCheckin?.filter((f) =>
            this.glb.isEqual(f.Id_Medicamento, intervMed?.Id_Medicamento)
          );
          const medication = this.mapIntervToMedication(intervMed, h, checksOfMedication);
          const key = this.checkMedSrv.generateKey(
            intervMed.codigoPaciente,
            medication,
            referenceDate // medication.medicationDate
          );
          const check = checksOfMedication?.find((f) => f.identificacao === key);
          const status = this.getStatus(null, medication, referenceDate, check);
          medication.status = status;
          if (check) {
            medication.took = this.glb.isNullOrEmpty(check.tomou) ? true : check.tomou;
            medication.observation = check.observacoes;
            medication.medicationDate = check.dataCheckin;
            medication.date = this.glb.dateToddMMYYYYhhmmss(check.dataCheckin);
          }
          return medication;
        } catch (error) {
          this.log.Registrar(this.constructor.name, 'getMedicationWithTimes.map', error.message);
        }
        return null;
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getMedicationWithTimes', error.message);
    }
  }

  mapIntervToMedication(intervMed: IInterventionMedication, prescribedTime: string = null, lstCheckin: IMedicationCheckin[] = null, lstHorarios: IIntervecoesMedicamentosHorarios[] = null): IMedication {
    return mapInterventionToMedication(intervMed, prescribedTime, lstCheckin, lstHorarios);
    // prescribedTime = prescribedTime || intervMed.horaprimeiraDose;
    // lstCheckin = lstCheckin || intervMed.lstmedicationCheckin;
    // return {
    //   medicationId: +intervMed.Id_Medicamento,
    //   ocorrenciaNo: +intervMed.ocorrenciaNo,
    //   medicationName: intervMed?.medicametnoPrescrito,
    //   prescription: intervMed?.medicametnoPrescrito,
    //   prescriptionId: intervMed?.Id_Prescricao,
    //   prescribedTime: this.glb.formatTime(prescribedTime, true),
    //   dosage: intervMed?.dosagem,
    //   took: false,
    //   lstCheckin,
    //   lstHorarios,
    //   date: intervMed?.dataPRESCRICAO,
    //   status: null,
    //   access: intervMed?.via,
    //   presentation: intervMed?.apresentacao,
    //   instruction: intervMed?.orientacoes,
    //   material: intervMed?.tIPOMaterial || 'MEDICAMENTO',
    //   businessUnit: intervMed?.uNIDADEDENEGOCIO,
    //   costCenter: intervMed?.centrocusto,
    //   unity: intervMed?.apresentacao,
    //   codPacienteNomedoPacienteCodMedicamento: intervMed?.codPacienteNomedoPacienteCodMedicamento,
    //   dtStart: intervMed?.dataInicio,
    //   dtEnd: intervMed?.dataFim,
    //   enPosology: intervMed?.posologia,
    //   intervaloDS: intervMed?.intervaloDS,
    //   dailyQuantity: intervMed?.quantUtilizadaD || 1,
    //   dailyQuantityGotas: intervMed?.quantUtilizadaGota || 1,
    //   isSOS: intervMed?.calc__isSOS,
    //   medicationDate: intervMed?.dataEVOLUCAO,
    //   stopMedication: intervMed?.interroperMedicacao,
    //   stopInstructions: intervMed?.interrupcaoMotivo,
    //   isRestricted: this.glb.getBoolean(intervMed?.medicamentoControlado),
    //   tempoUso: intervMed?.tempoUso,
    //   order: +intervMed.ordem,
    //   wasChanged: intervMed.wasChanged,
    //   estimatedTime: intervMed.tempoEstimado,
    //   materialId: intervMed.materialId,
    //   horarios: this.intervMedSrv.convertGridHorariosToText(intervMed?.lsthorariosGrid)
    // } as IMedication;
  }

  mapMedicationToInverv(medication: IMedication, professional: string, patientId: number = null, patientName: string = null): IInterventionMedication {
    return mapMedicationToIntervention(medication, professional, patientId, patientName);
    // if (!medication) {
    //   return null;
    // }

    // const splitCodPacNomePacCodMed = medication?.codPacienteNomedoPacienteCodMedicamento?.split('_');
    // const patientId: number = splitCodPacNomePacCodMed ? +splitCodPacNomePacCodMed[0] : null;
    // const patientName: string = splitCodPacNomePacCodMed ? splitCodPacNomePacCodMed[1] : null;
    // const material = medication?.material;
    // return {
    //   apresentacao: medication.presentation,
    //   calc__isSOS: medication.isSOS,
    //   // cascataCheckIDClienteIDMedicamentoDataEVOLUCAO: medication.cascataCheckIDClienteIDMedicamentoDataEVOLUCAO,
    //   centrocusto: medication.costCenter,
    //   centroCustoIDCentroCusto: `${patientName}_${patientId}`,
    //   codigo: medication.medicationId,
    //   codigoHospede: patientId,
    //   codigomedicamento: medication.medicationId,
    //   codigoMedicamentoPaciente: `${medication.medicationId}_${patientName}`,
    //   codigoPaciente: patientId,
    //   codigoPacienteNomePaciente: `${patientId}_${patientName}`,
    //   codPacienteNomedoPacienteCodMedicamento: medication.codPacienteNomedoPacienteCodMedicamento,
    //   dataEVOLUCAO: medication.medicationDate,
    //   dataFim: medication.dtEnd,
    //   dataInicio: medication.dtStart,
    //   dataPRESCRICAO: medication.date,
    //   // dia: medication.order, //*
    //   dosagem: medication.dosage,
    //   guid: medication.guid,
    //   habilitado: true,
    //   // hojedia: null, //*
    //   horaprimeiraDose: this.glb.formatTime(medication.prescribedTime, true),
    //   horarios: medication.horarios,
    //   Id_Medicamento: +medication.medicationId,
    //   Id_Paciente: patientId,
    //   Id_Prescricao: medication.prescriptionId,
    //   IdMedicamento_IdPaciente: `${medication.medicationName}_${patientId}`, //* `No Studio, a fórmula está para o nome ao invés do id`${medication.medicationId}_${patientId}`
    //   interroperMedicacao: medication.stopMedication,
    //   interrupcaoMotivo: medication.stopInstructions,
    //   intervaloDS: medication.intervaloDS,
    //   intervaloHoras: medication.intervaloDS, //*
    //   lsthorariosGrid: medication.lstHorarios, //*
    //   lstmedicationCheckin: medication.lstCheckin,
    //   medicamento: medication.medicationName,
    //   medicamentoControlado: this.glb.getReverseBoolean(medication.isRestricted),
    //   medicametnoPrescrito: medication.medicationName,
    //   ocorrenciaNo: +medication.ocorrenciaNo,
    //   ordem: medication.order,
    //   orientacoes: medication.instruction,
    //   posologia: medication.enPosology,
    //   profissional: professional,
    //   quantTempoEstimado: this.glb.isEqual(medication.material, 'TERAPIA') ? medication.estimatedTime : medication.dailyQuantity, //*
    //   quantUtilizadaD: medication.dailyQuantity || 1,
    //   quantUtilizadaGota: medication.dailyQuantityGotas,
    //   tempoEstimado: medication.estimatedTime, //*
    //   tempoUso: medication.tempoUso, // Ocasional? Permanente?
    //   tIPOMaterial: medication.material,
    //   tipomedicamento: material, //*
    //   tIPOMedicamentos: material, //*
    //   tipoMedicamentosCodPaciente: `${medication.materialId}_${patientId}`,
    //   titulo: medication.medicationTitle || `Paciente: ${patientName}__${medication.medicationName}__${medication.dosage}__${medication.medicationId}`,
    //   uNIDADEDENEGOCIO: medication.businessUnit,
    //   validCadastro: new Date(),
    //   via: medication.access,
    //   wasChanged: medication.wasChanged,
    //   materialId: medication.materialId
    // *** */
    // Mapeamento antigo
    // guid: medication.guid,
    // Id_Medicamento: +medication.medicationId,
    // ocorrenciaNo: +medication.ocorrenciaNo,
    // medicametnoPrescrito: medication.medicationName,
    // medicamento: medication.medicationName,
    // horaprimeiraDose: this.glb.formatTime(medication.prescribedTime),
    // dosagem: medication.dosage,
    // lstmedicationCheckin: medication.lstCheckin,
    // lsthorariosGrid: medication.lstHorarios,
    // dataPRESCRICAO: medication.date,
    // via: medication.access,
    // apresentacao: medication.presentation,
    // orientacoes: medication.instruction,
    // tIPOMaterial: medication.material,
    // uNIDADEDENEGOCIO: medication.businessUnit,
    // centrocusto: medication.costCenter,
    // codPacienteNomedoPacienteCodMedicamento: medication.codPacienteNomedoPacienteCodMedicamento,
    // dataInicio: medication.dtStart,
    // dataFim: medication.dtEnd,
    // posologia: medication.enPosology,
    // intervaloDS: medication.intervaloDS,
    // quantUtilizadaD: medication.dailyQuantity || 1,
    // quantUtilizadaGota: medication.dailyQuantityGotas,
    // calc__isSOS: medication.isSOS,
    // dataEVOLUCAO: medication.medicationDate,
    // interroperMedicacao: medication.stopMedication,
    // interrupcaoMotivo: medication.stopInstructions,
    // medicamentoControlado: this.glb.getReverseBoolean(medication.isRestricted),
    // tempoUso: medication.tempoUso,
    // ordem: medication.order,
    // //
    // codigoPaciente: patientId,
    // // hojedia: null,
    // codigo: medication.medicationId,
    // titulo: medication.medicationTitle || `Paciente: ${patientName}__${medication.medicationName}__${medication.dosage}__${medication.medicationId}`,
    // // habilitado: true,
    // // cascataCheckIDClienteIDMedicamentoDataEVOLUCAO: medication.cascataCheckIDClienteIDMedicamentoDataEVOLUCAO,
    // centroCustoIDCentroCusto: `${patientName}_${patientId}`,
    // tIPOMedicamentos: medicationType,
    // tipomedicamento: medicationType,
    // // dia: medication.order,
    // codigoPacienteNomePaciente: `${patientId}_${patientName}`,
    // // validCadastro: medication.order,
    // tipoMedicamentosCodPaciente: `${medicationType}_${patientId}`,
    // codigoMedicamentoPaciente: `${medication.medicationId}_${patientName}`,
    // Id_Paciente: patientId,
    // IdMedicamento_IdPaciente: `${medication.medicationName}_${patientId}`,
    // intervaloHoras: medication.intervaloDS,
    // horarios: medication.horarios, //
    // profissional: professional,
    // // tempoEstimado: medication.,
    // // quantTempoEstimado: medication.order,
    // codigoHospede: medication.order,
    // Id_Prescricao: medication.prescriptionId,
    // } as unknown as IInterventionMedication;
  }

  /* Fabrica uma lista diária de horários conforme a posologia do medicamento. */
  protected timesAccordingPosology(
    intervMed: IInterventionMedication,
    horarios: IIntervecoesMedicamentosHorarios[],
    idMedicamento: number = null
  ): string[] {
    try {
      return timesAccordingPosology(intervMed, horarios, idMedicamento);
      // let result = [];
      //   const posology = intervMed.posologia ? intervMed.posologia.toUpperCase() : '';
      //   const referenceTime = this.glb.formatTime(intervMed?.horaprimeiraDose);
      //   if (posology !== EnPosology.customized) {
      //     switch (posology) {
      //       default:
      //       case EnPosology.p12in12h:
      //         result = this.getItemHorario(referenceTime, 12);
      //         break;
      //       case EnPosology.p8in8h:
      //         result = this.getItemHorario(referenceTime, 8);
      //         break;
      //       case EnPosology.p6in6h:
      //         result = this.getItemHorario(referenceTime, 6);
      //         break;
      //       case EnPosology.p1perDay:
      //         result = this.getItemHorario(referenceTime, 24);
      //         break;
      //       case EnPosology.perDay:
      //         result = this.getItemHorario(referenceTime, 24);
      //         break;
      //       case EnPosology.perWeek:
      //         result = this.getItemHorario(referenceTime, 24);
      //         break;
      //       case EnPosology.sos:
      //         result = this.getItemHorario(referenceTime, 24);
      //         break;
      //       case EnPosology.hourInterval:
      //         result = this.getItemHorario(referenceTime, intervMed.intervaloHoras);
      //         break;
      //     }
      //   } else {
      //     result = horarios
      //       .filter((h) => {
      //         try {
      //           const split = h?.codigoMedicamentoPaciente?.split('__');
      //           const codMed = split && split[0] ? split[0] : null;
      //           return this.glb.isEqual(idMedicamento, codMed);
      //         } catch (error) {
      //           this.log.Registrar(this.constructor.name, 'timesAccordingPosology.filter', error.message);
      //         }
      //         return false;
      //       })
      //       .map<string>((h) => h?.hora)
      //       .sort((a, b) => this.glb.CompararIgnorarCapitulacaoEAcentuacao(a, b));
      //   }
      //   return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'timesAccordingPosology', error.message);
    }
    return [];
  }

  /* Retorna uma lista de horários a partir de uma hora inicial e um intervalo entre os horários. */
  protected getItemHorario(horaInicio, intervalo): string[] {
    return getItemHorario(horaInicio, intervalo);
    // const result = [];
    // try {
    //   // Existe a possibilidade do intervalo ser uma fração de um dia, mas também de ser um número de dias, exemplo a cada 72 horas
    //   const contHorario = intervalo <= 24 ? 24 / intervalo : intervalo;
    //   if (horaInicio !== '' || undefined) {
    //     result.push(horaInicio);
    //   } else {
    //     result.push('06:00');
    //   }
    //   for (let index = 1; index < contHorario; index++) {
    //     const date = moment('29/11/2018' + result[index - 1], 'DD/MM/YYYY h:m').add(intervalo, 'hours');
    //     result.push(moment(date).format('HH:mm'));
    //   }
    //   return result;
    // } catch (error) {
    //   this.log.Registrar(this.constructor.name, 'getItemHorario', error.message);
    //   return [];
    // }
  }

  /* Limpa o cache de forma que a próxima chamada buscará os dados do serviço novamente. */
  clearCache(): void {
    try {
      this.intervMedSrv.clearCache();
      this.horPersonSrv.clearCache();
      this.checkMedSrv.clearCache();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'clearCache', error.message);
    }
  }

  /* Retorna uma lista de horários a partir de uma hora inicial e um intervalo entre os horários. */
  // protected getItemHorarioDia(horaInicio, intervalo): Array<string> {
  //     let result = [];
  //     try {
  //         // Existe a possibilidade do intervalo ser uma fração de um dia, mas também de ser um número de dias, exemplo a cada 72 horas
  //         let contHorario = intervalo <= 24 ? 24 / intervalo : intervalo
  //         if (horaInicio != "" || undefined) {
  //             result.push(horaInicio);
  //         } else {
  //             result.push("06:00");
  //         }
  //         for (var index = 1; index < 1; index++) {
  //             const date = moment('29/11/2018' + result[index - 1], "DD/MM/YYYY h:m").add(intervalo, 'hours');
  //             result.push(moment(date).format("HH:mm"));
  //         }
  //         return result;
  //     } catch (error) {
  //         this.log.Registrar(this.constructor.name, 'getItemHorario', error.message);
  //         return [];
  //     }
  // }
}
