import { PatientCustomService, EvolutionCustomService, VitalSignCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { EvolutionComponent } from './pwa/view/evolution/evolution.component';
// Project modules
// Angular
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

// Component
import { LongitudinalHistoryComponent } from './pwa/view/longitudinal-history/longitudinal-history.component';

// Pipe
// Directive
// PrimeNg

// Services
import { LongitudinalHistoryService } from './assist/service/longitudinal-history.service';
// import { CookieModule } from 'ngx-cookie';
import { CheckMedicamentos } from './assist/service/checkMedicamentos.service';
import { IntervencoesVitalSigns } from './assist/service/intervercoes-vitalsigns.service';
import { LibErpService } from './erp/service/lib-erp.service';
import { ProfessionalCustomService } from './assist/service/professional.custom.service';
import { FormDALService, MaterialDialogService, CustomerConfigService, GeCoreModule } from '@medlogic/shared/gecore';
import { CadastroService } from '@medlogic/shared/shared-data-access';
import { LocalCoreModule } from './module/core/local-core.module';
import { PrimeNgModule } from './module/core/primeng.module';
// import { AngularDateTimePickerModule } from 'angular2-datetimepicker';

// Classes
@NgModule({
  declarations: [
    LongitudinalHistoryComponent,
    // CareMap
    EvolutionComponent,
  ],
  exports: [
  ],
  imports: [
    GeCoreModule,
    // AppGeModule,
    CommonModule,
    LocalCoreModule,
    PrimeNgModule,
    // Componente de Data
    // CookieModule.forRoot()
  ],
  providers: [
    LongitudinalHistoryService,
    // Care Map
    PatientCustomService,
    FormDALService,
    CheckMedicamentos,
    IntervencoesVitalSigns,
    MaterialDialogService,
    CadastroService,
    LibErpService,
    CustomerConfigService,
    VitalSignCustomService,
    EvolutionCustomService,
    ProfessionalCustomService
  ]
})
export class AppAssistModule {

  /* Para previnir de multiplas importações desse módulo, que deve ser singleton */
  constructor(@Optional() @SkipSelf() parentModule: AppAssistModule) {
    if (parentModule) {
      throw new Error(
        'AppAssistModule is already loaded. Import it in the AppModule only');
    }
  }


}
