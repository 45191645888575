<div [class.isMobile]="isMobile">

  <div class="top-bar" [class.isMobile]="isMobile">

    <button mat-button (click)="onBack($event)" type="button" >
      <mat-icon>arrow_back_ios</mat-icon>VOLTAR
    </button>

  </div>

</div>

<div class="wrapper wrapper-content animated fadeInRight overflow" [class.isMobile]="isMobile">

  <lib-cmp-atividade id="vital" [usuarioNo]="usuarioNo$ | async" [saveInList]="true" [ocorrenciaNo]="ocorrenciaNo$ | async"
    [atividadeNo]="atividadeNo$ | async" [processoNo]="processoNo" [defaultFormControls]="defaultFormControls$ | async"
    [isReadOnly]="isReadOnly" [isMobile]="isMobile" [enTheme]="enTheme" (eventBubble)="eventBubble($event)"
    (onErpRefresh)="onRefresh($event)" (onBack)="onGeBack($event)" (eventAfterCompleted)="afterCompleted($event)"
    (afterSaved)="afterSaved($event)"></lib-cmp-atividade>

</div>
