import { LogService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { EnRequestType } from '@medlogic/shared/shared-interfaces';
import { EnContentType } from '@medlogic/shared/shared-interfaces';
import { iif, Observable } from 'rxjs';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap, map, tap } from 'rxjs/operators';
import { retryWhen } from 'rxjs/operators';
import { delay } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { publishReplay, refCount, defaultIfEmpty, take, concatMap } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class BasePageService {

  private dicCache$: { [id: string]: Observable<any> } = {};

  constructor(
    private http: HttpClient,
    private cfgJson: ConfigJsonService,
    private glb: GlobalService,
    private log: LogService
  ) { }

  format(...args): string {
    let s: string = args[0];
    for (let i = 0; i < arguments.length - 1; i++) {
      const reg = new RegExp('\\{' + i + '\\}', 'gm');
      s = s.replace(reg, arguments[i + 1]);
    }
    return s;
  }

  replaceAll(target: string, search: string | RegExp, replacement: any) {
    try {
      return target.replace(new RegExp(search, 'g'), replacement);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'replaceAll', error.message);
    }
  }

  getQueryString(name: string, url: string) {
    try {
      if (!url) {
        url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      const results = regex.exec(url);
      if (!results) { return null; }
      if (!results[2]) { return ''; }
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getQueryString', error.message);
    }
  }

  baseDados<T = any>(
    type: EnRequestType,
    url: string,
    dados: any,
    numOfRetries: number = 3,
    enContentType: EnContentType = EnContentType.Json
  ): Observable<T> {
    try {
      if ((url.indexOf(this.cfgJson.baseUrlAPI) <= 0) && !url.startsWith('http')) {
        url = this.cfgJson.baseUrlAPI + url;
      }
      switch (type) {
        case EnRequestType.Get:
          return this.get<T>(url, numOfRetries, enContentType);
        case EnRequestType.Post:
          return this.post<T>(url, dados, numOfRetries, enContentType);
        case EnRequestType.Delete:
          return this.delete<T>(url, numOfRetries);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'baseDados', error.message);
    }
    return of(null);
  }

  protected error = () => catchError((err, obs) => {
    this.log.Registrar(this.constructor.name, 'baseDadosReplay', err.message);
    return of({ msg: 'Load error or token-expired' }); // TODO: not necessarily the error result is because token
  })

  /* Similar ao baseDados, mas adiciona o publishReplay e refCount para realizar cache e retornar apenas o resultado mais recente.
  * Também assegura que o retorno será um observable.
  */
  baseDadosReplay(
    type: EnRequestType,
    url: string,
    dados: any,
    numOfRetries: number = 3,
    enContentType: EnContentType = EnContentType.Json
  ): Observable<any> {
    try {
      return this.baseDados(type, url, dados, numOfRetries, enContentType)
        .pipe(
          this.cache(url, dados),
          this.error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'baseDadosReplay', error.message);
    }
    return of(null);
  }

  /** Limpa o cache para uma chave específica ou todo. */
  protected cleanCache(url: string = null, dados: any = null): void {
    try {
      if (!url && !dados) {
        this.dicCache$ = {};
      } else {
        const key = this.keyGen(url, dados);
        let cacheItem$ = this.dicCache$[key];
        if (cacheItem$) {
          cacheItem$ = null;
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'cleanCache', error.message);
    }
  }

  /** Gera a chave para o dicionário de caches. */
  protected keyGen(url: string, dados: any): string {
    try {
      dados = dados || '';
      url = url || '';
      return `${url}__${JSON.stringify(dados)}`;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'keyGen', error.message);
    }
    return null;
  }

  private cache = <T = any>(url: string, dados: string) => mergeMap((item: any) => {
    const key = this.keyGen(url, dados);
    let cacheItem$ = this.dicCache$[key];
    if (cacheItem$) {
      return cacheItem$;
    } else {
      cacheItem$ = of(item)
        .pipe(
          publishReplay(),
          refCount(),
          defaultIfEmpty(null),
          this.error()
        );
      return cacheItem$.pipe(map(m => m as T));
    }
  })

  get<T = any>(
    url: string,
    numOfRetries: number = 3,
    enContentType: EnContentType = EnContentType.Json
  ): Observable<T> {
    try {
      let headers: HttpHeaders;
      switch (enContentType) {
        default:
        case EnContentType.Json:
          headers =
            new HttpHeaders(
              {
                'Content-Type': 'application/json; charset=utf-8',
                'Data-Type': 'json',
              });
          break;
        case EnContentType.XML:
          headers =
            new HttpHeaders(
              {
                'Content-Type': 'text/xml; charset="utf-8"',
                'Data-Type': 'xml',
              });
          break;
      }
      return this.http.get(url, { headers })
        .pipe(
          this.httpRetry(numOfRetries), // retry a failed request up to 3 times
          this.trackAndError(),
          map((res: any) => {

            return res as T;
          })
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'get', error.message);
    }
    return of(null);
  }

  protected trackAndError = () => catchError((error: any) => {
    return throwError(error || 'Server error');
  })

  post<T = any>(url: string, dados: any, numOfRetries: number = 3, enContentType: EnContentType = EnContentType.Json): Observable<T> {
    try {
      let headers: HttpHeaders;
      let data: string | FormData;
      switch (enContentType) {
        case EnContentType.FormData:
          headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'enctype': 'multipart/form-data'
          });
          data = Object.keys(dados).reduce((obj, key) => { obj.append(key, dados[key]); return obj; }, new FormData());
          break;
        default:
        case EnContentType.Json:
          headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Data-Type': 'json'
          });
          data = JSON.stringify(dados);
          break;
      }
      // headers.append('Access-Control-Allow-Origin', '');
      return this.http.post(url, data, { headers })
        .pipe(
          // tap((t) => console.log(t)),
          this.httpRetry(numOfRetries), // retry a failed request up to 3 times
          map(m => m as T),
          catchError((err, obs) => of(err))
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'post', error.message);
    }
    return of(null);
  }

  delete<T = any>(
    url: string,
    numOfRetries: number = 3
  ): Observable<T> {
    try {
      const headers = new HttpHeaders(
        {
          'Content-Type': 'application/json; charset=utf-8',
          'Data-Type': 'json',
        });
      return this.http.delete(url, { headers })
        .pipe(
          this.httpRetry(numOfRetries), // retry a failed request up to 3 times
          this.trackAndError(),
          map((res: any) => res as T)
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'delete', error.message);
    }
    return of(null);
  }

  /* Operador personalizado para um número pré-determinado de retentativas a cada delay. */
  protected httpRetry = (maxRetry: number = 3, delayMs: number = 3000) =>
    retryWhen((errors: any) => errors.pipe(
      mergeMap((error: any) => iif(() => error === 'Unauthorized',
        throwError(() => new Error('Unauthorized')),
        of(error).pipe(
          delay(delayMs), take(maxRetry), concatMap(() => throwError(() => new Error('Número máximo de tentativas excedido!')))
        )
      ))))

  guid() {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  getClienteNo(strClienteId: string) {
    try {
      let result = '';
      const str = atob(strClienteId);
      for (let i = 0; i < str.length; i++) {
        if (i % 2 === 0) {
          result = result + str[i];
        }
      }
      return result;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getClienteNo', error.message);
    }
  }

  /*
  * Cria um objeto para salvar no método SetData
  */
  newObjSetData(atividadeNo: number, tarefaNo: number, ocorrenciaNo: number, valorTexto: any, variavelNo: number, usuarioNo: number) {
    try {
      const body = {
        UsuarioNo: usuarioNo,
        DtRegistro: this.glb.dateToYYYYMMddThhmmss(new Date()),
        OcorrenciaNo: ocorrenciaNo,
        // "ValorTexto": "<![CDATA[" + valorTexto + "]]>",
        ValorTexto: valorTexto,
        ValorData: '',
        TarefaNo: tarefaNo,
        ValorBit: false,
        VariavelNo: variavelNo,
        Versao: 1,
        AtividadeNo: atividadeNo,
        Calculos: '<![CDATA[<Alerta>false</Alerta>]]>'
      };
      return body;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'newObjSetData', error.message);
    }
  }


}
