import { Injectable } from '@angular/core';
import { EvolutionService } from './evolution.service';
import { error, GlobalService, IMedlogicEvolution, LogService } from '@medlogic/shared/shared-interfaces';
import { Observable, of } from 'rxjs';
import { map, filter, mergeMap, publishReplay, refCount, reduce } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CadastroService } from '@medlogic/shared/shared-data-access';

@Injectable({
  providedIn: 'root'
})
export class EvolutionCustomService extends EvolutionService {

  constructor(
    protected http: HttpClient,
    protected cadastroSrv: CadastroService,
    protected glb: GlobalService,
    protected log: LogService) {
    super(http, cadastroSrv, glb, log);
  }
  protected MAX_PAIN = 8; // Nível máximo de dor tolerável.

  /* Retorna todos os itens através do id do código do paciente.
  * É um filtro real, cujo retorno do bd já virá filtrado.
  */
  getByCodigoPaciente(ano: number, codigoPaciente: string, dtStart: Date = null, dtEnd: Date = null): Observable<IMedlogicEvolution> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const strFilter = `V_2230:${codigoPaciente}`;
      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByCodigoPaciente', error.message);
    }
    return of(null);
  }

  /* Retorna todos os itens dos sinais vitais através do id do paciente. */
  getByIdAndPeriod(ano: number, patientId: number, dtStart: Date = null, dtEnd: Date = null): Observable<IMedlogicEvolution> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filterStr = `V_2230:${patientId}`;
      return this.getFiltered(this.cadastroNo, filterStr, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByIdAndPeriod', error.message);
    }
    return of(null);
  }

  getByIdAndPeriodWithOcorrences(cadEvolutionNo: number, codigoPaciente: number, dtStart: Date = null, dtEnd: Date = null): Observable<any> {
    try {
      this.cadastroNo = cadEvolutionNo;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const filtered$ = this.getByIdAndPeriod(cadEvolutionNo, codigoPaciente, startDate, endDate);
      const reduceOcurrences$ = () => reduce((a: any, b: IMedlogicEvolution) => {
        const aIntensidade = a && a.intensidade ? +a.intensidade : 0;
        const bIntensidade = b && b.intensidade ? +b.intensidade : 0;
        const intensidade = Math.max(aIntensidade, bIntensidade);
        return {
          ...a,
          ...b,
          intensidade,
          calcHasOccurrence: (a?.calcHasOccurrence || false) || (b.houveIntercorrencias || false) || (b.realizouConduta || false) || (intensidade || 0) >= +this.MAX_PAIN || (b.ultimaEvacuacaoOcorreurmais2Dias || false)
        } as IMedlogicEvolution;
      }, {} as any);
      // pipe principal
      const res$ = filtered$
        .pipe(
          mergeMap(evolutions => of(evolutions)
            .pipe(
              this.calcHasOccurrence$(),
              reduceOcurrences$(),
              error()
            )
          )
        );
      return res$;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getByIdAndPeriodWithOcorrences', error.message);
    }
    return of(null);
  }

  /* Retorna dados filtrando a query no bd. strFilter é do tipo: `V_2230:${patientId}`.
  * Também filtra especificamente a dataavaliacao dentro do período.
  */
  protected getFiltered(cadastroNo: number, strFilter: string, startDate: Date, endDate: Date): Observable<IMedlogicEvolution> {
    try {
      this.cadastroSrv.dtInicial = this.glb.dateToYYYYMMddThhmmss(startDate);
      this.cadastroSrv.dtFinal = this.glb.dateToYYYYMMddThhmmss(endDate);
      return this.cadastroSrv
        .getCadastroComFiltro(cadastroNo, this.lstVariaveis, strFilter, true)
        .pipe(
          map(c => this.toAttribute(c)),
          filter((f: IMedlogicEvolution) => this.glb.isBetweenIgnoreTime(this.glb.getTypedValue(f.dataAvaliacao).value, startDate, endDate)),
          publishReplay(),
          refCount()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFiltered', error.message);
    }
    return of(null);
  }

  /* Checa se o paciente possui alguma intercorrência no período fornecido.
  * Retornará um observable com o codigoPaciente e uma coluna hasOccurrence.
  * Indicará se houve conduta, ou se houve intercorrência, ou se há presença
  * de dor em nível superior ao MAX_PAIN.
  */
  getHasOccurrence(ano: number, codigoPaciente: string, dtStart: Date = null, dtEnd: Date = null): Observable<IMedlogicEvolution> {
    try {
      this.cadastroNo = ano;
      const startDate = dtStart || new Date(1900, 0, 1);
      const endDate = dtEnd || new Date(2500, 0, 1);
      const lstVariables = 'V_2230,V_28889,V_28888,V_28091,V_101593,V_28611,V_28595,V_28929';
      const filtered$ = this.getSome(ano, lstVariables, startDate, endDate)
        .pipe(
          filter((f: any) => {
            return +f.codigoPaciente === +codigoPaciente;
          }),
          error()
        );
      // Pipe
      return filtered$
        .pipe(
          this.calcHasOccurrence$(),
          error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getHasOccurrence', error.message);
    }
    return of(null);
  }

  private calcHasOccurrence$ = () => map((evolution: IMedlogicEvolution) => {
    return {
      ...evolution,
      calcHasOccurrence: (evolution?.houveIntercorrencias || false) || (evolution?.realizouConduta || false) || (evolution?.intensidade || 0) >= +this.MAX_PAIN || (evolution?.ultimaEvacuacaoOcorreurmais2Dias || false)
    } as IMedlogicEvolution;
  });

  /* Operador personalizado. Retorna os dados da evolução descritiva formatada. */
  fillEvolucaoDescricaoHtml = (showDate: boolean = true, isLineBreak: boolean = false) => mergeMap((evolution: IMedlogicEvolution) => {
    try {
      let intercorrencia = evolution.houveIntercorrencias ? evolution.queixaPaciente || 'houve intercorrência!' : '(sem intercorrência)';
      let conduta = evolution.realizouConduta ? evolution.condutaEvolucao || 'houve conduta!' : '(não foi realizada conduta)';
      let evolucao = evolution.evolucaoDescritiva || '(nenhuma observação na data)';
      let avaliador = evolution.professional &&
        evolution.professional.nome ? evolution.professional.nome.toUpperCase() :
        (evolution.executorAvaliacao ? evolution.executorAvaliacao.toUpperCase() : '(não especificado)');
      const especialidade = evolution.professional &&
        evolution.professional.especialidade ?
        (` (${evolution.professional.especialidade}:)`).toUpperCase() : '';
      const identificacao = evolution.professional &&
        evolution.professional.numeroRegistro ?
        (`${evolution.professional.tipoRegistro}: ${evolution.professional.numeroRegistro}`).toUpperCase() : '';
      const data = showDate ?
        this.glb.dateToddMMYYYYhhmmss(evolution.dataAvaliacao || null, '/', false) || '' :
        this.glb.dateTohhmmss(evolution.dataAvaliacao || null);
      intercorrencia = `${this.glb.addPontoFinal(intercorrencia)}`;
      conduta = `${this.glb.addPontoFinal(conduta)}`;
      evolucao = this.glb.addPontoFinal(evolucao);
      avaliador = `${this.glb.addPontoFinal(`${avaliador}${especialidade}, ${identificacao}`)}`;
      const quebraLinha = isLineBreak ? '<br /><br />' : '';
      evolution.descricaoHtml = ` <h3>${data}</h3> Avaliado por ${avaliador} Descrição: ${evolucao} ${intercorrencia} ${conduta}${quebraLinha}`;
      const hora = this.glb.dateTohhmmss(evolution.dataAvaliacao || null, false).substr(0, 2);
      parseInt(hora, 10) >= 7 && parseInt(hora, 10) <= 18 ? evolution.turno = 'DIA' : evolution.turno = 'NOITE';
      evolution.descricaoHtml += `<br /><br /><strong>PROTOCOLO DE EVOLUÇÃO:</strong><br />${this.anaminesePositiva(evolution)}`;
      return of(evolution);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'evolucaoDescricao', error.message);
    }
    return of(evolution);
  })

  sortByDate = () => map((v: IMedlogicEvolution[]) =>
    v.sort(
      (a, b) =>
        b.dataAvaliacao && a.dataAvaliacao
          ? this.glb.compareDates(b.dataAvaliacao, a.dataAvaliacao)
          : 0
    )
  )

  /* Retorna um texto com todos os itens marcados como ocorridos.
  * O conteúdo retornado é html.
  */
  anaminesePositiva(evolution: IMedlogicEvolution): string {
    try {
      let res = '';
      res += evolution.obito ? `<span class="warning">Com pesar, registrado que residente foi a óbito. </span>` : '';
      if (!evolution.obito) {
        res += evolution.desidratacao ? `O paciente está desidratado. ` : '';
        res += evolution.desnutricao ? `O paciente está desnutrido. ` : '';
        res += evolution.diurese ? `A paciente está com diurese. ` : '';
        res += evolution.escabiose ? `O paciente está com escabiose. ` : '';
        res += evolution.higieneBucal ? `Higiene bucal realizada. ` : 'Higiene bucal não realizada. ';
        res += evolution.ingestao ? `O paciente deglutiu adequadamente. ` : 'O paciente apresentou problemas de deglutição. ';
        res += evolution.quedaComLesao ? `O paciente teve queda, com lesão. ` : '';
        res += evolution.quedaSemLesao ? `O paciente teve queda, ou quase queda, sem lesão. ` : '';
        if (evolution.senteDor) {
          // const dor = `O paciente registra dor com intensidade <img src='${evolution.intensidadeDorEvaURL}' />`;
          const dor = `O paciente registra dor com intensidade ${evolution.intensidade}. `;
          res += evolution.intensidade &&
            this.glb.isNumeric(evolution.intensidade) &&
            parseInt(evolution.intensidade, 10) >= 8 ? `<span class="warning">${dor}</span>` : dor;
        }
        res += evolution.tentativaSuicidio ? `<span class="warning">Houve tentativa de suicídio. </span>` : '';
        res += evolution.tomouBanho ? `O paciente tomou banho.` : 'O paciente não tomou banho nesse turno. ';
        res += evolution.ulceraDecubito ? `Há presença de úlcera de decúbito. ` : '';
        res += evolution.diarreia ? `Há presença de diarréia. ` : '';
        res += evolution.oPacienteEvacuaRegularmente ? `O paciente evacua regularmente. ` : 'O paciente não evacua regularmente. ';
        res += evolution.ultimaEvacuacaoOcorreurmais2Dias ?
          `<span class="warning">ATENÇÃO: última evacuação ocorrida há mais de 2 dias. </span>` : '';
        res += evolution.oPacienteEstaIngerindoUmaQuantidadeAdequadafibras ?
          `O paciente ingeriu quantidade adequada de fibras. ` : 'O paciente não ingeriu fibras. ';
        res += evolution.oPacienteEstahidratandoAdequadamente ?
          `O paciente se hidratou adequadamente. ` : 'O paciente não se hidratou adequadamente. ';
        res += evolution.oPacienteUtilizaLaxante ? `O paciente está fazendo uso de laxante. ` : '';
      }
      return res;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'anaminesePositiva', error.message);
    }
    return '';
  }

  mapObjToEvolution(obj: { [key: string]: string | Date | number }): Observable<IMedlogicEvolution> {
    try {
      return of(obj).pipe(this.mapTo());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapObjToEvolution', error.message);
    }
    return of(null);
  }

  private mapTo = () => map((c: any) => this.toAttribute(c));


}
