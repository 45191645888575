import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { IDialogData } from '@medlogic/shared/shared-interfaces';

@Component({
  selector: 'ui-dialog-alert',
  templateUrl: './ui-dialog-alert.component.html',
  styleUrls: ['./ui-dialog-alert.component.css']
})
export class UiDialogAlertComponent implements OnInit {

  // tslint:disable: variable-name
  private _title: string;
  public get title(): string {
    return this._title;
  }
  public set title(v: string) {
    this._title = v;
  }

  private _btnOK: string;
  public get btnOK(): string {
    return this._btnOK;
  }
  public set btnOK(v: string) {
    this._btnOK = v;
  }

  icon: string;
  message = '';

  constructor(
    public dialogRef: MatDialogRef<UiDialogAlertComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IDialogData) {
    this.title = data?.title || '';
    this.btnOK = data?.btnOk || 'OK';
    this.icon = data?.icon || '';
    this.message = data?.messageHtml || '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  changeData() {
  }


}
