import { createAction, props } from '@ngrx/store';
import { ITenant, EnTheme, EnUserRole } from '@medlogic/shared/shared-interfaces';

// This action is necessary to get the Ids for each module, according with the logged user.

export enum TenantActionTypes {
  LoadTenant = '[Tenant API Ids] Load Tenant',
  SetTenant = '[Tenant API Ids] SET_TENANT',
  SetTenantId = '[Tenant API Ids] SET_TENANT_ID',
  SetTenantToken = '[Login Effects] SET_TENANT_TOKEN',
  SetTenantOno = '[Privacy Page] SET_TENANT_ONO',
  SetTenantCustomerId = '[Welcome Page] SET_TENANT_CUSTOMER_ID',
  SetTenantTheme = '[Welcome Page] SET_TENANT_THEME',
  ErrorTenant = '[Tenant API Ids] LOAD_TENANT_FAIL',
  SuccessTenant = '[Tenant API Ids] TENANT_SUCCESS',
  LoadTenantGoRoot = '[Tenant API Ids] LoadTenantGoRoot',
  GoRoot = '[Tenant API Ids] GoRoot',
  ClearTenant = '[Page Login] Clear Tenant',
}

export const loadTenant = createAction(
  TenantActionTypes.LoadTenant,
  props<{ login: any, tenantId: number, loggedUserName: string, enTheme: EnTheme, userRole: EnUserRole, token: string, userId: number, customerId: number }>()
);
export const loadTenantGoRoot = createAction(
  TenantActionTypes.LoadTenantGoRoot,
  props<{ login: any, tenantId: number, loggedUserName: string, enTheme: EnTheme, userRole: EnUserRole, token: string, userId: number, customerId: number, imgUrl?: string }>()
);
export const setTenantId = createAction(TenantActionTypes.SetTenantId, props<{ tenantId: number }>());
export const setTenantToken = createAction(TenantActionTypes.SetTenantToken, props<{ token: string, login: any }>());
export const setTenantOno = createAction(TenantActionTypes.SetTenantOno, props<{ ono: number }>());
export const setTenantCustomerId = createAction(TenantActionTypes.SetTenantCustomerId, props<{ customerId: number }>());
export const setTenantTheme = createAction(TenantActionTypes.SetTenantTheme, props<{ enTheme: EnTheme }>());
export const clearTenant = createAction(TenantActionTypes.ClearTenant);
export const setTenant = createAction(
  TenantActionTypes.SetTenant,
  props<{ selectedTenant: ITenant, customerId?: number, identification1?: any, identification2?: any }>()
);

export const errorTenant = createAction(TenantActionTypes.ErrorTenant);
export const successTenant = createAction(TenantActionTypes.SuccessTenant);
export const goRoot = createAction(TenantActionTypes.GoRoot);
