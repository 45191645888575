import { fadeAnimation } from '@medlogic/shared/utils';
import {
  loadEvolutionsBySelectedPatient, selectSearch,
  setEvolution,
  setSearch,
} from '@medlogic/medlogic/medlogic-state';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';

import {
  ISmartList, LogService, GlobalService, EnTheme, UnsubscribeOnDestroyAdapter,
  IListItem,
  IMedlogicEvolution,
} from '@medlogic/shared/shared-interfaces';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';
import { NavigationPwaService } from '../../service/navigation-pwa.service';
import { selectEvolutionsAsSmartList } from '@medlogic/medlogic/medlogic-state';


@Component({
  selector: 'ml-evolution-list',
  templateUrl: './evolution-list.component.html',
  styleUrls: ['./evolution-list.component.css'],
  animations: [fadeAnimation]
})
export class EvolutionListComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  NUMBER_OF_DAYS = 30;
  groups$: Observable<ISmartList[] | any> = this.store.select(selectEvolutionsAsSmartList);

  enTheme = EnTheme.default;
  readonly PAGE_TITLE = 'Evoluções';
  readonly NUM_MIN_OF_CHAR_SEARCH = 3;
  imgUrl = '/assets/img/Evolutions.png';
  isLoading = true;
  search$: Observable<string>;
  search = new FormControl(null);
  readOnly = false;

  constructor(
    private store: Store<IAppMedlogicState>,
    private log: LogService,
    private glb: GlobalService,
    private nav: NavigationPwaService
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.subs.sink = this.store.select(state => selectSearch(state))
        .subscribe(value => this.search.setValue(value));
      this.search.valueChanges.subscribe(search => this.store.dispatch(setSearch({ search })));
      const td = new Date();
      const dtStart = this.glb.addDays(td, -this.NUMBER_OF_DAYS);
      const dtEnd = this.glb.addDays(td, +1);
      this.store.dispatch(loadEvolutionsBySelectedPatient({ dtStart, dtEnd }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  onEsc($event: any): void {
    try {
      this.search.setValue('');
      this.store.dispatch(setSearch({ search: '' }));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onEsc', error.message);
    }
  }

  /* Clique numa pessoa específica. */
  itemClick(
    item: IListItem<IMedlogicEvolution>,
    enTheme: EnTheme,
    readOnly: boolean
  ): void {
    try {
      if (item) {
        this.store.dispatch(setEvolution({ selectedId: item?.id }));
        this.nav.gotoEvolution(item?.id);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'itemClick', error.message);
    }
  }

  /* Botão adicionar. */
  addClick($event: any): void {
    try {
      this.store.dispatch(setEvolution({ selectedId: null }));
      this.nav.gotoEvolution(-1);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'addClick', error.message);
    }
  }

  /* Click no botão voltar dessa tela. */
  onBack($event: any): void {
    try {
      this.nav.gotoCardPatient();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onBack', error.message);
    }
  }


}
