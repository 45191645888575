import { LocalMsgPtBR } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { EmployeeCustomService } from '@medlogic/medlogic/medlogic-data-access';
import { sendEmail } from '@medlogic/shared/state-utils';
import { IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { ConfigJsonService, GlobalService, IEmailMessage, IFamily, IMedlogicEvolution } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { of, iif, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  EvolutionCustomService, FamilyCustomService,
} from '@medlogic/medlogic/medlogic-data-access';
import {
  LogService,
} from '@medlogic/shared/shared-interfaces';
import { setIsLoading } from '../../state-medlogic/+state/medlogic.actions';
import {
  evolutionFail, loadEvolutions, loadEvolutionsSuccess,
  loadEvolutionsBySelectedPatient, addEvolutions, sendEvolutionEmail
} from './evolution.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class EvolutionEffects {

  private readonly MODULE_NAME = 'EVOLUÇÃO CLÍNICA';

  private getAllBySelectedPatient$ = (dtStart: Date = null, dtEnd: Date = null) => mergeMap(([never, state]) => {
    return this.evolutionSrv.getByIdAndPeriodWithOcorrences(
      state?.tenant?.selectedTenant?.cadEvolutionsNo,
      state?.patient?.selectedId,
      dtStart,
      dtEnd
    )
      .pipe(
        toArray(),
        switchMap((evolutions: IMedlogicEvolution[]) => {
          return [
            evolutions ? addEvolutions({ evolutions }) : evolutionFail(null),
            setIsLoading({ isLoading: false })
          ];
        }),
        catchError((e: any) => {
          console.log(e);
          return of(evolutionFail(null));
        })
      )
  });

  constructor(
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppMedlogicState>,
    private evolutionSrv: EvolutionCustomService, // TODO: ContaService changed to the API
    private cnfJson: ConfigJsonService,
    private familySrv: FamilyCustomService,
    private employeeSrv: EmployeeCustomService,
    private msg: LocalMsgPtBR,
    private snackBar: MatSnackBar,
  ) { }

  loadEvolutionList$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadEvolutions),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.evolutionSrv.getByIdAndPeriodWithOcorrences(state?.tenant?.selectedTenant?.evolutionAno, state?.patient?.selectedId)
          .pipe(
            toArray(),
            switchMap((evolutions: IMedlogicEvolution[]) => [
              evolutions ? loadEvolutionsSuccess({ evolutions }) : evolutionFail(null),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              return of(evolutionFail(null));
            })
          );
      })
    )
  );

  loadEvolutionsBySelectedPatient$ = createEffect(() => this.actions$
    .pipe(
      ofType(loadEvolutionsBySelectedPatient),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        const { dtStart, dtEnd } = state?.evolutions;
        return of([never, state]).pipe(this.getAllBySelectedPatient$(dtStart, dtEnd));
      })
    )
  );

  sendEvolutionEmail$ = createEffect(() => this.actions$
    .pipe(
      ofType(sendEvolutionEmail),
      withLatestFrom(this.store),
      mergeMap(([{ evolution }, state]) => {
        const { selectedTenant } = state?.tenant;
        const { cadEmployeeNo } = selectedTenant;
        return cadEmployeeNo ? this.employeeSrv.getByEnviarEmailFamiliar(cadEmployeeNo).pipe(toArray(), map(employees => ({ evolution, state, employees }))) : EMPTY 
      }),
      mergeMap(({ evolution, state, employees }) => {
        
        if (!employees && employees.length <= 0) {
          return EMPTY;
        }

        const { selectedTenant, imgUrl, loggedUserName } = state?.tenant;
        const { cadFamilyNo, cadEvolutionNo, emailFrom } = selectedTenant;
        const module = this.cnfJson.modules?.find(f => (f.id && f.id[0] && (f.id as Array<number>).includes(+cadEvolutionNo)) || (this.MODULE_NAME.toUpperCase() === f.name.toUpperCase())) || null;

        if (module?.sendEmailReportToFamilyAfterSave) {

          const sendEmail$ = (imgUrl: string) => map((members: IFamily[]) => {
            const toEmails = this.glb.Distinct(members?.filter(f => f.receberNotificacoesPormail && f.eMail)?.map(m => (m.eMail as String).trim().toLowerCase())).join(',');
            if (this.glb.isNullOrEmpty(toEmails)) {
              return null;
            }
            const message = this.getEmailMessage(evolution, emailFrom, toEmails, `Evolução`, imgUrl);
            return message;
          });

          const notifyUser$ = () => tap((message: IEmailMessage) => {
            this.snackBar.open(`${this.msg.EMAIL_ENVIADO_PARA} ${message.toEmails}`, null, {
              duration: 5000,
              panelClass: 'snackbar'
            });
          });

          const getFamilyMembersAndSendEmail$ = () => this.familySrv.getByCodigoPaciente(cadFamilyNo, evolution.codigoPaciente)
            .pipe(
              toArray(),
              sendEmail$(imgUrl),
              notifyUser$()
            );

          // pipe principal
          return iif(
            () => employees?.map(m => m.loginFuncionario?.toUpperCase()).includes(loggedUserName?.toUpperCase()),
            getFamilyMembersAndSendEmail$(),
            of(null)
          )
        }
        return of(null);
      }),
      switchMap(message => message ? [sendEmail({ message })] : [evolutionFail(null)]),
      catchError((e: any) => {
        console.log(e);
        return of(evolutionFail(null));
      })
    )
  );

  private getEmailMessage(evolution: IMedlogicEvolution, fromEmail: string, toEmails: string, title: string, imgUrl: string): IEmailMessage {
    try {
      return ({
        fromEmail,
        toEmails,
        subject: `${title} em ${new Date().toLocaleDateString()}`,
        htmlContent: `
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml">
            <head> 
              <meta charset="UTF-8"> 
              <meta content="width=device-width, initial-scale=1" name="viewport"> 
              <meta name="x-apple-disable-message-reformatting"> 
              <meta http-equiv="X-UA-Compatible" content="IE=edge"> 
              <meta content="telephone=no" name="format-detection"> 
              <title>${title}</title> 
              <style type="text/css">
                .title {
                  padding: 20px 0 0 0;
                  font-size: 1.2em;
                  font-weight: 600;
                  color: #111;
                }
                .content {
                  padding:0;
                  font-size: 1em;
                  font-weight: 300;
                  color: #111;
                }
                .container {
                  width: 600px;
                  margin: auto;
                  font-family: Roboto, arial, 'helvetica neue', helvetica, sans-serif;
                  background-color: white;
                  padding: 20px;
                  text-align: left;
                }
                .imgContainer {
                  width: 100%;
                  text-align: center;
                }
                .img {
                  max-height: 190px;
                  margin: auto;
                }
                body {
                  margin: 0;
                  padding: 0;
                  background-color: #F6F6F6;
                  text-align: center;
                }
                .pain {
                  max-width: 60%
                }
            </style> 
          </head> 
          <body> 
              
            <div class="container"> 
              
              <div class="imgContainer">
                <img class="img" src="${imgUrl}" title="logomarca" />
              </div>

              <div class="title">Segue a descrição da evolução do seu assistido.</div>
              
              <h2>Evolução Clínica</h2>

              <div class="title">Residente:</div>
              <div class="content">${evolution.residente}</div>

              <div class="title">Data de Nascimento:</div>
              <div class="content">${this.glb.DateToddMMYYYY(this.glb.getTypedValue(evolution.dtNascimento).value)}</div>

              <div class="title">Data da Avaliação:</div>
              <div class="content">${this.glb.dateToddMMYYYY(this.glb.getTypedValue(evolution.dataAvaliacao).value)}</div>

            
              <h2>AVALIAÇÃO</h2>

              <div class="title">Evolução descritiva:</div>
              <div class="content">${evolution.evolucaoDescritiva}</div>

              <div class="title">Houve Intercorrência?</div>
              <div class="content">${evolution.houveIntercorrencias ? 'SIM' : 'NÃO'}</div>
            
              <div class="title">Queixa do Paciente:</div>
              <div class="content">${evolution.houveIntercorrencias ? evolution.queixaPaciente : 'NENHUMA'}</div>

              <div class="title">Realizou Conduta:</div>
              <div class="content">${evolution.realizouConduta ? 'SIM' : 'NÃO'}</div>

              <div class="title">Descrição da Conduta:</div>
              <div class="content">${evolution.realizouConduta ? evolution.condutaEvolucao : 'NENHUMA'}</div>

              <div class="title">Sente Dor:</div>
              <div class="content">${evolution.senteDor ? 'SIM' : 'NÃO'}</div>
            
              <div class="title">Intensidade:</div>
              <div class="content">${evolution.senteDor ? `<img src="${evolution.intensidadeDorEvaURL}" class="pain" title="intensidade da dor" />` : 'NENHUMA'}</div>

              <div class="title">Evacua Regularmente:</div>
              <div class="content">${evolution.oPacienteEvacuaRegularmente ? 'SIM' : 'NÃO'}</div>
            
              <div class="title">Última evacuação ocorreu a mais de 2 dias:</div>
              <div class="content">${evolution.ultimaEvacuacaoOcorreurmais2Dias ? 'SIM' : 'NÃO'}</div>
            
              <div class="title">Hidratação adequada:</div>
              <div class="content">${evolution.oPacienteEstahidratandoAdequadamente ? 'SIM' : 'NÃO'}</div>
            
              <div class="title">Ingestão de fibras em quantidade adequada:</div>
              <div class="content">${evolution.oPacienteEstahidratandoAdequadamente ? 'SIM' : 'NÃO'}</div>

              <div class="title">Hidratação adequada:</div>
              <div class="content">${evolution.oPacienteEstaIngerindoUmaQuantidadeAdequadafibras ? 'SIM' : 'NÃO'}</div>
            
              <div class="title">Responsável pela Avaliação:</div>
              <div class="content">${evolution.executorAvaliacao}</div>
                
            </div>  
          </body>
        </html>
     `
      } as IEmailMessage);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getEmailMessage', error.message);
    }
  }


}
