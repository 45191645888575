import { ConfigStateService } from '@medlogic/shared/state-config';
import { ExpressionFunctionService } from './expression-function.service';
import { Injectable } from '@angular/core';
import { LibService } from './lib.service';
import { JavascriptLib } from './javascript-lib.service';
import { LogService, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { WebService } from '@medlogic/shared/shared-data-access';
import { CalculatorService } from './calculator.service';
import { CurrencyService } from './currency.service';

// tslint:disable: ban-types
interface IFunction {
  name: string;
  method: Function;
  paramMethod: Function;
}

@Injectable()
export class ExpressionGridFunctionService extends ExpressionFunctionService {
  /*override */
  protected arrayGridFunction: Array<IFunction> = [
    // Lógica
    { name: 'GRID_SUMIF', method: this.ProcessarFuncaoGRIDSUMIF, paramMethod: this.addParamVars },
    // Agregação
    { name: 'GRID_SUM', method: this.ProcessarFuncaoGRIDSUM, paramMethod: this.addParamVars },
    { name: 'GRID_COUNT', method: this.ProcessarFuncaoGRIDCOUNT, paramMethod: this.addParamVars },
    { name: 'GRID_MEAN', method: this.ProcessarFuncaoGRIDMEAN, paramMethod: this.addParamVars },
    { name: 'GRID_PRODUCT', method: this.ProcessarFuncaoGRIDPRODUCT, paramMethod: this.addParamVars },
    { name: 'GRID_GREATER', method: this.ProcessarFuncaoGRIDGREATER, paramMethod: this.addParamVars },
    { name: 'GRID_LOWER', method: this.ProcessarFuncaoGRIDLOWER, paramMethod: this.addParamVars },
    { name: 'GRID_LAST', method: this.ProcessarFuncaoGRIDLAST, paramMethod: this.addParamVars },
    { name: 'GRID_FIRST', method: this.ProcessarFuncaoGRIDFIRST, paramMethod: this.addParamVars }
  ];

  constructor(
    log: LogService,
    global: GlobalService,
    config: ConfigStateService,
    cnfJson: ConfigJsonService,
    calculator: CalculatorService,
    lib: LibService,
    jsLib: JavascriptLib,
    ws: WebService,
    currencySrv: CurrencyService
  ) {
    super(log, global, config, cnfJson, calculator, lib, jsLib, ws, currencySrv);
  }

  /*override
   * Necessário para permitir que as classes herdeiras sobrescrevam e adicionem seus respectivos arrays */
  getFunctionArray(): Array<IFunction> {
    try {
      return super.getFunctionArray().concat(this.arrayGridFunction);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getFunctionArray', error.message);
    }
  }

  /*
   *Soma o valor de todas as linhas de todas as colunas especificadas como parâmetro
   * @param arrayParam: #Numero_Variavel_Grid#; V_NumeroVariavelColuna1; V_NumeroVariavelColuna2...
   * @return
   *
   */
  private ProcessarFuncaoGRIDSUM(none: string, obj: any): number {
    try {
      const lstCadastroAdicional: any[] = this.getLstCadastroAdicional(obj);
      const id = obj.index;
      if (!this.validateParams(false, id) || !lstCadastroAdicional) {
        return null;
      }
      const sum = lstCadastroAdicional
        .map((m) => this.global.getTypedValue(m[id]).value)
        .reduce<number>((a, b) => {
          try {
            a = this.global.getTypedValue(a).value; // this.global.ConvertToAmericanNumber(a, 2, true);
            b = this.global.getTypedValue(b).value; // this.global.ConvertToAmericanNumber(b, 2, true);
            return a + b;
          } catch (error) {
            this.log.Registrar(this.constructor.name, 'ProcessarFuncaoFRIDSUM.reduce', error.message);
          }
        }, 0);
      // return this.global.ConvertToBrazilianNumber(Number.parseFloat(sum.toString()).toFixed(2));
      return sum;
    } catch (error) {
      this.log.Registrar('ExpressionFunction', 'ProcessarFuncaoGRIDSUM', error.message);
    }
    return null;
  }

  /*O valor que vem do grid pode estar entre aspas, com espaços e em português.
   * Esse método converte para number
   */
  protected getNum(val: any): number {
    try {
      if (this.global.isNumeric(val)) {
        return val;
      }
      return this.global.ConvertToAmericanNumber(this.global.trim(this.global.RemoverAspas(val)));
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getNum', error.message);
    }
  }

  /*  */
  protected getLstCadastroAdicional(obj: any): any[] {
    try {
      if (obj && obj.lstCtrlReferenciados[0] && obj.lstCtrlReferenciados[0].lstCadastroAdicional) {
        return this.lib.toArray(obj.lstCtrlReferenciados[0].lstCadastroAdicional);
      } else {
        return null;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getLstCadastroAdicional', error.message);
    }
    return null;
  }

  /*
   *Faz uma soma das colunas somente se a condição (que pode referenciar outras colunas, for atendida)
   * GRID.SUMIF( #Numero_Variavel_Grid#; V_NumeroVariavelColunaASerSomada;
   * V_NumeroVariavelColunaComparacao1; V_NumeroVariavelValorComparacao1;
   * V_NumeroVariavelColunaComparacao2; V_NumeroVariavelValorComparacao2;...
   * @param arrayParam
   * @param _ctrGrid
   * @return
   *
   */
  private ProcessarFuncaoGRIDSUMIF(...arrayParam): number {
    let value;
    try {
      if (arrayParam.length > 0) {
        // Dois primeiros parâmetros
        const selectedValue: string = this.extractParam(arrayParam[0]);
        const obj = arrayParam[1];
        const lstCadastroAdicional: any[] = this.getLstCadastroAdicional(obj);
        const clmASerSomadaName = obj.index;
        let sum = 0;
        // Demais parâmetros
        const arrayParamLength: number = arrayParam.length;
        const count: number = lstCadastroAdicional.length;
        for (
          let j = 0;
          j < count;
          j++ // Percorre todas as linhas do grid
        ) {
          let canSum = true;
          // Percorre todas as variáveis condicionais para cada linha
          for (let i = 2; i < arrayParamLength; i = i + 2) {
            // Os 2 primeiros parâmetros já foram extraídos. Os demais, são extraídos 2 a 2.
            const dataGridObject: any = lstCadastroAdicional[j];
            if (dataGridObject.hasOwnProperty(clmASerSomadaName)) {
              value = dataGridObject[clmASerSomadaName];
              value = this.getNum(value);
              if (!isNaN(value)) {
                const clmComparacaoNome: string = this.extractParam(arrayParam[i]);
                const valorComparacao: string = this.extractParam(arrayParam[i + 1]);
                if (dataGridObject.hasOwnProperty(clmComparacaoNome)) {
                  if (
                    dataGridObject[clmComparacaoNome].toUpperCase() ===
                    valorComparacao.toUpperCase()
                  ) {
                    canSum = canSum && true;
                  } else {
                    canSum = false;
                    break;
                  }
                } else {
                  canSum = false;
                  break;
                }
              }
            }
          }
          if (canSum) {
            sum += this.getNum(value); // A soma ocorre apenas se a condição for satisfeita, para cada linha
          }
        }
        // TODO: Criar uma função específica para conversão para número brasileiro, ou resolver isso com máscaras.
        // return this.global.ConvertToBrazilianNumber(_sum.toString(), 999, 2);
        return sum;
      }
    } catch (error) {
      this.log.Registrar('ExpressionFunction', 'ProcessarFuncaoGRIDSUMIF', error.message);
    }
    return null;
  }

  /*
   *Média de todos os valorers de todas as linhas de todas as colunas especificadas como parâmetro
   * @param arrayParam: #Numero_Variavel_Grid#; V_NumeroVariavelColuna1; V_NumeroVariavelColuna2...
   * @return
   *
   */
  private ProcessarFuncaoGRIDMEAN(none: string, obj: any): number {
    try {
      const lstCadastroAdicional: any[] = this.getLstCadastroAdicional(obj);
      const id = obj.index;
      if (!this.validateParams(false, id) || !lstCadastroAdicional) {
        return null;
      }
      const sum = lstCadastroAdicional.map((m) => m[id]).reduce((a, b) => this.getNum(a) + this.getNum(b), 0);
      const count = lstCadastroAdicional.length;
      return sum / count;
    } catch (error) {
      this.log.Registrar('ExpressionFunction', 'ProcessarFuncaoGRIDMEAN', error.message);
    }
    return null;
  }

  /*
   *Multiplica o valor de todas as linhas de todas as colunas especificadas como parâmetro
   * @param arrayParam: #Numero_Variavel_Grid#; V_NumeroVariavelColuna1; V_NumeroVariavelColuna2...
   * @return
   *
   */
  private ProcessarFuncaoGRIDPRODUCT(none: string, obj: any): number {
    try {
      const lstCadastroAdicional: any[] = this.getLstCadastroAdicional(obj);
      const id = obj.index;
      if (!this.validateParams(false, id) || !lstCadastroAdicional) {
        return null;
      }
      const product = lstCadastroAdicional.map((m) => m[id]).reduce((a, b) => this.getNum(a) * this.getNum(b), 0);
      return product;
    } catch (error) {
      this.log.Registrar('ExpressionFunction', 'ProcessarFuncaoGRIDPRODUCT', error.message);
    }
    return null;
  }

  /*
   *Retorna o maior valor para a coluna especificada.
   * =GRID.GREATER( #Numero_Variavel_Grid#; V_NumeroVariavelColuna)
   * @param arrayParam: V_NumeroVariavelColuna
   * @param _ctrGrid
   * @return
   *
   */
  private ProcessarFuncaoGRIDGREATER(none: string, obj: any): number {
    try {
      const lstCadastroAdicional: any[] = this.getLstCadastroAdicional(obj);
      const id = obj.index;
      if (!this.validateParams(false, id) || !lstCadastroAdicional) {
        return null;
      }
      const greater = lstCadastroAdicional
        .map((m) => m[id])
        .reduce((a, b) => (this.getNum(a) >= this.getNum(b) ? a : b), 0);
      return greater;
    } catch (error) {
      this.log.Registrar('ExpressionFunction', 'ProcessarFuncaoGRIDGREATER', error.message);
    }
    return null;
  }

  /*
   *Retorna o menor valor para a coluna especificada.
   * =GRID.LOWER( #Numero_Variavel_Grid#; V_NumeroVariavelColuna)
   * @param arrayParam: V_NumeroVariavelColuna
   * @param _ctrGrid
   * @return
   *
   */
  private ProcessarFuncaoGRIDLOWER(none: string, obj: any): number {
    try {
      const lstCadastroAdicional: any[] = this.getLstCadastroAdicional(obj);
      const id = obj.index;
      if (!this.validateParams(false, id) || !lstCadastroAdicional) {
        return null;
      }
      const lower = lstCadastroAdicional
        .map((m) => m[id])
        .reduce((a, b) => (this.getNum(a) <= this.getNum(b) ? a : b), 0);
      return lower;
    } catch (error) {
      this.log.Registrar('ExpressionFunction', 'ProcessarFuncaoGRIDLOWER', error.message);
    }
    return null;
  }

  /*
   *Retorna o primeiro valor para a coluna especificada.
   * =GRID.FIRST( #Numero_Variavel_Grid#; V_NumeroVariavelColuna;  V_NumeroVariavelColunaOrdenacao; TipoColunaOrdenacaoNo )
   * @param arrayParam: V_NumeroVariavelColuna; V_NumeroVariavelColunaOrdenacao,
   * // TipoColunaOrdenacaoNo (1=Numérico Padrão brazileiro 00,00, 2=Alfabético, 3=Data, 4=Numérico padrão americano 00.00)
   * @param _ctrGrid
   * @return
   *
   */
  private ProcessarFuncaoGRIDFIRST(none: string, obj: any, clmOrderName: string, typeOrder: string): string {
    try {
      const lstCadastroAdicional: any[] = this.getLstCadastroAdicional(obj);
      const id = obj.index;
      if (!this.validateParams(false, id) || !lstCadastroAdicional) {
        return null;
      }
      const count = lstCadastroAdicional.length;
      let first = '';
      // Ordenação do Grid
      const sorted = this.getSortedByClmName(lstCadastroAdicional, clmOrderName, typeOrder);
      if (lstCadastroAdicional[0][id]) {
        first = lstCadastroAdicional[0][id];
      }
      return first;
    } catch (error) {
      this.log.Registrar('ExpressionFunction', 'ProcessarFuncaoGRIDFIRST', error.message);
    }
    return null;
  }

  /*
   *Retorna o último valor para a coluna especificada.
   * =GRID.LAST( #Numero_Variavel_Grid#; V_NumeroVariavelColuna;  V_NumeroVariavelColunaOrdenacao; TipoColunaOrdenacaoNo )
   * @param arrayParam: V_NumeroVariavelColuna; V_NumeroVariavelColunaOrdenacao,
   * TipoColunaOrdenacaoNo (1=Numérico Padrão brazileiro 00,00, 2=Alfabético, 3=Data, 4=Numérico padrão americano 00.00)
   * @param lstCadastroAdicional
   * @return
   *
   */
  private ProcessarFuncaoGRIDLAST(none: string, obj: any, clmOrderName: string, typeOrder: string): string {
    try {
      const lstCadastroAdicional: any[] = this.getLstCadastroAdicional(obj);
      const id = obj.index;
      if (!this.validateParams(false, id) || !lstCadastroAdicional) {
        return null;
      }
      const count = lstCadastroAdicional.length;
      let last = '';
      // Ordenação do Grid
      const sorted = this.getSortedByClmName(lstCadastroAdicional, clmOrderName, typeOrder);
      if (lstCadastroAdicional[count - 1][id]) {
        last = lstCadastroAdicional[count - 1][id];
      }
      return last;
    } catch (error) {
      this.log.Registrar('ExpressionFunction', 'ProcessarFuncaoGRIDLAST', error.message);
    }
    return null;
  }

  /*
 * ATENÇÃO: ESSA FUNÇÃO TEM UM PROBLEMA DE ATUALIZAÇÃO. Como ela não
 * monitora uma variável, não é atualizada quando o número de linhas do grid aumenta ou diminui.
 *Conta o número de linhas do Grid
 * @param arrayParam: #Numero_Variavel_Grid#
 * @return
 *
 */
  private ProcessarFuncaoGRIDCOUNT(none: string, obj: any): number {
    try {
      const lstCadastroAdicional: any[] = this.getLstCadastroAdicional(obj);
      const id = obj.index;
      if (!this.validateParams(false, id) || !lstCadastroAdicional) {
        return null;
      }
      const count = lstCadastroAdicional.length;
      return count;
    } catch (error) {
      this.log.Registrar('ExpressionFunction', 'ProcessarFuncaoGRIDCOUNT', error.message);
    }
    return null;
  }

  /*Retorna a lista ordenada por uma coluna e conforme um tipo específico */
  protected getSortedByClmName(lstCadastroAdicional: any[], clmOrderName: string, typeOrder: string): void {
    let sorted;
    try {
      if (lstCadastroAdicional[clmOrderName]) {
        switch (typeOrder) {
          case '1': // Numerico - Padrão brazileiro 00,00
            sorted = lstCadastroAdicional.sort((a, b): number => {
              return this.sortBrazilianNumber(a, b, clmOrderName);
            });
            break;
          default:
          case '2': // Alfabético
            sorted = lstCadastroAdicional.sort((a: string, b: string): number =>
              a[clmOrderName].toUpperCase().localeCompare(b[clmOrderName].toUpperCase())
            );
            break;
          case '3': // Data
            sorted = lstCadastroAdicional.sort(function(a: Object, b: Object): number {
              return this.sortCalcData(this.a, this.b, clmOrderName);
            });
            break;
          case '4': // Numérico - Padrão Americano 00.00
            sorted = lstCadastroAdicional.sort(
              (a: string, b: string): number => a[clmOrderName] - b[clmOrderName]
            );
            break;
        }
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSortedByClmName', error.message);
    }
    return sorted;
  }

  private sortBrazilianNumber(a: any, b: any, propertyName: string): number {
    try {
      const strA = a[propertyName].replace('.', '').replace(',', '.');
      const strB = b[propertyName].replace('.', '').replace(',', '.');
      return Number(strA) - Number(strB);
    } catch (error) {
      this.log.Registrar('CtrGrid', 'sortBrazilianNumber', error.message);
    }
    return 0;
  }

  private sortCalcData(a: any, b: any, propertyName: string): number {
    try {
      const dta: Date = this.global.ddMMYYYYToDate(a[propertyName]);
      const dtb: Date = this.global.ddMMYYYYToDate(b[propertyName]);
      const difference: number = this.truncateDate(dta) - this.truncateDate(dtb);
      return difference / Math.abs(difference);
    } catch (error) {
      this.log.Registrar('CtrGrid', 'sortCalcData', error.message);
    }
    return 0;
  }

  // replace Object with the proper type
  private truncateDate(object: Date): number {
    return (object as Date).getTime() * 0.0001;
  }
}
