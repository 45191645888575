import { IExame, IMedicalTest } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { LogService, EnUserRole, GlobalService, IListItem } from '@medlogic/shared/shared-interfaces';
import { IExameState, IAppMedlogicState } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { ItemSearchPipe } from '@medlogic/shared/utils';

export const selectExame = (state: IExameState) => state?.exame;
export const selectAllExames = (state: IExameState) => state?.exames;
export const selectExameError = (state: IExameState) => state?.error;

const glb = new GlobalService();
const log = new LogService();

export const selectExamesAsIMedicalTest = (state: IExameState): IMedicalTest[] => {
  try {
    const categoryName = state?.categoria?.split(' (')[0];
    const exames = state?.exames?.filter(f => glb.isNullOrEmpty(categoryName) || glb.isEqual(f.categoria, categoryName))
      .map(m => ({
        id: m?.codigo,
        categoryName: m?.categoria,
        name: m?.descricao,
        min: m?.valorMinimoReferencia,
        max: m?.valorMaximoReferencia,
        unit: m?.unidademedida
      } as IMedicalTest));
    return exames;
  } catch (error) {
    console.log('selectExamesAsIMedicalTest', 'selectExamesAsIMedicalTest', error.message);
  }
  return null;
}

export const selectExameCategories = (state: IAppMedlogicState): string[] => {
  const categories = glb.Distinct(state?.exame?.exames?.filter(f => f.categoria).map(m => m.categoria));
  return categories?.map(category => {
    const numOfResults = state?.exameResultado?.resultados?.filter(f => glb.isEqual(f.categoria, category))?.length || 0;
    return `${category} (${numOfResults})`;
  });
};

/* Operador examealizado que converte os exameos em módulos. */
const toIListItem = ((exame: IExame) => {
  return ({
    id: exame?.codigo,
    identification1: exame?.codigo,
    identification2: exame?.titulo,
    topLeft: exame?.descricao,
    bottomLeft: exame?.descricao,
    topRight: exame?.codigo,
    bottomRight: '',
    color: 'green',
    imgUrl: '',
    obj: exame
  } as IListItem<IExame>);
})

function uniqueDates(itens: any[]) {
  const datas: Date[] = [];
  if (itens.length > 1) {
    itens.forEach(item => {
      if (!datas.find(f => glb.isEqualIgnoreTime(f, glb.YYYYMMddThhmmssToDate(item.obj.data)))) {
        datas.push(glb.YYYYMMddThhmmssToDate(item.obj.data));
      }
    });
    return datas;
  }
}

export const selectExamesAsIListItems = (state: any) => {
  return state?.exame?.exame?.map(toIListItem)[0];
};

const filterBySearch = (items: any[], keyword: string) => { // ISmartExame<IModule>
  return new ItemSearchPipe(log, glb).transform(items, keyword);
}

export const selectExameDefaultFormControls = (state: IAppMedlogicState): any => {
  const isEducator = [EnUserRole.customer, EnUserRole.educator, EnUserRole.professional].includes(state?.tenant?.userRole);
  const isSpecialist = [EnUserRole.professional].includes(state?.tenant?.userRole);
  const isParent = [EnUserRole.responsible].includes(state?.tenant?.userRole);
  const userName = state?.login?.userName;
  const patient = state?.person?.person;
  return {
    V_104052: userName,
    V_102283: state?.person?.person?.codigo,
    V_104049: glb.dateToYYYYMMddThhmmss(new Date()),
    // V_102725: isEducator ? userName : patient?.nomeEducadorProfissionalResponsavel,
    // V_102727: isParent ? userName : patient?.nomeresponsavelLegal,
    // V_102728: patient?.grauparentesco,
    // V_102303: state?.school?.selectedSchool?.codigo || state?.login?.selectedLogin?.customerId,
    V_103860: isEducator ? state?.login?.selectedLogin?.usuarioLogadoNome : '',
    V_103865: isSpecialist ? state?.login?.selectedLogin?.usuarioLogadoNome : '',
  };
}
