import { ConfigStateService } from '@medlogic/shared/state-config';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NavigationService } from '../../shared/service/navigation.service';
import { EnTheme } from '@medlogic/shared/shared-interfaces';
import { AtividadeDAL } from '../../shared/model/dal/atividade-dal';
import { Observable, of } from 'rxjs';
import { IAtividade } from '../../shared/interface/iatividade';
import { mergeMap } from 'rxjs/operators';
import { HomeView, CustomerConfigService } from '@medlogic/shared/gecore';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { ModelComponent } from '@medlogic/shared/shared-data-access';
import { CadastroService } from '@medlogic/shared/shared-data-access';

@Component({
  selector: 'lib-cadastro-list-view',
  templateUrl: './cadastro-list-view.component.html',
  styleUrls: ['./cadastro-list-view.component.css']
})
export class CadastroListViewComponent extends HomeView implements OnInit {

  enTheme = EnTheme.default;
  ano = -1;
  pno = -1;
  readOnly = false;

  constructor(
    log: LogService,
    glb: GlobalService,
    cnf: ConfigStateService,
    route: ActivatedRoute,
    modelComponent: ModelComponent,
    customerCnf: CustomerConfigService,
    msg: MsgPtBR,
    protected nav: NavigationService,
    protected atividadeDAL: AtividadeDAL,
    protected cadastroDAL: CadastroService,
  ) {
    super(log, glb, cnf, route, nav, modelComponent, customerCnf, msg);
  }

  ngOnInit() {
    try {
      this.getUrlParams(this.route);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  /** Extrai os parâmetros passados na url/rota. */
  protected getUrlParams(route: ActivatedRoute): void {
    try { // Os parametros estão sendo passados diretamente aos componentes
      // Para aguardar o carregamento do config
      this.subs.sink = route.params
        .pipe(
          mergeMap((params: Params) => {
            if (params.key) {
              this.cnf.modeloConfigNo = +params.key;
              this.ano = +params.key;
            }
            if (params.pno) {
              this.cnf.processoNo = +params.pno;
              this.pno = +params.pno;
            }
            if (params.theme) {
              this.enTheme = params.theme;
            }
            if (params.readOnly) {
              this.readOnly = params.readOnly;
            }
            return this.refresh(this.cnf.modeloConfigNo);
          })
        ).subscribe();
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getUrlParams', error.message);
    }
  }

  /** Define os parâmetros extraídos do Cadastro de Configurações. */
  protected setCadParams(): void {
    try {
      // this.cadParams = [
      //   <ICadParam>{ idName: 'cadEstoqueMateriaisNo', label: 'Cad_Estoque_Materiais' },
      // ];
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCadParams', error.message);
    }
  }

  protected setCurrentRoute(): void {
    try {
      this.currentRoute = this.nav.getRouteActivityList(this.cnf.modeloConfigNo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCurrentRoute', error.message);
    }
  }

  /** Carregamento principal. */
  protected refresh(ano: number): Observable<IAtividade> {
    try {
      return of(null);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'refresh', error.message);
    }
  }

  /** Click no botão Novo. */
  onNewClick($event: any): void {
    try {
      this.nav.gotoActivityNew(this.ano, this.pno, this.enTheme);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onNewClick', error.message);
    }
  }

  /** Click no item. */
  onItemClick($event: any): void {
    try {
      if ($event.hasOwnProperty('ono')) {
        console.log($event);
        this.nav.gotoActivityEdit(this.ano, this.pno, $event.ono, this.readOnly, this.enTheme);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onNewClick', error.message);
    }
  }


}
