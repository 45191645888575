<mat-list *ngIf="groups && groups?.length > 0; else empty" [class.black]="ENTHEME.black == enTheme">
  <ng-container *ngFor="let group of groups">

    <div mat-subheader class="title">{{ group?.title }}</div>

    <ng-container *ngIf="group?.items?.length > 0; else empty">

      <mat-list-item *ngFor="let item of group?.items" [@fadeAnimation]="'in'" (click)="onItemClick(item)">

        <ui-smart-list-badge-calendar mat-list-icon [date]="item?.identification2" badge></ui-smart-list-badge-calendar>

        <div mat-line class="top">{{ item?.topLeft }}</div>
        <div mat-line class="bottom">{{ item?.bottomLeft }}</div>

        <mat-icon *ngIf="item?.bottomRight === 'checked'" class="checked">check_circle_outline</mat-icon>
        <mat-icon *ngIf="item?.bottomRight === 'missed'" class="missed">highlight_off</mat-icon>
        <mat-icon>chevron_right</mat-icon>

        <mat-divider></mat-divider>

      </mat-list-item>

    </ng-container>

  </ng-container>
</mat-list>

<ng-template #empty>
  <ui-empty [imgUrl]="imgUrl"></ui-empty>
</ng-template>

<!-- <mat-list>


    <div mat-subheader class="title">{{ groups?.title }}</div>

    <ng-container *ngIf="groups?.items?.length > 0; else empty">

      <mat-list-item *ngFor="let item of groups?.items" [@fadeAnimation]="'in'" (click)="onItemClick(item)">

        <ui-smart-list-badge-calendar mat-list-icon [date]="item?.identification2" badge></ui-smart-list-badge-calendar>

        <div mat-line class="top">{{ item?.topLeft }}</div>
        <div mat-line class="bottom">{{ item?.bottomLeft }}</div>

        <mat-icon *ngIf="item?.bottomRight === 'checked'" class="checked">check_circle_outline</mat-icon>
        <mat-icon *ngIf="item?.bottomRight === 'missed'" class="missed">highlight_off</mat-icon>
        <mat-icon>chevron_right</mat-icon>

        <mat-divider></mat-divider>

      </mat-list-item>

    </ng-container>


</mat-list>

<ng-template #empty>
  <ui-empty></ui-empty>
</ng-template> -->
