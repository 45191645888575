import { routeGetAuthorizedPatients } from '@medlogic/shared/shared-interfaces';
import { Observable } from 'rxjs';
import {
  EnRequestType, GlobalService, ConfigJsonService, LocalLibService,
  routeGetSubProcessByUser, IPatient
} from '@medlogic/shared/shared-interfaces';
import { IPerson } from '@medlogic/medlogic/medlogic-shared-interfaces';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { BasePageService, CadastroService } from '@medlogic/shared/shared-data-access';
import { Injectable } from '@angular/core';
import { concatMap, defaultIfEmpty, map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnContentType } from '@medlogic/shared/shared-interfaces';
import { PersonService } from './person.service';

@Injectable({
  providedIn: 'root'
})
export class PersonCustomService extends PersonService {

  ORDERNO_TO_HIDE_PROCESS = 99;
  BASE_URL_WEBAPI = '';

  // TODO: API
  // protected urlGetSubProcessByUser = `Process/GetSubProcessByUser?ocorrenciaNo={0}`;
  protected urlGetSubProcessByUser = `${routeGetSubProcessByUser}?ocorrenciaNo={0}`;
  protected urlGetAuthorizedPatients = `${routeGetAuthorizedPatients}?cadPacienteNo={0}&cadAutorizacaoCuidadoNo={1}`;
  protected processByUser = new Array<IPerson>();
  protected modulos = new Array<IPerson>();
  protected baseUrl: string;
  protected userName: string;
  protected propsAtividadeResumidaByPersono: any[];
  protected processIsModule: IPerson[];
  protected processNotModule: IPerson[];
  protected processLoaded = false;
  protected activityLoaded = false;
  protected isLoading = false;

  constructor(
    private basepage: BasePageService,
    cadastroSrv: CadastroService,
    glb: GlobalService,
    cnf: ConfigJsonService,
    lib: LocalLibService,
    log: LogService) {
    super(cadastroSrv, glb, cnf, lib, log);
  }


  getBySchool(ano: number, idSchool: number, startDate?: Date, endDate?: Date): Observable<IPerson> {
    try {
      this.cadastroNo = ano;
      startDate = startDate || new Date(1900, 0, 1);
      endDate = endDate || new Date(2500, 0, 1);
      const strFilter = `V_102303:${idSchool}`;
      return this.getFiltered(this.cadastroNo, strFilter, startDate, endDate);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getBySchool', error.message);
    }
    return of(null);
  }


  /* Retorna a lista de processos que um usuário tem permissão para acessar.
  * O id da primeira atividade de cada processo também é retornada.
  */
  getSubProcessByUser(ocorrenciaNo: number = -1): Observable<IPerson> {
    try {
      const urlGet = this.basepage.format(this.urlGetSubProcessByUser, ocorrenciaNo);
      return this.basepage
        .baseDadosReplay(EnRequestType.Get, urlGet, {}, 2, EnContentType.Json)
        .pipe(
          map(m => (m as IPerson))
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getSubProcessByUser', error.message);
    }
  }

  protected error = () => catchError((err, obs) => {
    console.log(err);
    this.isLoading = false;
    return of(err);
  })

  /* Resgata os dados dos processos que o usuário pode acessar.
  * Inclusive o id das respectivas primeiras atividades.
  * Retornará os processos que são módulos.
  */
  getDataPerson(isMockup: boolean = false): Observable<IPerson> {
    try {
      if (isMockup) {
        return this.getDataPersonMockup();
      }
      this.isLoading = true;
      const afterLoaded = () => tap((p: any) => {
        this.processLoaded = true;
        this.isLoading = false;
      });
      return this.getSubProcessByUser(-1)
        .pipe(
          // this.processToIListItem(),
          defaultIfEmpty(null),
          afterLoaded(),
          this.error()
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDataPerson', error.message);
    }
  }

  getDataPersonMockup(): Observable<IPerson> {
    try {
      return of([]).pipe(
        concatMap(f => f)
      );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDataPersonMockup', error.message);
    }
    return of(null);
  }

  mapToIPatient(person: IPerson): IPatient {
    try {
      return !person ? null : {
        nomeHospede: person.nomeResidente,
        nascimento: person.nascimento,
        outraReligiosidade: person.religiao,
        nomemae: person.nomemae,
        codigo: person.codigo,
        titulo: person.titulo,
        habilitado: person.habilitado,
        sexo: person.sexo,
        estadoCivil: person.estadoCivil,
        estado: person.estado,
        cor: person.cor,
        religiao: person.religiao,
        data: person.data,
        profissao: person.profissao,
        idade: person.idade,
        naturalidade: person.naturalidade,
        paisNascimento: person.paisNascimento,
        nomeSocial: person.nomeSocial,
        cPF: person.cPF,
        rG: person.rG,
        anosescolaridade: person.escolaridade,
        enfermeiroReferenciaUBS: person.enfermeiroReferencia,
        medicoReferencia: person.medicoReferencia,
        regional: person.regiao,
        telefone: person.telefone,
        planoSaude: person.planoSaude,
        sistemasaudequeEstaVinculado: !!person.planoSaude,
        iDInicio: person.dtAdmissao,
        sistemasaudeVinculado: person.planoSaude,
        descricao: person.descricao,
        classificacao: person.classificacao,
        identificacao1: person.identificacao1,
        identificacao2: person.identificacao2,
        temAlgumaAlergiamaiorGravidade: person.temAlgumaAlergiamaiorGravidade,
        seSimQual: person.seSimQual,
        temAlgumaDeficienciaVisualAuditivaFisicaOuOutra: person.temAlgumaDeficienciaVisualAuditivaFisicaOuOutra,
        seSimQual2: person.seSimQual2,
        nomepai: person.nomepai,
        situacaoPaciente: person.situacaoHospede,
        pacienteFalecido: person.pacienteFalecido,
        dataObito: person.dataObito,
        anoObito: person.anoObito,
        usaFralda: person.usaFralda,
        comeSozinho: person.comeSozinho,
        cadeirante: person.cadeirante,
        traqueostomizado: person.traqueostomizado,
        ulceradecubito: person.ulceradecubito,
        sondaGastrica: person.sondaGastrica,
        sondaNaso: person.sondaNaso,
        comUlceraPosHospitalizacao: person.comUlceraPosHospitalizacao,
        hospital: person.hospital,
        fraturaColofemur: person.fraturaColofemur,
        diabetico: person.diabetico,
        usaInsulina: person.usaInsulina,
        menores60: person.menores60,
        dataAdmissao: person.dataAdmissao,
        anoAdmissao: person.anoAdmissao,
        admissaoAntes2000: person.admissaoAntes2000,
        hospitalSim: person.hospitalSim,
        agendamento: person.agendamento,
        unidade: person.unidadeNegocio,
        urlLogo: person.urlLogo,
        prontuarioSoma: person.prontuarioSoma,
        totalCadastro: person.totalCadastro,
        pRTO: person.pRTO,
        identificacoes: person.identificacoes,
        endereco: person.endereco,
        numero: person.numero,
        bairro: person.bairro,
        cidade: person.cidade,
        telefone2: person.telefone2,
        estado2: person.estado2,
        celular: person.celular,
        eMail: person.eMail,
        quarto: person.quarto,
        regiao: person.regiao,
        trato: person.trato,
        educacao: person.educacao,
        foto: person.imgUrl,
        referencia: person.referencia,
        perfilCliente: person.perfilCliente,
        informacoesImportante: person.informacoesImportantes,
        diagnostico: person.dIAGNOSTICODASDOENCASOUCONDICOESCRONICASDESAUDE,
        pesoAtual: person.pesoAtualKg,
        altura: person.alturaM,
        prontuario: person.prontuario,
        imc: person.iMC
      } as IPatient;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToIPatient', error.message);
    }
    return null;
  }

  mapToIPerson(patient: IPatient): IPerson {
    try {
      return !patient ? null : {
        ocorrenciaNo: patient.codigo,
        nomeResidente: patient.nomeHospede,
        nascimento: patient.nascimento,
        // religiao: patient.outraReligiosidade,
        nomemae: patient.nomemae,
        codigo: patient.codigo,
        titulo: patient.titulo,
        habilitado: patient.habilitado,
        sexo: patient.sexo,
        estadoCivil: patient.estadoCivil,
        estado: patient.estado,
        cor: patient.cor,
        religiao: patient.religiao,
        data: patient.data,
        profissao: patient.profissao,
        idade: patient.idade,
        naturalidade: patient.naturalidade,
        paisNascimento: patient.paisNascimento,
        nomeSocial: patient.nomeSocial,
        cPF: patient.cPF,
        rG: patient.rG,
        escolaridade: patient.anosescolaridade,
        enfermeiroReferencia: patient.enfermeiroReferenciaUBS,
        medicoReferencia: patient.medicoReferencia,
        // regiao: patient.regional,
        telefone: patient.telefone,
        planoSaude: patient.planoSaude,
        sistemasaudequeEstaVinculado: patient.planoSaude,
        dtAdmissao: patient.iDInicio,
        // planoSaude: patient.sistemasaudeVinculado,
        descricao: patient.descricao,
        classificacao: patient.classificacao,
        identificacao1: patient.identificacao1,
        identificacao2: patient.identificacao2,
        temAlgumaAlergiamaiorGravidade: patient.temAlgumaAlergiamaiorGravidade,
        seSimQual: patient.seSimQual,
        temAlgumaDeficienciaVisualAuditivaFisicaOuOutra: patient.temAlgumaDeficienciaVisualAuditivaFisicaOuOutra,
        seSimQual2: patient.seSimQual2,
        nomepai: patient.nomepai,
        situacaoHospede: patient.situacaoPaciente,
        pacienteFalecido: patient.pacienteFalecido,
        dataObito: patient.dataObito,
        anoObito: patient.anoObito,
        usaFralda: patient.usaFralda,
        comeSozinho: patient.comeSozinho,
        cadeirante: patient.cadeirante,
        traqueostomizado: patient.traqueostomizado,
        ulceradecubito: patient.ulceradecubito,
        sondaGastrica: patient.sondaGastrica,
        sondaNaso: patient.sondaNaso,
        comUlceraPosHospitalizacao: patient.comUlceraPosHospitalizacao,
        hospital: patient.hospital,
        fraturaColofemur: patient.fraturaColofemur,
        diabetico: patient.diabetico,
        usaInsulina: patient.usaInsulina,
        menores60: patient.menores60,
        dataAdmissao: patient.dataAdmissao,
        anoAdmissao: patient.anoAdmissao,
        admissaoAntes2000: patient.admissaoAntes2000,
        hospitalSim: patient.hospitalSim,
        agendamento: patient.agendamento,
        unidadeNegocio: patient.unidade,
        urlLogo: patient.urlLogo,
        prontuarioSoma: patient.prontuarioSoma,
        totalCadastro: patient.totalCadastro,
        pRTO: patient.pRTO,
        identificacoes: patient.identificacoes,
        endereco: patient.endereco,
        numero: patient.numero,
        bairro: patient.bairro,
        cidade: patient.cidade,
        telefone2: patient.telefone2,
        estado2: patient.estado2,
        celular: patient.celular,
        eMail: patient.eMail,
        quarto: patient.quarto,
        regiao: patient.regiao,
        trato: patient.trato,
        educacao: patient.educacao,
        imgUrl: patient.foto,
        referencia: patient.referencia,
        perfilCliente: patient.perfilCliente,
        informacoesImportantes: patient.informacoesImportante,
        dIAGNOSTICODASDOENCASOUCONDICOESCRONICASDESAUDE: patient.diagnostico,
        pesoAtualKg: patient.pesoAtual,
        alturaM: patient.altura,
        prontuario: patient.prontuario,
        iMC: patient.imc
      } as unknown as IPerson;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'mapToIPerson', error.message);
    }
    return null;
  }

  getAuthorizedPersons(ano: number, cadAutorizacaoCuidadoNo: number): Observable<IPerson[]> {
    try {
      const urlGet = this.basepage.format(this.urlGetAuthorizedPatients, ano, cadAutorizacaoCuidadoNo);
      return this.basepage.baseDadosReplay(EnRequestType.Get, urlGet, {}, 2, EnContentType.Json)
        .pipe(
          map(m => m.map(person => this.mapToIPerson(person)))
        );
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getAuthorizedPatients', error.message);
    }
    return of(null);
  }


}
