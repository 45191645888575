<div class="grid">

  <div class="top-bar">

    <button mat-button type="button" *ngIf="showBack" (click)="onBack($event)">
      <mat-icon class="icn-back">arrow_back_ios</mat-icon>
      <span>LISTA</span>
    </button>

  </div>

  <mat-card>

    <mat-card-header>
      <mat-card-subtitle>{{ title }}</mat-card-subtitle>
    </mat-card-header>

    <img mat-card-image [src]="imgUrl" [alt]="title">

    <mat-card-content>
    </mat-card-content>

    <mat-card-actions>
      <button mat-button (click)="onDownload(doc)">BAIXAR</button>
    </mat-card-actions>

  </mat-card>

</div>